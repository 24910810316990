import React from 'react';
import Product from '../components/Product/Product';

function ProductData({ history }: { history: any}) {
    // console.log("ee", history);
    let search = new URLSearchParams(history.location.search)
    const s_code = parseInt(search.get("product_code") || "0");
    // console.log({s_code});
    let product_type = s_code > 1 ? 0: s_code;
    return (
        <Product p_type={product_type}/>
    )
}

export default ProductData;
