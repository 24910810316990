import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { TitleComponent } from '../TitleComponent'
import PictureWebpComponent from '../PictureWebpComponent'

import './Product.css'

// images
import productLima05W_3x_png from '../../images/image-chair-algo-lima-04-l-w@3x.png'
import productLima05W_2x_png from '../../images/image-chair-algo-lima-04-l-w@2x.png'
import productLima05W_1x_png from '../../images/image-chair-algo-lima-04-l-w.png'
import productD2a_3x_png from '../../images/image-chair-algo-d-2-a-w@3x.png'
import productD2a_2x_png from '../../images/image-chair-algo-d-2-a-w@2x.png'
import productD2a_1x_png from '../../images/image-chair-algo-d-2-a-w.png'
import productD2_3x_png from '../../images/image-chair-algo-d-2-w@3x.png'
import productD2_2x_png from '../../images/image-chair-algo-d-2-w@2x.png'
import productD2_1x_png from '../../images/image-chair-algo-d-2-w.png'
import productQ7_3x_png from '../../images/image-chair-algo-q-7-w@3x.png'
import productQ7_2x_png from '../../images/image-chair-algo-q-7-w@2x.png'
import productQ7_1x_png from '../../images/image-chair-algo-q-7-w.png'

import productChild_3x_png from '../../images/image-chair-child-w@3x.png'
import productChild_2x_png from '../../images/image-chair-child-w@2x.png'
import productChild_1x_png from '../../images/image-chair-child-w.png'
import productLearn_3x_png from '../../images/image-chair-learn-w@3x.png'
import productLearn_2x_png from '../../images/image-chair-learn-w@2x.png'
import productLearn_1x_png from '../../images/image-chair-learn-w.png'

import productCushionB_3x_png from '../../images/image-cushion-b-w@3x.png'
import productCushionB_2x_png from '../../images/image-cushion-b-w@2x.png'
import productCushionB_1x_png from '../../images/image-cushion-b-w.png'
import productCushionR_3x_png from '../../images/image-cushion-r-w@3x.png'
import productCushionR_2x_png from '../../images/image-cushion-r-w@2x.png'
import productCushionR_1x_png from '../../images/image-cushion-r-w.png'
import productCushionG_3x_png from '../../images/image-cushion-g-w@3x.png'
import productCushionG_2x_png from '../../images/image-cushion-g-w@2x.png'
import productCushionG_1x_png from '../../images/image-cushion-g-w.png'

// import icarrowright_b from '../../images/icon-arrow-right-b@3x.png';

import iconBle from '../../images/icon-ble@3x.png'
import iconWifi from '../../images/icon-wifi@3x.png'

import iconAlgoHealth from '../../images/icon-algohealth-n@3x.png'
import iconAlgoStudy from '../../images/icon-algostudy-n@3x.png'
import { Trans, useTranslation } from 'react-i18next';
import { useScrollFadeIn } from '../../utils/useScrollEvent'
import { openNewTabUrl } from '../../utils/commonUtils'

function Product(props: { p_type: number }) {
    const { t } = useTranslation();
    const { p_type } = props;

    const [isActiveProductSec, setIsActiveProductSec] = useState<number>(0); //'스마트체어', '스마트방석', 'pressureGo' Tab (추후 항목 추가)
    const [chosenActiveChair, setChosenActiveChair] = useState<number>(0); //"스마트체어 번호 선택"
    const [chosenActiveCushion, setChosenActiveCushion] = useState<number>(0); //"스마트방석 번호 선택"

    useEffect(() => {
        if (p_type !== isActiveProductSec) {
            setIsActiveProductSec(p_type);
        }
    }, [props]);
    // console.log({p_type}, {isActiveProductSec});

    // const openNewTabUrl = (newUrl:string) => {
    //     window.open(newUrl, '_blank');
    // }
    // const { chairSrc, ChairCode } = {
    //     chairSrc : productD2a,
    //     ChairCode : 'algo-LIMA 05W'
    // }
    const setChosenChair = () => {
        if (chosenActiveChair === 0) {
            return {
                chairSrc_1x: productD2a_1x_png,
                chairSrc_2x: productD2a_2x_png,
                chairSrc_3x: productD2a_3x_png,
                alt_name: 'image-chair-algo-d-2-a',
                ChairCode: 'algo-D2A'
            }
        } else if (chosenActiveChair === 1) {
            return {
                chairSrc_1x: productD2_1x_png,
                chairSrc_2x: productD2_2x_png,
                chairSrc_3x: productD2_3x_png,
                alt_name: 'image-chair-algo-d-2',
                ChairCode: 'algo-D2'
            }
        } else if (chosenActiveChair === 2) {
            return {
                chairSrc_1x: productQ7_1x_png,
                chairSrc_2x: productQ7_2x_png,
                chairSrc_3x: productQ7_3x_png,
                alt_name: 'image-chair-algo-q-7',
                ChairCode: 'algo-Q7'
            }
        } else if (chosenActiveChair === 3) {
            return {
                chairSrc_1x: productLima05W_1x_png,
                chairSrc_2x: productLima05W_2x_png,
                chairSrc_3x: productLima05W_3x_png,
                alt_name: 'image-chair-algo-lima-04-l',
                ChairCode: 'algo-LIMA 05W'
            }
        }
        return {
            chairSrc_1x: productLima05W_1x_png,
            chairSrc_2x: productLima05W_2x_png,
            chairSrc_3x: productLima05W_3x_png,
            alt_name: 'image-chair-algo-lima-04-l',
            ChairCode: 'algo-LIMA 05W'
        }
    }
    // const { cushionSrc } = {
    //     cushionSrc : productCushionB,
    // }
    const setChosenCushion = () => {
        if (chosenActiveCushion === 0) {
            return {
                cushionSrc_1x: productCushionB_1x_png,
                cushionSrc_2x: productCushionB_2x_png,
                cushionSrc_3x: productCushionB_3x_png,
                alt_name: 'image-cushion-b',
            }
        } else if (chosenActiveCushion === 1) {
            return {
                cushionSrc_1x: productCushionR_1x_png,
                cushionSrc_2x: productCushionR_2x_png,
                cushionSrc_3x: productCushionR_3x_png,
                alt_name: 'image-cushion-r',
            }
        } else if (chosenActiveCushion === 2) {
            return {
                cushionSrc_1x: productCushionG_1x_png,
                cushionSrc_2x: productCushionG_2x_png,
                cushionSrc_3x: productCushionG_3x_png,
                alt_name: 'image-cushion-g',
            }
        }
        return {
            cushionSrc_1x: productCushionB_1x_png,
            cushionSrc_2x: productCushionB_2x_png,
            cushionSrc_3x: productCushionB_3x_png,
            alt_name: 'image-cushion-b',
        }
    }

    const animatedFadeInTitle1 = useScrollFadeIn('up', 0.5, 0);
    const animatedFadeInTitleContent1 = useScrollFadeIn('up', 0.5, 0.3);

    // const animatedChairImg = useScrollClipPath('down', 1.2, 0.3, 0.2, 1.3);
    // const animatedCushionImg = useScrollClipPath('down', 1.2, 0.3, 0.2, 1.3);

    const animatedFadeInChairProductC1 = useScrollFadeIn('up', 0.4, 0.3);
    const animatedFadeInChairProductC2 = useScrollFadeIn('up', 0.4, 0.6);
    const animatedFadeInChairProductC3 = useScrollFadeIn('up', 0.4, 0.8);
    // const animatedaChairSel1 = useScrollClipPath('left', 2.5, 0.3, 0.2, 1.3);
    // const animatedaChairSel2 = useScrollClipPath('left', 2.5, 0.3, 0.2, 1.3);
    // const animatedaChairSel3 = useScrollClipPath('left', 2.5, 0.3, 0.2, 1.3);
    // const animatedaChairSel4 = useScrollClipPath('left', 2.5, 0.3, 0.2, 1.3);
    // const animatedaChairSel1 = useScrollFadeIn('left', 0.3, 0.3);
    // const animatedaChairSel2 = useScrollFadeIn('left', 0.3, 0.6);
    // const animatedaChairSel3 = useScrollFadeIn('left', 0.3, 0.9);
    // const animatedaChairSel4 = useScrollFadeIn('left', 0.3, 1.2);
    // const animatedaChairPuchase = useScrollFadeIn('up', 2.5, 1);
    // const animatedaChairChild = useScrollFadeIn('up', 0.4, 0.1);
    // const animatedaChairStudy = useScrollFadeIn('up', 0.4, 0.3);
    // const animatedBanner = useScrollClipPath('', 2, 0, 0.5, 1.5);

    const animatedFadeInCushionProductC1 = useScrollFadeIn('up', 0.4, 0.4);
    const animatedFadeInCushionProductC2 = useScrollFadeIn('up', 0.4, 0.6);
    const animatedFadeInCushionProductC3 = useScrollFadeIn('up', 0.4, 0.8);
    // const animatedCushionSel1 = useScrollFadeIn('left', 0.3, 0.3);
    // const animatedCushionSel2 = useScrollFadeIn('left', 0.3, 0.6);
    // const animatedCushionSel3 = useScrollFadeIn('left', 0.3, 0.9);
    // const animatedCushionPuchase = useScrollFadeIn('up', 2.5, 1);

    return (
        <section className="container pro_ctr">
            <TitleComponent title={t('header.nav.product')} />
            <div className="content pro_top_1">
                <div className="inner">
                    <div className="pro_top_1_1 bg_wrap" {...animatedFadeInTitle1}>
                        <div className="bg--blue7"></div>
                        <h1 className="title lang_en_font">PRODUCTS</h1>
                    </div>
                    <p className="title_content" {...animatedFadeInTitleContent1}><Trans i18nKey="product.title_content" components={[<br />]} /></p>
                    <div className="pro_top_2">
                        <ul className=" pro_code_sel tab tab_btn">
                            <li className={isActiveProductSec === 0 ? "active" : ""} onClick={() => setIsActiveProductSec(0)}>{t('product.category.smartchair')}</li>
                            <li className={isActiveProductSec === 1 ? "active" : ""} onClick={() => setIsActiveProductSec(1)}>{t('product.category.smartcushion')}</li>
                            {/* <li className={isActiveProductSec === 2 ? "active" : ""} style={{ cursor: 'not-allowed' }}>{t('product.category.smartchair')}</li> */}
                        </ul>
                        {/* tab1 (스마트 체어) */}
                        <div className={"chair_sec" + (isActiveProductSec === 0 ? "" : " no_display")}>
                            <div className="flex_sec">
                                <figure>
                                    <PictureWebpComponent fb_img_1x={setChosenChair().chairSrc_1x} fb_img_2x={setChosenChair().chairSrc_2x} fb_img_3x={setChosenChair().chairSrc_3x} alt_img_name={setChosenChair().alt_name}></PictureWebpComponent>
                                    {/* <img src={setChosenChair().chairSrc} alt="product img"></img> */}
                                    <figcaption className="lang_en_font">{setChosenChair().ChairCode}</figcaption>
                                </figure>
                                <ul className="product_sel_list scroll-none">
                                    <li onClick={() => { setChosenActiveChair(0) }}>
                                        <div className="d2a"></div>
                                    </li>
                                    <li onClick={() => { setChosenActiveChair(1) }}>
                                        <div className="d2"></div>
                                    </li>
                                    <li onClick={() => { setChosenActiveChair(2) }}>
                                        <div className="q7"></div>
                                    </li>
                                    <li onClick={() => { setChosenActiveChair(3) }}>
                                        <div className="lima05"></div>
                                    </li>
                                </ul>
                                <div className="product_content">
                                    <div {...animatedFadeInChairProductC1}>
                                        <p className="title_colav lang_en_font">Algorigo X DUOBACK</p>
                                        <p className="title"><Trans i18nKey="product.category.smartchair.title" components={[<span className="lang_en_font"></span>]} /></p>
                                    </div>
                                    <div {...animatedFadeInChairProductC2}>
                                        <p className="title_content">
                                            <Trans i18nKey="product.category.smartchair.title_content" components={[<br className="u_desk" />, <br />]}></Trans>
                                        </p>
                                        <div className="com_icons">
                                            <img src={iconWifi} alt="wifi" className="com_icon"></img>
                                            <img src={iconBle} alt="BLE" className="com_icon"></img>
                                        </div>
                                    </div>
                                    <div {...animatedFadeInChairProductC3}>
                                        <h4 className="app_service_title">{t('product.appservice.title')}</h4>
                                        <ul className="app_services">
                                            <li>
                                                <Link to="/services/algohealth">
                                                    <div className="app_icon">
                                                        <img src={iconAlgoHealth} alt="algo health icon"></img>
                                                    </div>
                                                    <div className="app_content">
                                                        <h5 className="title">{t('product.appservice.algohealth')}</h5>
                                                        <p className="title_content">{t('product.appservice.algohealth.content')}</p>
                                                    </div>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/services/algostudy">
                                                    <div className="app_icon">
                                                        <img src={iconAlgoStudy} alt="algo study icon"></img>
                                                    </div>
                                                    <div className="app_content">
                                                        <h5 className="title">{t('product.appservice.algostudy')}</h5>
                                                        <p className="title_content">{t('product.appservice.algostudy.content')}</p>
                                                    </div>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="buy_button">
                                    <a href="https://smartstore.naver.com/algorigo" target="_blank" rel="noreferrer">
                                        <button>{t('product.category.smartchair.btn1')}</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* tab2 (스마트 방석) */}
                        <div className={"cushion_sec" + (isActiveProductSec === 1 ? "" : " no_display")}>
                            <div className="flex_sec">
                                <figure>
                                    <PictureWebpComponent fb_img_1x={setChosenCushion().cushionSrc_1x} fb_img_2x={setChosenCushion().cushionSrc_2x} fb_img_3x={setChosenCushion().cushionSrc_3x} alt_img_name={setChosenCushion().alt_name}></PictureWebpComponent>
                                    {/* <img src={setChosenCushion().cushionSrc} alt="product img"></img> */}
                                    {/* <figcaption className="lang_en_font">{setChosenChair().ChairCode}</figcaption> */}
                                </figure>
                                <ul className="product_sel_list scroll-none">
                                    <li onClick={() => { setChosenActiveCushion(0) }}>
                                        <div className="cushion_b"></div>
                                        {/* <div style={{ backgroundImage: `url(${productCushionB})` }}></div> */}
                                    </li>
                                    <li onClick={() => { setChosenActiveCushion(1) }}>
                                        <div className="cushion_r"></div>
                                        {/* <div style={{ backgroundImage: `url(${productCushionR})` }}></div> */}
                                    </li>
                                    <li onClick={() => { setChosenActiveCushion(2) }}>
                                        <div className="cushion_g"></div>
                                        {/* <div style={{ backgroundImage: `url(${productCushionG})` }}></div> */}
                                    </li>
                                </ul>
                                <div className="product_content">
                                    <div {...animatedFadeInCushionProductC1}>
                                        <p className="title_colav lang_en_font">Algorigo X DUOBACK</p>
                                        <p className="title"><Trans i18nKey="product.category.smartchair.title" components={[<span className="lang_en_font"></span>]} /></p>
                                    </div>
                                    <div {...animatedFadeInCushionProductC2}>
                                        <p className="title_content">
                                            <Trans i18nKey="product.category.smartcushion.title_content" components={[<br />]} />
                                        </p>
                                        <div className="com_icons">
                                            <img src={iconBle} alt="BLE" className="com_icon"></img>
                                        </div>
                                    </div>
                                    <div {...animatedFadeInCushionProductC3}>
                                        <h4 className="app_service_title">{t('product.appservice.title')}</h4>
                                        <ul className="app_services">
                                            <li>
                                                <Link to="/services/algohealth">
                                                    <div className="app_icon">
                                                        <img src={iconAlgoHealth} alt="algo health icon"></img>
                                                    </div>
                                                    <div className="app_content">
                                                        <h5 className="title">{t('product.appservice.algohealth')}</h5>
                                                        <p className="title_content">{t('product.appservice.algohealth.content')}</p>
                                                    </div>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="buy_button">
                                    <a href="https://smartstore.naver.com/algorigo" target="_blank" rel="noreferrer">
                                        <button>{t('product.category.smartcushion.btn1')}</button>
                                    </a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className={"content_wide pro_top_5" + (isActiveProductSec === 0 ? "" : " no_display")}>
                <ul className="inner">
                    <li>
                        <PictureWebpComponent fb_img_1x={productChild_1x_png} fb_img_2x={productChild_2x_png} fb_img_3x={productChild_3x_png} alt_img_name="image-chair-child" fb_alt="Chair only child" className="li_product_img"></PictureWebpComponent>
                        {/* <img src={productChild} alt="Chair only child" className="li_product_img"></img> */}
                        <div className="li_content">
                            <p className="title">{t('product.category.smartchair.child.title')}</p>
                            <img src={iconWifi} alt="wifi" className="com_icon"></img>
                            <p className="title_content">{t('product.category.smartchair.child.title_content')}</p>
                            <p className="li_model">{t('product.category.model')} <span className="lang_en_font">DO-028DF / DO-070LDF</span></p>
                            <div className="show_more">
                                <a href="http://www.duoback.co.kr/c_mbuy/?p_url=detail&p_id=CA001CB089CC001P004249" target="_blank" rel="noreferrer">{t('product.show.product')}<span className="arrow-r"></span></a>
                            </div>
                        </div>
                    </li>
                    <li>
                        <PictureWebpComponent fb_img_1x={productLearn_1x_png} fb_img_2x={productLearn_2x_png} fb_img_3x={productLearn_3x_png} alt_img_name="image-chair-learn" fb_alt="Chair for student" className="li_product_img"></PictureWebpComponent>
                        {/* <img src={productLearn} alt="Chair for student" className="li_product_img"></img> */}
                        <div className="li_content">
                            <p className="title">{t('product.category.smartchair.study.title')}</p>
                            <img src={iconWifi} alt="wifi" className="com_icon"></img>
                            <p className="title_content">{t('product.category.smartchair.study.title_content')}</p>
                            <p className="li_model">{t('product.category.model')} <span className="lang_en_font">algo-043W</span></p>
                            <div className="show_more">
                                <a href="http://www.duoback.co.kr/c_mbuy/?p_url=detail&p_id=CA001CB089CC002P000001" target="_blank" rel="noreferrer">{t('product.show.product')}<span className="arrow-r"></span></a>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            {isActiveProductSec === 0 ? (
                <div className="floating_buy" onClick={() => openNewTabUrl('https://smartstore.naver.com/algorigo')}>
                    {t('product.category.smartchair.btn1')}
                </div>
            ) : (
                <div className="floating_buy" onClick={() => openNewTabUrl('https://smartstore.naver.com/algorigo')}>
                    {t('product.category.smartcushion.btn1')}
                </div>
            )}
            <section className="content_wide s_qna">
                <div className="s_qna_content">
                    <p><Trans i18nKey="product.banner.title" components={[<b></b>]} /></p>
                    <Link to="/contact/inquire">
                        <button>{t('product.banner.btn')}</button>
                    </Link>

                </div>
            </section>
        </section>
    )
}

export default Product
