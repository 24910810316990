import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './error.css';

function Error() {
    const { t } = useTranslation();

    return (
        <div className="error">
            <p className="e_tit"><Trans i18nKey="error.notfound.title" components={[<span></span>]} /></p>
            <p className="e_tit_content"><Trans i18nKey="error.notfound.title.content" components={[]} /></p>
            <Link to="/">{t("error.notfound.btn")}</Link>
        </div>
    )
}

export default Error;