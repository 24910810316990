/**
 * 바이트수 반환  
 * 
 * @param el : tag jquery object
 * @returns {Number}
 */
 export function byteCheckCount(el: string):number{
    var codeByte = 0;
    for (var idx = 0; idx < el.length; idx++) {
        var oneChar = escape(el.charAt(idx));
        if ( oneChar.length === 1 ) {
            codeByte ++;
        } else if (oneChar.indexOf("%u") !== -1) {
            codeByte += 2;
        } else if (oneChar.indexOf("%") !== -1) {
            codeByte ++;
        }
    }
    return codeByte;
}
