import React, { useEffect } from 'react';
import AlgoHealth from '../components/Service/AlgoHealth';
import AlgoStudy from '../components/Service/AlgoStudy';
import AlgoWork from '../components/Service/AlgoWork';

function ServiceData({ match }: { match: any }) {
    const service_type = match.params.service_type;

    // https://short.algorigo.com/J3VNhn14X

    useEffect(() => {
        // NOTE :  algo-study만 예외처리 (21.12.16)
        if (service_type === 'algo-study'){
            window.location.href = "https://short.algorigo.com/J3VNhn14X"
        }
    }, [service_type]);
    

    return (
        <>
            {service_type === 'algo-health' || service_type === 'algohealth' ? <AlgoHealth /> :
                service_type === 'algostudy' ? <AlgoStudy /> :
                    service_type === 'algo-work' || service_type === 'algowork' ? <AlgoWork /> :
                        <AlgoHealth />}
        </>
    )
}

export default ServiceData;
