// import { ThunkAction } from 'redux-thunk';
// import { RootState } from '..';
// import { RecruitAction } from './types';
import { getRecruitsAsync } from './actions';
import { getRecruits } from '../../api/api';
import createAsyncThunk from '../../lib/createAsyncThunk';

// export function getRecruitsThunk(): ThunkAction<void, RootState, null, RecruitAction> {
//     return async dispatch => {
//         const { request, success, failure } = getRecruitsAsync;
//         dispatch(request());
//         try {
//             const recruitList = await getRecruits();
//             dispatch(success(recruitList));
//         } catch (e) {
//             dispatch(failure(e));
//         }
//     };
// }

export const getRecruitsThunk = createAsyncThunk(getRecruitsAsync, getRecruits)