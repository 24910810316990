import React from 'react';
import { Trans } from 'react-i18next'
import { useScrollFadeIn } from '../../utils/useScrollEvent'

import './about.css';

function CvCard(props : {c_n : number, c_t : string, c_c_code : string}){
    const { c_n, c_t, c_c_code } = props;
    const animatedFadeInCvCardt = useScrollFadeIn('up', 0.6, (c_n % 3 === 0 ? 0.8 : c_n % 3 === 2 ? 0.6 : 0.4));

    if (window.matchMedia('(min-width: 768px)').matches){
        return (
            <li {...animatedFadeInCvCardt}>
                <div>
                    <span className="lang_en_font">0{`${c_n}`}</span>
                    <h6 className="lang_en_font">{`${c_t}`}</h6>
                    <p><Trans i18nKey={`${c_c_code}`} components={[<br className="u_tablet"/>]} /></p>
                </div>
            </li>
        )
    }else{
        return (
            <li>
                <div>
                    <span className="lang_en_font">0{`${c_n}`}</span>
                    <h6 className="lang_en_font">{`${c_t}`}</h6>
                    <p><Trans i18nKey={`${c_c_code}`} components={[<br className="u_tablet"/>]} /></p>
                </div>
            </li>
        )
    }
}

export default CvCard;