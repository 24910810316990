import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { byteCheckCount } from '../../utils/inputValidate'
import ConfirmDlg from '../Dlg/ConfirmDlg';

import './contact.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../modules';
import { postContactDataThunk } from '../../modules/contact';


function ContactForm() {
    const { t } = useTranslation();

    const [userName, setUserName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [title, setTitle] = useState<string>('');
    const [contents, setContents] = useState<string>('');
    const [isInquiring, setIsInquiring] = useState<boolean>(false);

    const [isShowDlg, setIsShowDlg] = useState<number>(0);

    const onChangeUserName = (e: any) => {
        setUserName(e.target.value);
    };
    const onChangeEmail = (e: any) => {
        setEmail(e.target.value);
    };
    const onChangeTitle = (e: any) => {
        setTitle(e.target.value);
    };
    const onChangeContents = (e: any) => {
        setContents(e.target.value);
    };
    const validateUserName = () => {
        // ~100 byte
        return userName.length > 0 && byteCheckCount(userName) < 100;
    }
    const validateTitle = () => {
        // ~45 byte
        return title.length > 0 && byteCheckCount(title) < 45;
    }
    const validateSubmit = () => {
        return (
            validateUserName() &&
            email.length > 0 &&
            validateTitle() &&
            contents.length > 0 &&
            !isInquiring
        );
    };
    // async function sendContact(body: FormData) {
    //     const contactData = await postContact(body);
    //     console.log({ contactData });
    //     if (contactData.request_status === 'SUCCESS') {
    //         setTitle('');
    //         setContents('');

    //         setIsShowDlg(1);
    //         // alert("Send Success");
    //     } else {
    //         setIsShowDlg(2);
    //         // alert("Send Fail");
    //     }
    //     setIsInquiring(false);
    // }

    const { data, loading, error }: any = useSelector((state: RootState) => state.contact.postContact);
    const dispatch = useDispatch();

    const onSummit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsInquiring(true);
        // NOTE : algo health app의 질문 등록 process와 맞추기 위해 아래와 같이 구성
        const formData = new FormData();
        formData.append('category', '"1"');
        formData.append('device_id', '"' + '' + '"');
        formData.append('email', '"' + email + '"');
        formData.append('title', '"' + title + '"');
        formData.append('content', '"' + contents + '"');
        formData.append('name_1', '"' + userName + '"');
        formData.append('name_2', '"*viaHomepage*"');
        // console.log({ formData });
        dispatch(postContactDataThunk(formData))
        // sendContact(formData);
    }

    if (!loading && !error && data && isInquiring) {
        if (data.request_status) {
            if (data.request_status === 'SUCCESS') {
                setTitle('');
                setContents('');
                setIsShowDlg(1);
            } else {
                setIsShowDlg(2);
            }
        }
        setIsInquiring(false)
    }

    // const animatedFadeInName = useScrollFadeIn('up', 1, 0);
    // const animatedFadeInEmail = useScrollFadeIn('up', 1, 0.3);
    // const animatedFadeInTitle = useScrollFadeIn('up', 1, 0.6);
    // const animatedFadeInContent = useScrollFadeIn('up', 1, 0.9);
    // const animatedFadeInBtnSend = useScrollFadeIn('up', 1, 0);

    return (
        <>
            <ConfirmDlg isShow={isShowDlg} setIsShow={setIsShowDlg}></ConfirmDlg>
            <form onSubmit={onSummit} encType="multipart/form-data">
                <div className="form">
                    <div>
                        <label>{t('contact.body.name')}<span>*</span></label>
                        <input type="text" id="name" value={userName} onChange={onChangeUserName} className={userName.length === 0 || validateUserName() ? "" : "invalid"} />
                    </div>
                    <div>
                        <label>{t('contact.body.email')}<span>*</span></label>
                        <input type="email" id="email" value={email} onChange={onChangeEmail} />
                    </div>
                    <div>
                        <label>{t('contact.body.title')}<span>*</span></label>
                        <input type="text" id="title" value={title} onChange={onChangeTitle} className={title.length === 0 || validateTitle() ? "" : "invalid"} />
                    </div>
                    <div>
                        <label>{t('contact.body.content')}<span>*</span></label>
                        <textarea name="message" id="message" rows={10} value={contents} onChange={onChangeContents}></textarea>
                    </div>
                </div>
                <div className="ctt_submit">
                    <button type="submit" disabled={!validateSubmit()}>{t('contact.btn.send')} </button>
                </div>
            </form>
        </>
    )
}

export default ContactForm;