import React from 'react';

import './Policy.css'

// NOTE : 약관의 버전을 추후 rendering된 화면서 확인할 수 있도록 부모 div에 'data-p-version'을 추가해줄 것.

export default function LocationTerm({p_version, lang, numChapter}: {p_version:number, lang:string, numChapter:number}) {
    if (lang === 'ko') {
        switch (p_version) {
            case 0:
            // case 2:
            //     return ()
            case 1:
                return (
                <div data-p-version={1}>
                <h1 className="pol_tit">위치정보 이용약관</h1>
                <h2 className="pol_sub_tit">제 1 조 (목적)</h2>
                <p className="title_content">이 약관은 주식회사 알고리고(이하 “회사”)가 제공하는 위치정보사업 또는 위치기반서비스사업과 관련하여 회사와 개인위치정보주체와의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다</p>
                <h2 className="pol_sub_tit">제 2 조 (약관의 효력 및 변경)</h2>
                <ul className="pol_unord_ul">
                    <li><span>&middot;</span>본 약관은 이용자가 본 약관에 동의하고 회사가 정한 절차에 따라 위치기반서비스의 이용자로 등록됨으로써 효력이 발생합니다.</li>
                    <li><span>&middot;</span>회사는 법률이나 위치기반서비스의 변경사항을 반영하기 위한 목적 등으로 약관을 수정할 수 있습니다.</li>
                    <li><span>&middot;</span>약관이 변경되는 경우 회사는 변경사항을 최소 7일 전에 회사의 홈페이지 등 기타 공지사항 페이지를 통해 게시합니다.</li>
                    <li><span>&middot;</span>단, 개정되는 내용이 이용자 권리의 중대한 변경이 발생하는 경우에는 30일 전에 게시하도록 하겠습니다.</li>
                </ul>
                <h2 className="pol_sub_tit">제 3 조 (약관외 준칙)</h2>
                <p className="title_content">이 약관에 명시되지 않은 사항에 대해서는 위치 정보의 보호 및 이용 등에 관한 법률, 전기통신사업법, 정보통신망 이용 촉진 및 보호 등에 관한 법률 등 관계법령 및 회사가 정한 지침 등의 규정에 의합니다.</p>
                <h2 className="pol_sub_tit">제 4 조 (서비스의 내용 및 이용요금)</h2>
                <p className="title_content">회사는 직접 위치정보를 수집하거나 위치정보사업자로부터 위치정보를 전달받아 아래와 같은 위치 기반 서비스를 제공합니다.</p>
                <ul className="pol_ord_ul">
                    <li>
                        <span>&middot;</span>위치정보를 활용한 생활편의 정보 제공(ex.오늘의 날씨 등)<br />
                        <span>&middot;</span>위치정보를 활용한 콘텐츠 또는 통계자료 제공<br />
                        <span>&middot;</span>이용자 보호 및 부정 이용 방지:개인위치정보주체 또는 이동성 있는 기기의 위치를 이용하여 권한없는 자의 비정상적인 서비스 이용 시도 등을 차단<br />
                        <span>&middot;</span>Geo Tagging 서비스: 게시물에 포함되어 있는 스마트기기(스마트체어 또는 방석)의 위치정보가 게시물과 함께 저장<br />
                        <span>&middot;</span>상기에 표시된 위치기반서비스의 이용요금은 무료 또는 유료입니다. 무선 서비스 이용 시 발생하는 데이터 통신료는 별도이며, 이용자가 가입한 각 이동통신사의 정책에 따릅니다. 다만, 별도의 유료서비스의 경우는 해당 서비스에 명시된 요금을 지불하여야 사용 가능하며, 만 19세미만의 미성년자는 법정대리인의 동의가 있는 경우에만 사용 가능합니다.<br />
                    </li>
                </ul>
                <h2 className="pol_sub_tit">제 5 조 (서비스 이용의 변경<span>&middot;</span>제한<span>&middot;</span>중지)</h2>
                <ul className="pol_unord_ul">
                    <li><span>&middot;</span>회사는 위치기반서비스 사업자의 정책변경 등과 같이 회사의 제반사정 또는 법률상의 이유로 위치기반서비스를 유지할 수 없는 경우 서비스의 전부 또는 일부를 제한, 변경하거나 중지할 수 있습니다.</li>
                    <li>
                        <span>&middot;</span>회사는 다음 각 호의 어느 하나에 해당하는 경우에는 서비스의 전부 또는 일부를 제한하거나 중단할 수 있습니다.
                        <ul className="pol_ord_ul">
                            <li>
                                1. 시스템 점검, 증설, 유지보수 및 교체 등<br />
                                2. 천재지변, 전쟁, 폭동, 화재, 파업, 정부기관의 통제 등 회사의 합리적인 노력으로 제어할 수 없는 사유가 발생하거나 발생할 우려가 있는 경우<br />
                                3. 전기통신사업법에 의한 기간통신사업자가 전기통신서비스를 중지하는 등 기타 부득이한 사유가 있는 경우<br />
                                4. 고객이 회사의 정상적인 영업활동을 방해하는 경우<br />
                                5. 사업성 악화 등 회사의 경영상 판단으로 서비스 제공을 중단하는 경우<br />
                            </li>
                        </ul>
                    </li>
                    <li><span>&middot;</span>제1항에 의한 위치기반서비스 중단의 경우 회사는 사전에 홈페이지 등에 공지하거나 개인위치정보주체에게 통지합니다.</li>
                </ul>
                <h2 className="pol_sub_tit">제 6 조 (위치정보의 이용 및 보호)</h2>
                <ul className="pol_unord_ul">
                    <li><span>&middot;</span>회사는 개인위치정보를 이용하여 서비스를 제공하고자 할 때에는 해당 내용을 미리 약관에 명시한 후 개인위치정보주체(위치기반서비스를 이용하는 자. 위치기반서비스를 이용하는 자가 법인이나 단체일 때에는 그 법인이나 단체의 임직원, 피용자, 대리인 등으로서 위치기반서비스를 직접 이용하는 자연인과 그 법인 및 단체와 계약 등을 체결하고 위치기반서비스를 직접 이용하는 자연임을 말함)의 동의를 얻어야 합니다.</li>
                    <li>
                        <span>&middot;</span>회사는 개인위치정보주체의 동의 없이 개인위치정보를 제3자에게 제공하지 아니하며, 제3자에게 제공하는 경우에는 제공 받는 대상과 제공목적을 사전에 개인위치정보주체에게 고지하고 동의를 받습니다. 다만, 다음의 경우는 예외로 합니다.
                        <ul className="pol_ord_ul">
                            <li>
                                1. 법령의 규제에 의거하거나 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우<br />
                                2. 고객이 온라인 게시 등의 방법으로 통보할 것을 미리 요청하는 경우<br />
                            </li>
                        </ul>
                    </li>
                </ul>
                <h2 className="pol_sub_tit">제 7 조 (위치정보 이용<span>&middot;</span>제공 사실 확인자료 보유근거 및 보유기간)</h2>
                <ul className="pol_unord_ul">
                    <li><span>&middot;</span>회사는 위치정보의 보호 및 이용 등에 관한 법률 제16조 제2항에 근거하여 개인위치정보주체에 대한 위치정보 수집∙이용∙제공사실 확인자료를 위치정보시스템에 자동으로 기록하며, 6개월이상 보관합니다.</li>
                    <li><span>&middot;</span>고객은 회사에 대하여 언제든지 개인위치정보를 이용한 위치기반서비스 및 제3자 제공에 대한 동의의 전부 또는 일부를 철회할 수 있습니다. 이 경우 회사는 수집한 개인위치정보 및 위치정보 이용, 제공사실 확인자료를 파기합니다.</li>
                    <li><span>&middot;</span>고객은 회사에게 별도로 통지하여 자신의 개인위치정보의 수집∙이용∙제공을 일시적으로 중지해 달라고 요구할 수 있으며, 회사는 이를 거절할 수 없고 중지를 위한 기술적 수단을 갖추고 있습니다.</li>
                    <li><span>&middot;</span>고객은 본인에 대한 위치정보 수집∙이용∙제공 사실 확인자료와 위치정보법 또는 다른 법률의 규정의 의거 제3자에게 제공된 이유와 해당내용을 회사에게 열람,고지 또는 오류 정정을 요구할 수 있습니다.</li>
                </ul>
                <h2 className="pol_sub_tit">제 8 조 (법정대리인의 권리)</h2>
                <ul className="pol_unord_ul">
                    <li><span>&middot;</span>회사는 14세미만 아동의 개인위치정보를 이용∙제공하고자 할 때(개인위치정보주체가 지정하는 제3자에게 제공하는 서비스를 하는 경우 포함)에는 14세 미만의 아동과 그 법정대리인의 동의를 받아야 한다.</li>
                    <li><span>&middot;</span>회사는 14세 미만 아동의 개인위치정보 또는 위치정보 이용∙제공 사실 확인자료를 이용약관에 명시 또는 고지한 범위를 넘어 이용하거나 제3자에게 제공하고자 하는 경우에는 14세 미만의 아동과 법정대리인의 동의를 받아야 합니다. 단, 아래의 경우는 제외합니다.
                        <ul className="pol_ord_ul">
                            <li>
                                1. 위치정보 및 위치기반서비스 제공에 따른 요금정산을 위하여 위치정보 이용, 제공사실 확인자료가 필요한 경우<br />
                                2. 통계작성, 학술연구 또는 시장조사를 위하여 특정 개인을 알아볼 수 없는 형태로 가공하여 제공하는 경우<br />
                            </li>
                        </ul>
                    </li>
                </ul>
                <h2 className="pol_sub_tit">제 9 조 (8세 이하 아동 동의 보호의무자의 권리)</h2>
                <ul className="pol_unord_ul">
                    <li>
                        <span>&middot;</span>회사는 아래의 경우에 해당하는 자(8세이하의 아동 등)의 보호의무자가 8세 이하 아동 등의 생명 또는 신체보호를 위하여 개인위치정보를 이용하거나 제공하는 데 동의한 경우에는 본인의 동의가 있는 것으로 간주합니다.
                        <ul className="pol_ord_ul">
                            <li>
                                1. 8세 이하의 아동<br />
                                2. 금치산자<br />
                                3. 장애인복지법 제2조 제2항 제2호에서 규정하는 정신적 장애를 가진 자로서 장애인 고용촉진 및 직업재활법 제2조 제2호의 규정에 따라 중증장애인에 해당하는 자(장애인복지법 제32조의 규정에 따라 장애인등록을 한 자에 한합니다)<br />
                                4. 피성년후견인<br />
                            </li>
                        </ul>
                    </li>
                    <li><span>&middot;</span>8세 이하 아동 등의 생명 또는 신체보호를 위하여 개인위치정보를 이용하거나 제공하는 데 동의하고자 하는 보호의무자는 보호의무자임을 증명하는 서면 동의서를 첨부하여 서비스 제공자에게 제출하여야 합니다.</li>
                    <li><span>&middot;</span>8세 이하 아동 등의 개인위치정보를 이용하거나 제공하는 데 동의하는 보호의무자는 개인위치정보주체 권리의 전부를 행사할 수 있습니다.</li>
                </ul>
                <h2 className="pol_sub_tit">제 10 조 (이용자의 의무)</h2>
                <ul className="pol_unord_ul">
                    <li><span>&middot;</span>이용자는 서비스 이용에 필요한 정보를 제공하거나 등록할 때 현재의 사실과 일치하는 완전한 정보를 제공∙등록하여야 하며, 변경사항이 발생하면 즉시 변경하고 통지해야 합니다. 개인위치정보주체가 미성년자라면 이용자는 법정 대리인의 동의를 얻는 등 위치정보법이 정한 절차를 따라야 합니다. 이용자가 이를 위반하여 발생하는 모든 손실에 대해서 회사는 책임을 부담하지 않습니다.</li>
                    <li>
                        <span>&middot;</span>이용자는 다음 각 호의 1에 해당하는 행위를 해서는 안됩니다.
                        <ul className="pol_ord_ul">
                            <li>
                                1. 개인위치정보 수집과 관련된 설비의 오동작이나 정보 등의 파괴 및 혼란을 유발하는 컴퓨터 바이러스 감염자료를 등록하거나 유포하는 행위<br />
                                2. 타인으로 가장하는 행위 및 타인과의 관계를 허위로 명시하는 행위<br />
                                3. 타인의 개인위치정보를 무단으로 유용하거나 유출하는 행위<br />
                                4. 기타 불법적이거나 부당한 행위<br />
                            </li>
                        </ul>
                    </li>
                    <li><span>&middot;</span>이용자는 서비스 이용에 필요한 스마트기기(스마트체어 또는 스마트방석)을 무단으로 제3자에게 양도하거나 담보할 수 없으며, 스마트기기의 사용 및 관리, 유지/보수, A/S와 관련한 세부적인 사항은 회사와 이용자간의 별도 합의에 따릅니다.</li>
                    <li><span>&middot;</span>이용자는 아이디(ID, 이메일)와 비밀번호(PASSWORD)를 부여했을 때 제3자에게 아이디와 비밀번호를 이용하게 해서는 안되며, 이에 대한 관리책임을 부담합니다.</li>
                    <li><span>&middot;</span>이용자는 관계 법령, 본 약관의 규정, 서비스 이용안내 및 서비스상에 공지한 주의사항 등에 대해 회사가 통지하는 사항 등을 지켜야 합니다.</li>
                </ul>
                <h2 className="pol_sub_tit">제 11 조 (양도금지)</h2>
                <p className="title_content">고객의 서비스를 받을 권리는 이를 양도 내지 증여하거나 담보제공 등의 목적으로 처분할 수 없습니다.</p>
                <h2 className="pol_sub_tit">제 12 조 (손해배상)</h2>
                <ul className="pol_unord_ul">
                    <li><span>&middot;</span>본 약관의 규정을 위반하여 회사에게 손해를 발생시킨 이용자는 회사에게 발생한 모든 손해를 배상해야 합니다.</li>
                    <li><span>&middot;</span>고객이 본 약관의 규정을 위반하여 발생하는 개인정보주체를 비롯한 제3자의 이의제기, 분쟁, 소송 등 법률상 또는 사실상 제반 분쟁에 대한 모든 책임은 전적으로 고객이 부담하며, 고객은 이러한 분쟁과 관련하여 회사에게 발생하는 손해∙손실을 배상하거나 보상해야 합니다.</li>
                    <li><span>&middot;</span>회사가 고의 또는 과실로 고객에게 손해가 발생하면 고객은 회사에게 손해배상을 청구할 수 있으며, 이 경우 회사는 고의, 없음을 입증하지 못하는 경우 책임을 면할 수 없습니다.</li>
                </ul>
                <h2 className="pol_sub_tit">제 13 조 (면책)</h2>
                <ul className="pol_unord_ul">
                    <li>
                        <span>&middot;</span>회사는 다음 각 호의 사유로 정상적인 서비스를 제공할 수 없는 경우 이로 인하여 고객에게 발생한 손해에 대해서는 책임을 부담하지 않습니다.
                        <ul className="pol_ord_ul">
                            <li>
                                1. 천재지변 또는 이에 준하는 불가항력의 상태가 있는 경우<br />
                                2. 서비스 제공을 위하여 회사가 서비스 제휴계약을 체결한 제3자의 고의적인 서비스 방해가 있는 경우<br />
                                3. 고객의 귀책사유로 서비스 이용에 장애가 있는 경우<br />
                                4. 제1호 혹은 제3호를 제외한 기타 회사의 고의,과실이 없는 사유로 인한 경우<br />
                            </li>
                        </ul>
                    </li>
                    <li><span>&middot;</span>회사는 서비스 및 서비스에 게재된 정보, 자료, 사실의 신뢰도, 정확성 등에 대해서는 보증을 하지 않으며 이로 인해 발생한 고객의 손해에 대하여는 책임을 부담하지 않습니다.</li>
                    <li><span>&middot;</span>본 약관 제13조 1항에 따른 손해가 발생시, 이를 증명할 책임은 회사에 있으며, 회사가 자신의 고의 또는 과실 때문에 고객에게 손해가 발생한 것이 아님을 입증하면 회사는 이와 관련한 손해에 관한 배상책임을 면합니다.</li>
                </ul>
                <h2 className="pol_sub_tit">제 14 조 (분쟁의 조정)</h2>
                <ul className="pol_unord_ul">
                    <li><span>&middot;</span>서비스제공자는 위치정보와 관련된 분쟁에 대해 당사자 간 협의가 이루어지지 않거나 협의를 할 수 없으면 위치정보의 보호 및 이용 등에 관한 법률 제28조의 규정에 따라 방송통신위원회에 재정을 신청하거나 개인정보보호법 제43조에 의한 방송통신위원회 또는 개인정보분쟁조정위원회에 재정 또는 분쟁조정을 신청할 수 있습니다.</li>
                    <li><span>&middot;</span>상기 항으로도 분쟁이 해결되지 않으면 회사와 고객 간 당사자는 민사소송법상의 관할법원에 소를 제기할 수 있습니다.</li>
                </ul>
                <h1 className="pol_tit">부칙</h1>
                <h2 className="pol_sub_tit">제 1 조 (시행일)</h2>
                <p className="title_content">이 약관은 2020년 04월 01일부터 시행합니다.</p>
            </div>
                )
            }
        }
    else if (lang === 'en') {
        switch (p_version) {
            case 0:
            // case 1:
            //     return ()
        }
    }
    return (
        <div>
            <h1 className="pol_tit">Page not found.</h1>
            <h2 className="title_content">language code : {lang}<br/>document Version : {p_version}</h2>
        </div>
    )
}