import { createReducer } from 'typesafe-actions';
import { newsDataState, newsDataAction } from './types';
import { getNewsDataAsync, } from './actions';
import { asyncState, createAsyncReducer, transformToArray } from '../../lib/reducerUtils';

const initialState: newsDataState = {
    newsList: asyncState.initial()
};

const news = createReducer<newsDataState, newsDataAction>(initialState).handleAction(
    transformToArray(getNewsDataAsync),
    createAsyncReducer(getNewsDataAsync, 'newsList')
);

export default news;