import React from 'react'
import { Link } from 'react-router-dom';
import { TitleComponent } from '../TitleComponent'
import PictureWebpComponent from '../PictureWebpComponent'
import './service.css';

// images
import wlogo from '../../images/logo-algowork@3x.png';

import algowork_w_3x_png from '../../images/illustration-algowork-w@3x.png';
import algowork_w_2x_png from '../../images/illustration-algowork-w@2x.png';
import algowork_w_1x_png from '../../images/illustration-algowork-w.png';

import work1_w_3x_png from '../../images/illustration-work-1-w@3x.png';
import work1_w_2x_png from '../../images/illustration-work-1-w@2x.png';
import work1_w_1x_png from '../../images/illustration-work-1-w.png';

import work2_w_3x_png from '../../images/illustration-work-2-w@3x.png';
import work2_w_2x_png from '../../images/illustration-work-2-w@2x.png';
import work2_w_1x_png from '../../images/illustration-work-2-w.png';

import work3_w_3x_png from '../../images/illustration-work-3-w@3x.png';
import work3_w_2x_png from '../../images/illustration-work-3-w@2x.png';
import work3_w_1x_png from '../../images/illustration-work-3-w.png';

import work_team_w_3x_png from '../../images/image-work-team-w@3x.png';
import work_team_w_2x_png from '../../images/image-work-team-w@2x.png';
import work_team_w_1x_png from '../../images/image-work-team-w.png';
import work_company_w_3x_png from '../../images/image-work-company-w@3x.png';
import work_company_w_2x_png from '../../images/image-work-company-w@2x.png';
import work_company_w_1x_png from '../../images/image-work-company-w.png';
import work_personal_w_3x_png from '../../images/image-work-personal-w@3x.png';
import work_personal_w_2x_png from '../../images/image-work-personal-w@2x.png';
import work_personal_w_1x_png from '../../images/image-work-personal-w.png';


import { Trans, useTranslation } from 'react-i18next';
import { useScrollFadeIn } from '../../utils/useScrollEvent';


function AlgoWork() {
    const { t } = useTranslation();

    // sec 1
    const animatedFadeInUpTitle1 = useScrollFadeIn('', 0.5, 0, 0.2, 0, 20);
    const animatedFadeInUpTitle_content_1 = useScrollFadeIn('', 0.5, 0.2, 0.2, 0, 20);
    const animatedFadeInUpTitleBtn = useScrollFadeIn('', 0.5, 0.4, 0.2, 0, 20);
    // sec 2
    const animatedFadeInUpTitle2 = useScrollFadeIn('up', 0.5, 0);
    const animatedFadeInUpTitle_content_2 = useScrollFadeIn('up', 0.5, 0.2);
    const animatedFadeInUpCard1 = useScrollFadeIn('', 1, 0, 0.2, 0, 40);
    const animatedFadeInUpCard2 = useScrollFadeIn('', 1, window.matchMedia('(min-width: 960px)').matches ? 0.2 : 0, 0.2, 0, 40);
    const animatedFadeInUpCard3 = useScrollFadeIn('', 1, window.matchMedia('(min-width: 960px)').matches ? 0.4 : 0, 0.2, 0, 40);
    // sec 3
    const animatedFadeInUpTitle3 = useScrollFadeIn('up', 0.7, 0.2, 0.7);
    const animatedFadeInUpTitle_content_3 = useScrollFadeIn('up', 0.5, 0.2, 0.5);
    // sec 4
    const animatedFadeInUpTitle4 = useScrollFadeIn('up', 0.7, 0.2, 0.7);
    const animatedFadeInUpTitle_content_4 = useScrollFadeIn('up', 0.5, 0.2, 0.5);
    // sec 5
    const animatedFadeInUpTitle5 = useScrollFadeIn('up', 0.7, 0.2, 0.7);
    const animatedFadeInUpTitle_content_5 = useScrollFadeIn('up', 0.5, 0.2, 0.5);

    return (
        <section className="container service s_study s_work">
            <TitleComponent title={t('header.nav.service.algowork')} />
            <section className="content s_content_1">
                <div className="inner">
                    <div className="h_inner_1">
                        <h1 className="title" {...animatedFadeInUpTitle1}>
                            <img src={wlogo} alt="logo" />
                            <Trans i18nKey="service.algowork.intro.title" components={[<br />, <em className="work_line work_color"></em>, <em className="work_line"></em>]} />
                        </h1>
                        <p className="title_content" {...animatedFadeInUpTitle_content_1}>
                            <Trans i18nKey="service.algowork.intro.title_content" components={[<br className="u_desk" />]} />
                            <span className="s_comingsoon lang_en_font">coming soon!</span>
                        </p>
                        <div className="s_main_img">
                            <PictureWebpComponent fb_img_1x={algowork_w_1x_png} fb_img_2x={algowork_w_2x_png} fb_img_3x={algowork_w_3x_png} fb_alt="algowork" alt_img_name="illustration-algowork"></PictureWebpComponent>
                        </div>
                        <div className="down-btn-dis" {...animatedFadeInUpTitleBtn}>
                            <Link to="/contact/inquire">
                                <button>{t('service.algowork.intro.btn1')}<span className="arrow-r"></span></button>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            <section className="content_wide s_banner_wrap">
                <div className="s_banner">
                    <div className="s_banner_text">
                        <p className="s_banner_sub">{t('service.algowork.banner1.title')}</p>
                        <p className="s_banner_main"><Trans i18nKey="service.algowork.banner1.title_content" components={[<span className="lang_en_font"></span>]} /></p>
                    </div>
                    <div className="s_banner_btn">
                        <a href="https://smartstore.naver.com/algorigo" target="_black"><button>{t('service.algowork.banner1.btn')}</button></a>
                    </div>
                </div>
            </section>
            <section className="content s_content_2">
                <div className="inner">
                    <h1 className="title" {...animatedFadeInUpTitle2}><Trans i18nKey="service.algowork.feature.title" components={[<br className="u_mobile" />]} /></h1>
                    <p className="title_content" {...animatedFadeInUpTitle_content_2}>
                        <Trans i18nKey="service.algowork.feature.title_content" components={[<br className="u_desk" />]} />
                    </p>
                    <div className="rect_box" {...animatedFadeInUpCard1}>
                        <PictureWebpComponent fb_img_1x={work1_w_1x_png} fb_img_2x={work1_w_2x_png} fb_img_3x={work1_w_3x_png} alt_img_name="illustration-work-1"></PictureWebpComponent>
                        <p><Trans i18nKey="service.algowork.feature.card1" components={[<br className="u_mobile" />, <br />]} /></p>
                    </div>
                    <div className="rect_box" {...animatedFadeInUpCard2}>
                        <PictureWebpComponent fb_img_1x={work2_w_1x_png} fb_img_2x={work2_w_2x_png} fb_img_3x={work2_w_3x_png} alt_img_name="illustration-work-2"></PictureWebpComponent>
                        <p><Trans i18nKey="service.algowork.feature.card2" components={[<br className="u_mobile" />, <br />]} /></p>
                    </div>
                    <div className="rect_box" {...animatedFadeInUpCard3}>
                        <PictureWebpComponent fb_img_1x={work3_w_1x_png} fb_img_2x={work3_w_2x_png} fb_img_3x={work3_w_3x_png} alt_img_name="illustration-work-3"></PictureWebpComponent>
                        <p><Trans i18nKey="service.algowork.feature.card3" components={[<br className="u_mobile" />, <br />]} /></p>
                    </div>
                </div>
            </section>
            <section className="content s_content_7">
                <div className="inner">
                    <h1 className="title" {...animatedFadeInUpTitle3}>
                        <span>company</span>
                        <Trans i18nKey="service.algowork.detail1.title" components={[<br className="u_mobile" />, <em className="work_line"></em>]} />
                    </h1>
                    <div className="s_img_bottom">
                        <PictureWebpComponent fb_img_1x={work_company_w_1x_png} fb_img_2x={work_company_w_2x_png} fb_img_3x={work_company_w_3x_png} alt_img_name="image-work-company" />
                    </div>
                    <div className="s_group_text">
                        <p className="title_content" {...animatedFadeInUpTitle_content_3}><Trans i18nKey="service.algowork.detail1.title_content" components={[]} /></p>
                    </div>
                </div>
            </section>
            <section className="content s_content_7">
                <div className="inner">
                    <h1 className="title" {...animatedFadeInUpTitle4}>
                        <span>team</span>
                        <Trans i18nKey="service.algowork.detail2.title" components={[<br className="u_mobile" />, <em className="work_line"></em>]} />
                    </h1>
                    <div className="s_img_bottom">
                        <PictureWebpComponent fb_img_1x={work_team_w_1x_png} fb_img_2x={work_team_w_2x_png} fb_img_3x={work_team_w_3x_png} alt_img_name="image-work-team" />
                    </div>
                    <div className="s_group_text">
                        <p className="title_content" {...animatedFadeInUpTitle_content_4}><Trans i18nKey="service.algowork.detail2.title_content" components={[<br className="u_mobile" />]} /></p>
                    </div>
                </div>
            </section>
            <section className="content s_content_7">
                <div className="inner">
                    <h1 className="title" {...animatedFadeInUpTitle5}>
                        <span>personal</span>
                        <Trans i18nKey="service.algowork.detail3.title" components={[<br className="u_mobile" />, <em className="work_line"></em>]} />
                    </h1>
                    <div className="s_img_bottom">
                        <PictureWebpComponent fb_img_1x={work_personal_w_1x_png} fb_img_2x={work_personal_w_2x_png} fb_img_3x={work_personal_w_3x_png} alt_img_name="image-work-personal" />
                    </div>
                    <div className="s_group_text">
                        <p className="title_content" {...animatedFadeInUpTitle_content_5}><Trans i18nKey="service.algowork.detail3.title_content" components={[<br />]} /></p>
                    </div>
                </div>
            </section>
            <section className="content_wide s_qna">
                <div className="s_qna_content">
                    <p><Trans i18nKey="service.algowork.banner2.title" components={[<b></b>]} /></p>
                    <Link to="/contact/inquire">
                        <button>{t('service.algowork.banner2.btn')}</button>
                    </Link>
                </div>
            </section>
        </section>
    )
}

export default AlgoWork;
