import { Trans, useTranslation } from 'react-i18next';
import { TitleComponent } from '../TitleComponent'

import './contact.css';
import ContactForm from './ContactForm';
import { useScrollFadeIn } from '../../utils/useScrollEvent'

function Contact() {
    const { t } = useTranslation();

    const animatedFadeInTitle1 = useScrollFadeIn('up', 0.5, 0);
    const animatedFadeInTitleContent1 = useScrollFadeIn('up', 0.8, 0.5);

    const map_lang_url = document.documentElement.lang === 'ko' ? "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d791.2596296548736!2d127.05275862851812!3d37.50700966211634!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca4122ca5e0cf%3A0x9334918234da396e!2z7ISc7Jq47Yq567OE7IucIOqwleuCqOq1rCDthYztl6TrnoDroZw3N-q4uCAxMS0xMg!5e0!3m2!1sko!2skr!4v1710997969963!5m2!1sko!2skr" : "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3165.0385636512483!2d127.05340369999999!3d37.5070086!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca4122ca5e0cf%3A0x9334918234da396e!2s11-12%20Teheran-ro%2077-gil%2C%20Gangnam-gu%2C%20Seoul!5e0!3m2!1sen!2skr!4v1711001351844!5m2!1sen!2skr";

    return (
        <section className="container ctt_ctr">
            <TitleComponent title={t('header.nav.contact')} />
            <section className="content ctt_content">
                <div className="inner">
                    <div className="ctt_tit bg_wrap" {...animatedFadeInTitle1}>
                        <div className="bg--yellow7 light"></div>
                        <h1 className="title">{t('contact.title')}</h1>
                        <p className="title_content" {...animatedFadeInTitleContent1}>{t('contact.title_content')}</p>
                    </div>
                    <div className="ctt_form">
                        {/* form */}
                        <ContactForm></ContactForm>
                        {/* Map */}
                        <div className="ctt_map">
                            <iframe title="googlemap" width="100%" height="420" src={map_lang_url}></iframe>
                            <p>
                                <Trans i18nKey="footer.info.address" components={[<span className="lang_en_font"></span>]} />
                            </p>
                            <p><a href="mailto:admin@algorigo.com">admin@algorigo.com</a></p>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    )
}

export default Contact;