import React, {useEffect, useRef} from 'react';

import './Policy.css'

// NOTE : 약관의 버전을 추후 rendering된 화면서 확인할 수 있도록 부모 div에 'data-p-version'을 추가해줄 것.

export default function PrivacyPolicy({ p_version, lang, numChapter }: { p_version: number, lang: string, numChapter: number }) {
    const content1Ref = useRef<HTMLDivElement>(null);
    const content2Ref = useRef<HTMLDivElement>(null);
    const content3Ref = useRef<HTMLDivElement>(null);
    const content4Ref = useRef<HTMLDivElement>(null);
    const content5Ref = useRef<HTMLDivElement>(null);
    const content6Ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setTimeout(function () {
            if (numChapter === 1){
                content1Ref.current?.scrollIntoView({ behavior: 'smooth' });
            }
            else if (numChapter === 2){
                content2Ref.current?.scrollIntoView({ behavior: 'smooth' });
            }
            else if (numChapter === 3){
                content3Ref.current?.scrollIntoView({ behavior: 'smooth' });
            }
            else if (numChapter === 4){
                content4Ref.current?.scrollIntoView({ behavior: 'smooth' });
            }
            else if (numChapter === 5){
                content5Ref.current?.scrollIntoView({ behavior: 'smooth' });
            }
            else if (numChapter === 6){
                content6Ref.current?.scrollIntoView({ behavior: 'smooth' });
            }
            
        }, 500);
    }, []);

    if (lang === 'ko') {
        switch (p_version) {
            case 0:
            // case 2:
            //     return ()
            case 1:
                return (
                    <div className="pol_tab_2" data-p-version={1}>
                        <h1 className="pol_tit">개인정보 수집 및 이용 동의</h1>
                        <h2 className="pol_sub_tit" ref={content1Ref}>1. 개인정보의 수집 항목 및 이용목적</h2>
                        <p className="title_content">(주)알고리고는 개인정보를 안전하게 취급하는데 최선을 다합니다. 이용자가 스마트체어 또는 스마트방석을 이용한 알고리고의 서비스를 이용하기 위해 회원가입을 할 경우, 알고리고는 서비스 이용을 위해 필요한 최소한의 개인정보를 수집합니다. ‘서비스’는 기본적인 서비스 제공을 위한 ‘필수동의’와 고객별로 필요한 서비스를 제공하기 위한 ‘선택동의’로 구분되어 아래와 같은 정보를 수집하고 있습니다. 선택동의를 하지 않은 경우에는 서비스 이용에 제한이 있을 수 있습니다.</p>
                        <h2 className="pol_sub_tit">[필수동의 사항]</h2>
                        <table className="required_tbl">
                            <th>항목</th>
                            <th>이용목적</th>
                            <th>보유기간</th>
                            <tbody>
                                <tr>
                                    <td>아이디(이메일), 비밀번호</td>
                                    <td>
                                        <span>&middot;</span>서비스 이용에 따른 본인 확인, 개인 식별<br />
                                        <span>&middot;</span>고객센터 운영<br />
                                        <span>&middot;</span>서비스 관련 정보 및 공지<br />
                                    </td>
                                    <td rowSpan={3}>회원탈퇴 후 지체없이 삭제</td>
                                </tr>
                                <tr>
                                    <td>스마트체어 또는 스마트방석 식별정보 <br />(MAC address)</td>
                                    <td>
                                        <span>&middot;</span>스마트체어 또는 스마트 방석 등록 및 등록상태 모니터링<br />
                                        <span>&middot;</span>제품장애 정보
                                    </td>
                                </tr>
                                <tr>
                                    <td>거주지 정보, 프로필 정보 <br />(성별, 생년월일 등)</td>
                                    <td>
                                        <span>&middot;</span>인구통계학적 특성과 개인별 맞춤형 서비스 (날씨정보, 지역별 통계 등) 정보 제공<br />
                                        <span>&middot;</span>서비스 방문 및 이용기록의 분석, 이용자간 관계의 형성, 지인 및 관심사 등에 기반한 맞춤형 서비스 제공
                                    </td>
                                </tr>
                                <tr>
                                    <td>기기 위치 정보 <br />(Location Infomation)</td>
                                    <td>
                                        <span>&middot;</span>Wifi 또는 Bluetooth로 동작하는 스마트 체어(스마트 방석)의 연결을 위한 기기의 OS 권한<br />
                                    </td>
                                    <td rowSpan={1}>앱 사용중에만 앱 내부에 보유</td>
                                </tr>
                            </tbody>
                        </table>
                        <p className="title_content">본인은 개인정보 수집 및 이용관련 안내문을 상세히 읽어보았으며, 이에 관한 설명을 듣고 이해하였습니다. <br />상기 내용의 개인정보 수집 및 이용에 동의합니다.</p>
                        <h2 className="pol_sub_tit">[선택동의 사항]</h2>
                        <table className="selected_tbl">
                            <th>항목</th>
                            <th>항목</th>
                            <th>항목</th>
                            <tbody>
                                <tr>
                                    <td>성명, 전화번호, 이메일</td>
                                    <td><span>&middot;</span>알고리고 소식, 이벤트 및 홍보</td>
                                    <td>1년</td>
                                </tr>
                            </tbody>
                        </table>
                        <p className="title_content">본인은 개인정보 수집 및 이용관련 안내문을 상세히 읽어보았으며, 이에 관한 설명을 듣고 이해하였습니다. <br />상기 내용의 개인정보 수집 및 이용에 동의합니다.</p>
                        <h2 className="pol_sub_tit" ref={content2Ref}>2. 개인정보 수집에 대한 동의</h2>
                        <p className="title_content">고객님에게 서비스 제공을 위해 필요한 최소한의 개인정보이므로 동의를 해 주셔야 서비스 이용이 가능합니다.</p>
                        <h2 className="pol_sub_tit" ref={content3Ref}>3. 수집한 개인정보의 이용 및 제3자 제공</h2>
                        <p className="title_content">‘서비스’는 고객님의 개인정보를 서비스이용약관, 본 개인정보 처리방침의 ‘개인정보의 수집 항목 및 이용 목적’에서 고지한 범위 내에서 사용하며 동 범위를 초과하여 타인 또는 타기관에게 제공하지 않습니다. 다만, ‘고객의 동의’가 있거나 ‘법률에 특별한 규정이 있는 경우’로 행정목적이나 수사목적으로 행정관청 또는 수사기관이 요구해 온 경우라도 무조건 고객의 개인정보를 제공하지 않으며, 법률에 규정된 바에 따라 영장 또는 기관장의 날인이 서면에 의한 경우 등 적법한 절차에 따라 제공합니다.</p>
                        <table className="offered_tbl">
                            <th>제공받는 자</th>
                            <th>제공항목</th>
                            <th>제공목적</th>
                            <th>보유기간</th>
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                        <h2 className="pol_sub_tit" ref={content4Ref}>4. 개인정보의 보유기간 및 이용기간</h2>
                        <p className="title_content">회사는 고객님의 서비스 가입일로부터 서비스를 제공하는 기간 동안에 한하여 고객님의 개인 정보를 보유 및 이용하게 됩니다. 회원 탈퇴를 요청하거나 개인정보의 수집 및 이용에 대한 동의를 철회하는 경우 또는 보유/이용기간이 종료되거나 수집,이용 목적을 달성한 경우 등의 사유발생시 당해 개인정보를 지체없이 파기합니다.</p>
                        <h2 className="pol_sub_tit" ref={content5Ref}>5. 개인정보(계정) 삭제</h2>
                        <p className="title_content">알고리고의 각 서비스별 사용자 앱(App)을 통해 계정을 삭제할 수 있습니다. 계정 삭제는 취소할 수 없으며, 실수로 삭제하더라도 다시 되돌릴 수 없습니다.</p>
                        <ul className="pol_unord_ul">
                            <li>
                                <span>&middot;</span>알고헬스 사용자
                                <ul className="pol_ord_ul">
                                    <li>
                                        1. ‘설정’ &gt; ‘기기 사용자 정보 변경’ &gt; ‘사용자 삭제하기’를 선택하기
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <span>&middot;</span>알고스터디 사용자
                                <ul className="pol_ord_ul">
                                    <li>1. ‘설정’ &gt; ‘사용자 설정’ &gt; ‘회원탈퇴’ 를 선택하기</li>
                                    <li>2. 탈퇴 사유 선택 및 입력하기</li>
                                </ul>
                            </li>
                            <li>
                                <span>&middot;</span>자세알고 사용자
                                <ul className="pol_ord_ul">
                                    <li>
                                        1. ‘설정’ &gt; ‘사용자 정보 변경’ &gt; ‘사용자 삭제’를 선택하기
                                    </li>
                                    <li>
                                    <span>&middot;</span> 자세알고 서비스의 계정은 듀오백 서비스의 회원정보를 연동하여 가입되기 때문에 ‘사용자 삭제’를 하셔도 자세알고 서비스의 사용자정보만 삭제되고, 듀오백 서비스의 사용자정보는 삭제되지 않습니다. 듀오백 서비스의 회원정보 삭제를 원하시면 듀오백 홈페이지를 통해 삭제하실 수 있습니다.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <h2 className="pol_sub_tit" ref={content6Ref}>6. 개인정보의 파기</h2>
                        <p className="title_content">회사는 원칙적으로 이용자의 개인정보를 회원 탈퇴 시 지체없이 파기합니다. 단, 이용자에게 개인정보 보관기간에 대해 별도의 동의를 얻은 경우, 또는 법령에서 일정기간 정보보관 의무를 부과하는 경우에는 해당 기간 동안 개인정보를 안전하게 보관합니다.</p>
                        <ul className="pol_unord_ul">
                            <li>
                                <span>&middot;</span>전자상거래 등에서 소비자보호에 관한 법률
                                <ul className="pol_ord_ul">
                                    <li>
                                        - 계약 또는 청약철회 등에 관한 기록: 5년
                                        - 대금결제 및 재화 등의 공급에 관한 기록: 5년
                                        - 소비자의 불만 또는 분쟁처리에 관한 기록: 3년
                                        - 표시/광고에 관한 기록: 6개월
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <span>&middot;</span>국세기본법, 법인세법, 부가가치세법 등
                                <ul className="pol_ord_ul">
                                    <li>- 거래에 관한 장부 및 증빙서류: 5년</li>
                                </ul>
                            </li>
                            <li>
                                <span>&middot;</span>통신비밀보호법
                                <ul className="pol_ord_ul">
                                    <li>- 로그인 기록: 3개월</li>
                                </ul>
                            </li>
                        </ul>
                        <h1 className="pol_tit">부칙</h1>
                        <h2 className="pol_sub_tit">제 1 조 (시행일)</h2>
                        <p className="title_content">이 약관은 2020년 04월 01일부터 시행합니다.</p>
                    </div>
                )
        }
    }
    else if (lang === 'en') {
        switch (p_version) {
            case 0:
            // case 2:
            //     return ()
            case 1:
                return (
                    <div className="pol_tab_2" data-p-version={1}>
                        <h1 className="pol_tit"></h1>
                        <p className="title_content">
                            Your privacy is important to Algorigo. We developed this Privacy Policy to provide you with information on how we process your personal data when you create an algorigo account, interact with our customer support specialists, visit algorigo.com or or use algorigo app("algo health") that include a link to this Privacy Policy.
                        </p>
                        <h1 className="pol_tit">Categories of Personal Data Processed by algorigo</h1>
                        <p className="title_content">
                            We may collect the following personal information: <br />
                            When you create an algorigo account, we just ask you to provide your email address,  and password. You can choose to provide only your first name or a nickname instead of your full name if you wish.
                        </p>
                        <ul className="pol_ord_ul">
                            <li>
                                <span>&middot;</span> Besides that we may collect your name(nickname) or children's first name(nickname) and year of birth or children's year of birth <br />
                                <span>&middot;</span> MAC address about your device <br />
                                <span>&middot;</span> Location Information : OS permissions for devices for connecting smart chairs (smart cushions) operating on WiFi or Bluetooth
                            </li>
                        </ul>
                        <h2 className="pol_sub_tit">Purposes and Legal grounds:</h2>
                        <p className="title_content">
                            (a) algorigo processes your email address and password because you use your email address and password to sign in to your account. The legal ground for processing your email address and password for this purpose is algorigo's legitimate interest in protecting the security of your account.<br />
                            (b) algorigo also processes your email address for the purpose of sending you important information about your algorigo products, services, apps, or account, such as important safety information or material changes to this Privacy Policy. The legal ground for processing your email address and name for these purposes is algorigo's legitimate interest in providing you important safety or other information about your algorigo products, services, apps, or account or material changes to this Privacy Policy.<br />
                            (c) algorigo also processes your email address to associate it with your algorigo account when you interact with our customer support representatives. The legal ground for this processing is our legitimate interest in providing quality customer support.<br />
                            (d) algorigo also processes your email address to notify customers when they have violated our terms. The legal ground for this processing is our legitimate interest in ensuring a quality experience for all customers and ensuring adherence to our terms.<br />
                        </p>
                        <h2 className="pol_sub_tit">Personal data that is processed when you sync your algorigo device:</h2>
                        <p className="title_content">
                            When you sync your algorigo device through algo Health, we log data about the transmission, such as the sync time and date, geographic location of the device, information about your device, information about the network used to sync (e.g.,Bluetooth), and device battery level.
                        </p>
                        <p className="title_content">
                            We process this information to help identify and resolve errors or syncing issues. The legal ground for processing this information for this purpose is algorigo's legitimate interest in resolving errors or syncing issues and providing quality product support.
                        </p>
                        <h2 className="pol_sub_tit">Personal data that is processed when you communicate with algorigo:</h2>
                        <p className="title_content">
                            When you interact with our customer support representatives by email, online, we collect personal data, such as your name, mailing address, phone number, email address, and contact preferences, and information about the algorigo products you own, such as their serial numbers and dates of purchase. We also may create event logs that are useful in diagnosing product or app performance-related issues and capture information relating to the support or service issue. To improve customer service, subject to applicable laws, we may also record and review conversations with customer support representatives and analyze any feedback provided to us through voluntary customer surveys. With your consent, our customer support representatives may sign in to your algorigo account, if appropriate, to help troubleshoot and resolve your issue.
                        </p>
                        <p className="title_content">
                            We use this information to provide you with customer and product support, to monitor the quality and types of customer and product support we provide to our customers, and to facilitate repairs, returns, or exchanges. The legal ground for processing this information for these purposes is algorigo's legitimate interest in providing quality product support. The legal ground for signing in to your algorigo account, if appropriate, to help troubleshoot and resolve your issue is your consent, which you may withdraw.
                        </p>
                        <h2 className="pol_sub_tit">Other Disclosures:</h2>
                        <p className="title_content">
                            We may disclose personal data about you to others: (a) if we have your valid consent to do so; (b) to comply with legal obligations, such as a valid subpoena, court or judicial order, or other valid legal process; (c) to enforce any of our terms and conditions or policies; or (d) as necessary to pursue available legal remedies or defend legal claims.<br />
                            We may also transfer your personal data to an affiliate, a subsidiary, or a third party in the event of any reorganization, merger, sale, joint venture, assignment, transfer, or other disposition of all or any portion of algorigo's business, assets, or stock, including, without limitation, in connection with any bankruptcy or similar proceeding, provided that any such entity that we transfer personal data to will not be permitted to process your personal data other than as described in this Privacy Policy without providing you notice and, if required by applicable laws, obtaining your consent.
                        </p>
                        <h2 className="pol_sub_tit">Children's Personal Information</h2>
                        <p className="title_content">
                            Unless otherwise specified, the Services are designed for a general audience and are not directed to children. In connection with the Services, we do not knowingly solicit or collect personal information from children under the age of 13 without parental consent. If we learn that we have collected personal information from a child under age 13 without parental consent, we will either seek parental consent or promptly delete that information.
                        </p>
                        <h2 className="pol_sub_tit">Privacy Policy Updates</h2>
                        <p className="title_content">
                            We may update this Privacy Policy from time to time as we add new products, services, and apps, as we improve our current offerings, and as technologies and laws change. You can determine when this Privacy Policy was last revised by referring to the “Last Updated” legend at the top of this page. Any changes will become effective upon our posting of the revised Privacy Policy.
                        </p><p className="title_content">
                            We will provide notice to you if these changes are material and, where required by applicable law, we will obtain your consent. This notice will be provided by email or by posting notice of the changes on the algorigo websites and apps that link to this Privacy Policy.
                        </p>
                        <h2 className="pol_sub_tit">Retention of Personal Data</h2>
                        <p className="title_content">
                            We will retain your personal data as long as your algorigo account is considered to be active. In addition, see below under “Your Rights” for a description of your right of erasure.
                        </p>
                        <h2 className="pol_sub_tit">Your Rights</h2>
                        <p className="title_content">
                            Depending on applicable laws and regulations, you may have the right to access, rectification, erasure (and certain other rights) in relation to personal information that we hold about you (hereinafter referred to as the request). These rights will be subject to specific exclusions and exceptions under applicable laws.
                        </p>
                        <p className="title_content">
                            You can request access to or rectification, porability or erasure of your personal data, or to delete your algorigo account via algo Health app settings.
                        </p>
                        <p className="title_content">
                            If you have a right to exercise these or any other rights under your local laws, please contact algorigo inc. by email at <a href="mailto:admin@algorigo.com">admin@algorigo.com</a>
                        </p>

                        <i>Last updated : May 13, 2024</i>
                    </div>
                )
        }
    }
    return (
        <div>
            <h1 className="pol_tit">Page not found.</h1>
            <h2 className="title_content">language code : {lang}<br />document Version : {p_version}</h2>
        </div>
    )
}