// DO NOT EDIT manually
// This file has edited by setConfig.sh file

// version : web version (from package.json)
// build time : time the build was performed
// build commit : Commit value of GIT

export default {
    version: '1.5.6',
    build_time: '2024-05-13T07:07:36',
    build_commit_hash: 'caab76ee94eac6879fb8882dc0ae3c8c6ac8beaf',
};
