import React from 'react';
import Policy from '../components/Policy/Policy';

function PolicyData({ history }: { history: any }) {
    const search = new URLSearchParams(history.location.search)
    const p_version = parseInt(search.get("p_version") || "0"); // Last version

    const p_type = parseInt(search.get("p_type") || "0");

    const chapter_num = parseInt(search.get("c_n") || "0");
    // const p_type = p_code > 2 ? 0: p_code;

    // NOTE : QueryString -> p_version, p_type

    // NOTE : 다국어 완전 지원 전까지 lng queryString으로 약관페이지만 다국어처리
    // const lng = search.get("lng") || "ko";
    return (
        <Policy p_version={p_version} p_type={p_type} chapter_num={chapter_num}/>
    )
}

export default PolicyData;
