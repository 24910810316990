import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import './ConfirmDlg.css'

function ConfirmDlg({ isShow, setIsShow }: { isShow: number, setIsShow: React.Dispatch<React.SetStateAction<number>> }) {
    const { t } = useTranslation();
    // console.log({isShow})
    const title_txt = isShow === 1 ? t('contact.dlg.success.title') : t('contact.dlg.fail.title');
    const title_content_txt = isShow === 1 ? (<Trans i18nKey="contact.dlg.success.title_content" components={[<br />, <b></b>]} />)
        : (<Trans i18nKey="contact.dlg.fail.title_content" components={[<br />, <span className="bold_blue lang_en_font"></span>, <a href="mailto:admin@algorigo.com" className="bold_blue lang_en_font"></a>]} />)
    return (
        <div className="confirm_dlg">
            <div className={`${isShow ? 'dimmed' : 'dimmed-none'}`}>
                <div className="dlg_flex">
                    <div className="dlg_box">
                        <div className="title">{title_txt}</div>
                        <div className="title_content">{title_content_txt}</div>
                        <button onClick={() => setIsShow(0)}>{t('contact.dlg.btn')}</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ConfirmDlg;
