import React from 'react';
import About from '../components/About/About';

function AboutData() {
    return (
        <About />
    )
}

export default AboutData;
