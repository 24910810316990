import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux';
import News from '../components/News/News'
import { RootState } from '../modules';
import { getNewDataThunk } from '../modules/news';

function NewsData() {
    const [nowPageNum, setNowPageNum] = useState(1);
    const [newsKeyword, setNewsKeyword] = useState('');
    const { loading, data, error } = useSelector((state: RootState) => state.news.newsList);
    const dispatch = useDispatch();

    const param: object = {
        'page': nowPageNum,
        'itemPerPage': 9,
        'newsId': '"news_id"',
        'desc': 'True',
        'keyword': newsKeyword
    }
    let newsData: any = '';
    if (data) {
        newsData = data
    }

    useEffect(() => {
        dispatch(getNewDataThunk(param));
    }, [nowPageNum, newsKeyword]);

    return (
        <>
            <News
                newsData={newsData}
                loading={loading}
                nowPageNum={nowPageNum}
                setNowPageNum={setNowPageNum}
                newsKeyword={newsKeyword}
                setNewsKeyword={setNewsKeyword}
            />
        </>
    )
}

export default NewsData
