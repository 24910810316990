import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import $ from 'jquery';

import top from '../images/icon-top@3x.png';

function ScrollToTop({ children, history }) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [history.location]);

    const scrollTopBtn = () => {
        try {
            // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
            // window.scroll({
            //     top: 0,
            //     left: 0,
            //     behavior: 'smooth',
            // });
            $('html, body').animate(
                {
                    scrollTop: 0,
                },
                1200,
            );
        } catch (error) {
            // just a fallback for older browsers
            window.scrollTo(0, 0);
        }
    };

    return (
        <>
            {children}
            {/* <img
                className={
                    'scroll_btn' +
                    (history.location.pathname === '/product'
                        ? ' on_product'
                        : '')
                }
                src={top}
                alt="button"
                onClick={scrollTopBtn}
            /> */}
        </>
    );
}

export default withRouter(ScrollToTop);
