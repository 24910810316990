import React from 'react';
// import { Trans, useTranslation } from 'react-i18next';
import { useScrollFadeIn } from '../../utils/useScrollEvent'
import { RecruitType } from '../../types/RecruitType'
import { openNewTabUrl } from '../../utils/commonUtils'

import './Recruit.css'

function RecruitCard(props: { r: RecruitType }) {
    const { r } = props;
    // const { t } = useTranslation();
    const animatedFadeInCard = useScrollFadeIn('up', 0.3, 0, 0.2);

    return (
        <div className="re_list" key={r.recruit_id} {...animatedFadeInCard}>
            <div className="re_title">{r.title}</div>
            <div className={"re_type" + (!r.be_scheduled && r.due_date ? " lang_en_font" : "")}>{
                r.be_scheduled ? (
                    '채용예정'
                ) : (r.due_date ? ('D' + r.remaining_days) : ('상시채용'))
            }</div>
            <ul className="re_keyword">
                {
                    r.keyword.map((k: string, index_k: number) => {
                        return (
                            <li key={index_k}>{k}</li>
                        )
                    })
                }
            </ul>
            <div className="re_apply">
                <button onClick={() => openNewTabUrl(r.apply_url || '')} disabled={r.be_scheduled || r.apply_url == null}>지원하기</button>
            </div>
        </div>
    )
}

export default RecruitCard;