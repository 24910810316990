import React from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import './components/common/default.css';

import Router from './Router';
import Version from './components/version'


function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/version" component={Version} />
        <Router />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
