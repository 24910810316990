// import { createStandardAction } from 'typesafe-actions';
import { createAsyncAction } from 'typesafe-actions';
import { AxiosError } from 'axios';
import { NewsType } from '../../types/NewsType'

export const GET_NEWS_DATA = 'GET_NEWS_DATA';
export const GET_NEWS_DATA_SUCCESS = 'GET_NEWS_DATA_SUCCESS';
export const GET_NEWS_DATA_ERROR = 'GET_NEWS_DATA_ERROR';

export const getNewsDataAsync = createAsyncAction(
    GET_NEWS_DATA,
    GET_NEWS_DATA_SUCCESS,
    GET_NEWS_DATA_ERROR
)<any, NewsType, AxiosError>();
