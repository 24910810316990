import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEn from './locales/en/translation.json'
import translationKr from './locales/ko/translation.json'

const resources = {
  en: {
    translation: translationEn
  },
  ko: {
    translation: translationKr
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      resources,
      // fallbackLng: 'ko',
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false
      },
      debug: process.env.NODE_ENV === 'development',
      load: 'languageOnly',
      detection: {
        order: ['querystring', 'localStorage', 'navigator']
        // ?lng=LANGUAGE
      },
      // supportedLngs: ['ko'],
      supportedLngs: ['ko', 'en'],
      react: {
        // defaultTransParent: 'div', // a valid react element - required before react 16
        transEmptyNodeValue: '', // what to return for empty Trans
        transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'b'], // don't convert to <1></1> if simple react elements
      }
    }
  )

export default i18n;