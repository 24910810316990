import React from 'react';
import { Route } from 'react-router';
import Contact from '../components/Contact/Contact';
import RecruitData from './RecruitData';

function ContactData({ match }: { match: any }) {
    const contactPage = match.params.page;
    return (
        <>
            {contactPage === 'recruit' ? <RecruitData /> :
                contactPage === 'inquire' ? <Contact /> :
                    <Contact />}
        </>
    )
}

export default ContactData;
