// import { createStandardAction } from 'typesafe-actions';
import { createAsyncAction } from 'typesafe-actions';
import { AxiosError } from 'axios';
import { RecruitType } from '../../types/RecruitType'

export const GET_RECRUITS = 'GET_RECRUITS';
export const GET_RECRUITS_SUCCESS = 'GET_RECRUITS_SUCCESS';
export const GET_RECRUITS_ERROR = 'GET_RECRUITS_ERROR';

export const getRecruitsAsync = createAsyncAction(
    GET_RECRUITS,
    GET_RECRUITS_SUCCESS,
    GET_RECRUITS_ERROR
)<any, RecruitType, AxiosError>();
