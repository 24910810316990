import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { TitleComponent } from '../TitleComponent'
import './Recruit.css'
import { RecruitType } from '../../types/RecruitType'
import RecruitCard from './RecruitCard'
import { Trans, useTranslation } from 'react-i18next';
import PictureWebpComponent from '../PictureWebpComponent'

// images
import recr_welfare_1_w_3x_png from '../../images/illustration-recruit-1-w@3x.png'
import recr_welfare_1_w_2x_png from '../../images/illustration-recruit-1-w@2x.png'
import recr_welfare_1_w_1x_png from '../../images/illustration-recruit-1-w.png'
import recr_welfare_2_w_3x_png from '../../images/illustration-recruit-2-w@3x.png'
import recr_welfare_2_w_2x_png from '../../images/illustration-recruit-2-w@2x.png'
import recr_welfare_2_w_1x_png from '../../images/illustration-recruit-2-w.png'
import recr_welfare_3_w_3x_png from '../../images/illustration-recruit-3-w@3x.png'
import recr_welfare_3_w_2x_png from '../../images/illustration-recruit-3-w@2x.png'
import recr_welfare_3_w_1x_png from '../../images/illustration-recruit-3-w.png'
import recr_welfare_4_w_3x_png from '../../images/illustration-recruit-4-w@3x.png'
import recr_welfare_4_w_2x_png from '../../images/illustration-recruit-4-w@2x.png'
import recr_welfare_4_w_1x_png from '../../images/illustration-recruit-4-w.png'
import recr_welfare_5_w_3x_png from '../../images/illustration-recruit-5-w@3x.png'
import recr_welfare_5_w_2x_png from '../../images/illustration-recruit-5-w@2x.png'
import recr_welfare_5_w_1x_png from '../../images/illustration-recruit-5-w.png'
import recr_welfare_6_w_3x_png from '../../images/illustration-recruit-6-w@3x.png'
import recr_welfare_6_w_2x_png from '../../images/illustration-recruit-6-w@2x.png'
import recr_welfare_6_w_1x_png from '../../images/illustration-recruit-6-w.png'


import { useScrollFadeIn } from '../../utils/useScrollEvent'

function Recruit(props: { recruitList: RecruitType[], isLoaded: boolean, error: Error }) {
    const { t } = useTranslation();
    const { recruitList, isLoaded, error } = props;
    const [isActiveRecruitSec, setIsActiveRecruitSec] = useState<boolean>(true); //'진행중 채용', '사내복지' Tab

    const animatedFadeInTitle1 = useScrollFadeIn('up', 0.5, 0);
    const animatedFadeInTitleContent1 = useScrollFadeIn('up', 0.8, 0.5);

    const animatedFadeInWelfareCard1 = useScrollFadeIn('up', 0.35, window.matchMedia('(min-width: 960px)').matches ? 0.0 : window.matchMedia('(min-width: 768px)').matches ? 0.1 : 0.2, 0.1);
    const animatedFadeInWelfareCard2 = useScrollFadeIn('up', 0.35, window.matchMedia('(min-width: 960px)').matches ? 0.15 : window.matchMedia('(min-width: 768px)').matches ? 0.2 : 0.2, 0.1);
    const animatedFadeInWelfareCard3 = useScrollFadeIn('up', 0.35, window.matchMedia('(min-width: 960px)').matches ? 0.3 : window.matchMedia('(min-width: 768px)').matches ? 0.3 : 0.2, 0.1);
    const animatedFadeInWelfareCard4 = useScrollFadeIn('up', 0.35, window.matchMedia('(min-width: 960px)').matches ? 0.0 : window.matchMedia('(min-width: 768px)').matches ? 0.4 : 0.2, 0.1);
    const animatedFadeInWelfareCard5 = useScrollFadeIn('up', 0.35, window.matchMedia('(min-width: 960px)').matches ? 0.15 : window.matchMedia('(min-width: 768px)').matches ? 0.5 : 0.2, 0.1);
    const animatedFadeInWelfareCard6 = useScrollFadeIn('up', 0.35, window.matchMedia('(min-width: 960px)').matches ? 0.3 : window.matchMedia('(min-width: 768px)').matches ? 0.6 : 0.2, 0.1);

    return (
        <section className="container rec_ctr">
            <TitleComponent title={t('header.nav.contact.recruit')} />
            <section className="content rec_top_1">
                <div className="inner">
                    <div className="rec_top_1_1 bg_wrap" {...animatedFadeInTitle1}>
                        <div className="bg--yellow7 light" > </div>
                        <h1 className="title">{t('recruit.title')}</h1>
                        <p className="title_content" {...animatedFadeInTitleContent1}>
                            <span>
                                <Trans i18nKey="recruit.title_content" components={[<br />]} />
                            </span>
                        </p>
                    </div>
                    <div className="rec_top_2">
                        <ul className="tab tab_btn tab_btn">
                            <li className={isActiveRecruitSec ? "active" : ""} onClick={() => setIsActiveRecruitSec(true)}>{t('recruit.category.inprogress')}</li>
                            <li className={isActiveRecruitSec ? "" : "active"} onClick={() => setIsActiveRecruitSec(false)}>{t('recruit.category.welfare')}</li>
                        </ul>
                        {/* tab1 (진행중 채용) */}
                        <div className={"rec_sec" + (isActiveRecruitSec ? "" : " no_display")}>
                            {isLoaded ?
                                <>
                                    <div className="title"><Trans i18nKey="recruit.getting.data1" /></div>
                                    <div className="title_content"><Trans i18nKey="recruit.getting.data2" /></div>
                                </>
                                : error ?
                                    <>
                                        <div className="title"><Trans i18nKey="recruit.get.fail1" /></div>
                                        <div className="title_content"><Trans i18nKey="recruit.get.fail2" /></div>
                                    </>
                                    : recruitList.length < 1 ? (
                                        <>
                                            <h5 className="title">{t('recruit.category.inprogress.no.title')}</h5>
                                            <div className="title_content"><Trans i18nKey="recruit.category.inprogress.no.title_content" components={[<br />]} /></div>
                                            <Link to="/contact/inquire">
                                                <button className="rec_home_btn">{t('recruit.category.inprogress.no.btn')}</button>
                                            </Link>
                                        </>
                                    ) : (
                                        // {/* 채용 공고가 있을 때 */}
                                        recruitList.map((r: RecruitType, idx: number) => {
                                            return (
                                                <RecruitCard r={r} key={r.recruit_id}></RecruitCard>
                                            );
                                        })
                                    )
                            }
                        </div>
                        {/* tab2 (사내 복지) */}
                        <div className={"welfare_sec" + (isActiveRecruitSec ? " no_display" : "")}>
                            <div className="rect_box" {...animatedFadeInWelfareCard1}>
                                <div className="welfare_img">
                                    <PictureWebpComponent fb_img_1x={recr_welfare_1_w_1x_png} fb_img_2x={recr_welfare_1_w_2x_png} fb_img_3x={recr_welfare_1_w_3x_png} fb_alt="welfare1" alt_img_name="illustration-recruit-1"></PictureWebpComponent>
                                    {/* <img src={recr_welfare_1} alt="welfare"></img> */}
                                </div>
                                <div className="welfare_content">
                                    <Trans i18nKey="recruit.category.welfare.card1" components={[<br />, <br className="u_desk" />]} />
                                </div>
                            </div>
                            <div className="rect_box" {...animatedFadeInWelfareCard2}>
                                <div className="welfare_img">
                                    <PictureWebpComponent fb_img_1x={recr_welfare_2_w_1x_png} fb_img_2x={recr_welfare_2_w_2x_png} fb_img_3x={recr_welfare_2_w_3x_png} fb_alt="welfare2" alt_img_name="illustration-recruit-2"></PictureWebpComponent>
                                    {/* <img src={recr_welfare_2} alt="welfare"></img> */}
                                </div>
                                <div className="welfare_content">
                                    <Trans i18nKey="recruit.category.welfare.card2" components={[<br />, <br className="u_desk" />]} />
                                </div>
                            </div>
                            <div className="rect_box" {...animatedFadeInWelfareCard3}>
                                <div className="welfare_img">
                                    <PictureWebpComponent fb_img_1x={recr_welfare_3_w_1x_png} fb_img_2x={recr_welfare_3_w_2x_png} fb_img_3x={recr_welfare_3_w_3x_png} fb_alt="welfare3" alt_img_name="illustration-recruit-3"></PictureWebpComponent>
                                    {/* <img src={recr_welfare_3} alt="welfare"></img> */}
                                </div>
                                <div className="welfare_content">
                                    <Trans i18nKey="recruit.category.welfare.card3" components={[<br />, <br className="u_desk" />]} />
                                </div>
                            </div>
                            <div className="rect_box" {...animatedFadeInWelfareCard4}>
                                <div className="welfare_img">
                                    <PictureWebpComponent fb_img_1x={recr_welfare_4_w_1x_png} fb_img_2x={recr_welfare_4_w_2x_png} fb_img_3x={recr_welfare_4_w_3x_png} fb_alt="welfare4" alt_img_name="illustration-recruit-4"></PictureWebpComponent>
                                    {/* <img src={recr_welfare_4} alt="welfare"></img> */}
                                </div>
                                <div className="welfare_content">
                                    <Trans i18nKey="recruit.category.welfare.card4" components={[<br />, <br className="u_desk" />]} />
                                </div>
                            </div>
                            <div className="rect_box" {...animatedFadeInWelfareCard5}>
                                <div className="welfare_img">
                                    <PictureWebpComponent fb_img_1x={recr_welfare_5_w_1x_png} fb_img_2x={recr_welfare_5_w_2x_png} fb_img_3x={recr_welfare_5_w_3x_png} fb_alt="welfare5" alt_img_name="illustration-recruit-5"></PictureWebpComponent>
                                    {/* <img src={recr_welfare_5} alt="welfare"></img> */}
                                </div>
                                <div className="welfare_content">
                                    <Trans i18nKey="recruit.category.welfare.card5" components={[<br />, <br className="u_desk" />]} />
                                </div>
                            </div>
                            <div className="rect_box" {...animatedFadeInWelfareCard6}>
                                <div className="welfare_img">
                                    <PictureWebpComponent fb_img_1x={recr_welfare_6_w_1x_png} fb_img_2x={recr_welfare_6_w_2x_png} fb_img_3x={recr_welfare_6_w_3x_png} fb_alt="welfare6" alt_img_name="illustration-recruit-6"></PictureWebpComponent>
                                    {/* <img src={recr_welfare_6} alt="welfare"></img> */}
                                </div>
                                <div className="welfare_content">
                                    <Trans i18nKey="recruit.category.welfare.card6" components={[<br />, <br className="u_desk" />]} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    )
}

export default Recruit
