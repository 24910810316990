import React from 'react';

import './Policy.css'

// NOTE : 약관의 버전을 추후 rendering된 화면서 확인할 수 있도록 부모 div에 'data-p-version'을 추가해줄 것.

export default function TermsOfUse({p_version, lang, numChapter}: {p_version:number, lang:string, numChapter:number}) {
    if (lang === 'ko') {
        switch (p_version) {
            case 0:
            // case 2:
            //     return ()
            case 1:
                return (
                    <div data-p-version={1}>
                        <h1 className="pol_tit">제 1장 총칙</h1>
                        <h2 className="pol_sub_tit">제 1 조 (목적)</h2>
                        <p className="title_content">이 약관은 ㈜알고리고(이하 ‘회사’라 합니다)가 자체 또는 협력사를 통해 공급하는 ‘앉는 습관 모니터링 서비스’(이하 ‘서비스’라 합니다. Ex.알고헬스/알고스터디/알고워크 등)를 이용고객(이하 ‘고객’이라 합니다)에게 제공함에 있어, 회사와 이용 고객간의 권리, 의무 및 책임사항에 따른 이용조건 및 절차에 관한 사항을 정함을 목적으로 합니다.</p>
                        <h2 className="pol_sub_tit">제 2 조 (용어의 정의)</h2>
                        <p className="title_content">이 약관은 ㈜알고리고(이하 ‘회사’라 합니다)가 자체 또는 협력사를 통해 공급하는 ‘앉는 습관 모니터링 서비스’(이하 ‘서비스’라 합니다. Ex.알고헬스/알고스터디/알고워크 등)를 이용고객(이하 ‘고객’이라 합니다)에게 제공함에 있어, 회사와 이용 고객간의 권리, 의무 및 책임사항에 따른 이용조건 및 절차에 관한 사항을 정함을 목적으로 합니다.</p>
                        <ul className="pol_unord_ul">
                            <li>
                                <span>&middot;</span>이 약관에서 사용하는 용어의 정의는 다음 각 호와 같습니다.
                                <ul className="pol_ord_ul">
                                    <li>
                                        1. 앉는 습관 모니터링 서비스(이하 ‘서비스’라 합니다) : 고객이 스마트폰 어플리케이션을 통해, 스마트체어(방석 포함)에 대한 등록, 제어, 모니터링 및 관리 등을 하고 다양한 정보를 제공받을 수 있도록, 회사가 고객에게 어플리케이션 또는 웹의 형태로 제공하는 특정 서비스 및 기타 부가 서비스(특별히 구분하지 않는 경우 어플리케이션과 웹 페이지를 포함) <br />
                                        2. 어플리케이션: 스마트폰에 설치하여 서비스를 이용할 수 있도록 구성된 프로그램<br />
                                        3. 제휴 협력사(이하 ‘협력사’라 합니다): 회사와 협력 계약을 체결한 스마트체어 및 방석 등의 기기 관련 사업자<br />
                                        4. 스마트체어 또는 스마트방석: 협력사를 통해 제조, 판매, 유통하는 스마트 기능(원격 통신기능 등 서비스를 이용할 수 있도록 하는 기능을 말합니다)이 탑재된 제품을 말하며, 통신모듈 등 “앉는 습관 모니터링 서비스”를 위해 “협력사”가 해당 제품에 탑재하는 하드웨어 및 펌웨어를 포함<br />
                                        5. 아이디 : 서비스 이용을 위하여 고객이 선정하고 회사가 승인하는 이메일을 말하며, 제공하는 ‘앉는 습관 모니터링 서비스’ 이용에 사용되는 아이디(ID)<br />
                                        6. 비밀번호 : 아이디(ID, 이메일)를 통한 서비스 이용 및 회원의 정보 보호를 위해 회원 자신이 설정한 문자, 숫자 등의 조합<br />
                                        7. 콘텐츠 : 서비스를 이용하는 과정에서 유통되는 통계, 문서, 사진, 동영상 등 기타 일체의 정보<br />
                                    </li>
                                </ul>
                            </li>
                            <li><span>&middot;</span>이 약관에서 사용하는 용어의 정의는 본 조에서 정하는 것을 제외하고는 관련 법령 및 서비스별 안내에서 정하는 바에 의합니다.</li>
                        </ul>
                        <h2 className="pol_sub_tit">제 3 조 (약관의 효력 및 변경)</h2>
                        <ul className="pol_unord_ul">
                            <li><span>&middot;</span>회사는 이 약관의 내용을 고객이 쉽게 알 수 있도록 어플리케이션과 홈페이지(www.algorigo.com)에 게시합니다.</li>
                            <li><span>&middot;</span>회사는 약관의 규제에 관한 법률, 정보통신망이용촉진 및 정보보호 등에 관한 법률, 전자상거래 등에서의 소비자보호에 관한 법률 등 관련법령을 위반하지 않는 범위에서 이 약관을 개정할 수 있습니다.</li>
                            <li><span>&middot;</span>회사가 약관을 개정할 경우에는 기존 약관과 개정 약관 및 개정 약관의 시행일자 및 개정사유, 변경되는 내용을 명시하여 현행 약관과 함께 제1항의 방식에 따라 그 적용일자 15일 전부터 적용일자 전일까지 공지합니다. 다만, 고객에게 불리하거나 중대한 사항의 변경은 30일 전에 공지하고 회원에게 개별 통지합니다(고객의 연락처 미기재, 변경 등으로 인하여 개별 통지가 어려운 때에는 홈페이지에 변경 약관을 공지함으로써 개별 통지한 것으로 간주합니다).</li>
                            <li><span>&middot;</span>제3항에 따라, 회사가 개정약관을 공지 또는 통지하면서 고객에게 변경된 이용약관의 효력 발생일까지 약관 변경에 관한 거부의 의사표시를 하지 않으면 고객이 개정 약관에 동의할 것으로 본다는 뜻을 명확하게 따로 공지 또는 고지하였음에도 고객이 명시적인 반대의사를 표시하지 아니한 경우에는 개정 약관에 동의한 것으로 보고, 제3항에서 정한 공지기간이 종료됨과 동시에 효력이 발생합니다.</li>
                            <li><span>&middot;</span>고객은 변경된 약관에 동의하지 않을 권리가 있으며, 서비스 이용을 중단하고 서비스 탈퇴를 요청할 수 있습니다. 변경된 약관의 효력 발생일 이후 고객의 계속적인 서비스 이용은 이 약관의 변경사항에 동의한 것으로 봅니다.</li>
                        </ul>
                        <h2 className="pol_sub_tit">제 4 조 (약관외 준칙)</h2>
                        <p className="title_content">서비스 이용에 관하여는 이 약관을 적용하고, 이 약관에 명시되지 아니한 사항에 대하여는 관련법령 또는 상관례에 따라 적용합니다.</p>
                        <h1 className="pol_tit">제 2 장 계약 체결</h1>
                        <h2 className="pol_sub_tit">제 5 조 (이용계약의 성립)</h2>
                        <ul className="pol_unord_ul">
                            <li><span>&middot;</span>서비스 이용 계약은, 서비스를 이용하려는 고객이 어플리케이션 등에서 이 약관에 동의한 다음 서비스 ID 및 비밀번호를 설정함으로써 이용 신청을 하고 회사가 이를 승낙하는 방법으로 성립됩니다.</li>
                            <li><span>&middot;</span>회사는 고객의 개인정보를 수집<span>&middot;</span>이용함에 있어 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 관련 법령 및 회사의 개인정보 처리방침을 준수하고 있으며, 고객의 서비스 가입시 회사는 고객의 개인정보에 대한 수집, 이용 등에 대한 동의를 별도로 받습니다.</li>
                            <li><span>&middot;</span>회사는 고객이 유료 서비스를 이용할 경우, 이용 전에, 다음 각 호의 사항에 관하여 고객이 정확하게 이해할 수 있도록 정보를 제공합니다.
                                <ul className="pol_ord_ul">
                                    <li>
                                        1. 유료 서비스 확인 및 선택 또는 유료 고객으로의 전환<br />
                                        2. 결제방법의 선택 및 결제정보의 입력<br />
                                        3. 유료 서비스의 이용신청에 관한 확인 또는 회사의 확인에 대한 동의<br />
                                    </li>
                                </ul>
                            </li>
                            <li><span>&middot;</span>고객이 유료 서비스를 이용하기 위해서는 이 약관에 동의한 후 각 서비스 이용조건에 따라 이용요금을 지불하여야 합니다.</li>
                        </ul>
                        <h2 className="pol_sub_tit">제 6 조 (이용신청방법 등)</h2>
                        <ul className="pol_unord_ul">
                            <li><span>&middot;</span>고객은 서비스 이용 신청 정보를 정확히 기재 또는 입력하여야 하며, 기재 또는 입력된 고객의 개인 정보는 고객의 실제 정보인 것으로 간주되고, 실제 정보를 입력하지 않은 고객은 법적인 보호를 받을 수 없으며 서비스 이용에 제한을 받을 수 있습니다.</li>
                            <li><span>&middot;</span>민법상 미성년자인 회원이 유료 서비스를 이용할 경우 미성년자인 회원은 결제 전 법정대리인의 동의를 얻어야만 할 수 있으며, 각 서비스 상품별로 미성년자의 가입이 제한될 수 있습니다.</li>
                        </ul>
                        <h2 className="pol_sub_tit">제 7 조 (이용신청에 대한 승낙의 제한)</h2>
                        <p className="title_content">회사는 다음 각 호의 하나에 해당하는 서비스 이용신청에 대하여는 승낙을 하지 아니합니다.</p>
                        <ul className="pol_ord_ul">
                            <li>
                                1. 타인 명의로 신청한 경우<br />
                                2.제출정보 또는 서류의 내용이 허위인 경우<br />
                                3.법률 위반 또는 사회의 안녕과 질서 혹은 미풍양속을 저해할 목적으로 이용신청한 경우<br />
                                4.부정한 용도로 서비스를 이용하고자 하는 것으로 볼 수 있는 객관적인 사유가 있는 경우<br />
                                5.영리를 추구할 목적으로 이용신청한 경우<br />
                                6.법률 또는 약관을 위반하여 이용계약이 해지된 적이 있는 고객이 이용신청한 경우<br />
                                7.회사가 제공하는 이동전화 서비스 가입을 전제로 한 서비스 상품에 있어서, 회사의 이동전화 서비스를 가입하지 않은 경우 및 해당 이동전화 회선 명의와 동일명의가 아닌 경우<br />
                                8.기타 이 약관상 제반 규정을 위반하거나 회사가 정한 이용신청 요건이 미비한 경우<br />
                            </li>
                        </ul>
                        <h1 className="pol_tit">제 3 장 서비스 이용</h1>
                        <h2 className="pol_sub_tit">제 8 조 (서비스의 이용개시)</h2>
                        <ul className="pol_unord_ul">
                            <li><span>&middot;</span>회사가 고객의 이용신청을 승낙한 때부터 서비스 이용이 가능합니다. 다만, 일부 서비스의 경우에는 지정된 일자부터 서비스를 개시할 수도 있으며, 유료서비스의 경우 회사가 요금납입을 확인한 직후부터 가능하게 할 수 있습니다.</li>
                            <li><span>&middot;</span>회사의 업무상 또는 기술상의 장애로 인하여 서비스를 개시하지 못하는 경우에는 어플리케이션 또는 홈페이지에 공지하거나 고객에게 이를 통지합니다.</li>
                            <li><span>&middot;</span>회사는 고객에게 어플리케이션을 제공하며, 고객은 회사가 지정한 서비스 이용가능 스마트폰에 어플리케이션을 다운로드 및 설치하여 서비스를 이용할 수 있습니다. 단, 서비스 이용가능 스마트폰의 종류, 스마트폰 OS의 종류 및 버전에 따라 어플리케이션의 구성과 기능에는 차이가 있을 수 있습니다.</li>
                        </ul>
                        <h2 className="pol_sub_tit">제 9 조 (서비스 이용)</h2>
                        <ul className="pol_unord_ul">
                            <li><span>&middot;</span>고객은 스마트폰 제조사 및 통신사에서 제공하는 어플리케이션 스토어 또는 서비스내 다운로드 페이지를 통하여 어플리케이션을 설치하여 서비스를 이용할 수 있습니다.</li>
                            <li><span>&middot;</span>서비스는 회사의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴 1일 24시간 제공됨을 원칙으로 합니다. 단, 제10조(서비스 제한 및 중단) 항목에 해당하는 경우, 서비스의 전부 또는 일부가 제한되거나 중단될 수 있습니다.</li>
                            <li><span>&middot;</span>고객에 대한 보다 나은 서비스 제공을 위하여 위치정보이용에 동의한 고객에 한하여 스마트폰을 통해 수집된 위치정보를 활용한 서비스를 제공할 수 있습니다.</li>
                            <li><span>&middot;</span>제4항의 위치기반서비스는 위치정보사업자의 위치정보를 제공받는 관계로, 위치정보사업자의 사정에 따른 위치정보의 오차∙오류 등이 발생할 수 있으며, 서비스 제공 당시 기술 수준에 비추어 통상 생길 수 있는 오차 또는 오류 등의 경우 회사는 그에 관한 책임을 지지 않습니다.</li>
                            <li><span>&middot;</span>회사는 필요한 경우 서비스의 내용을 추가 또는 변경할 수 있습니다. 단, 이러한 경우에는 그 내용을 고객에게 사전에 공지합니다.</li>
                        </ul>
                        <h2 className="pol_sub_tit">제 10 조 (서비스 제한 및 중단)</h2>
                        <ul className="pol_unord_ul">
                            <li><span>&middot;</span>회사는 다음 각 호의 어느 하나에 해당하는 경우 서비스의 전부 또는 일부를 제한하거나 중단할 수 있습니다.
                                <ul className="pol_ord_ul">
                                    <li>
                                        1. 시스템 점검, 증설, 유지보수 및 교체 등 <br />
                                        2. 정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우<br />
                                        3. 위치정보사업자 또는 제휴사와의 계약 종료 등과 같은 회사의 사정으로 서비스를 유지할 수 없는 경우<br />
                                        4. 사업성 악화 등 회사의 경영상의 판단으로 서비스 제공을 중단하는 경우<br />
                                        5. 고객이 회사의 영업활동을 방해하는 경우<br />
                                        6. 전기통신사업법에 의한 기간통신사업자가 전기통신서비스를 중지하는 등 기타 부득이한 사유가 있는 경우<br />
                                        7. 천재지변, 국가 비상사태 등 불가항력적인 사유가 발생하거나 발생할 우려가 있는 경우<br />
                                    </li>
                                </ul>
                            </li>
                            <li><span>&middot;</span>회사가 제1항의 규정에 의하여 서비스의 전부 또는 일부를 제한하거나 중단한 경우에는, 회사는 중단사유, 일시 등을 제3조 제1항의 방법을 통해 고객에게 사전 공지합니다. 단, 회사가 통제할 수 없는 사유로 인한 서비스 제한 또는 중단으로 사전 공지가 불가능한 경우에는 해당 사유 해소 후 사후 공지합니다.</li>
                        </ul>
                        <h2 className="pol_sub_tit">제 11 조 (게시물의 관리)</h2>
                        <p className="title_content">회사는 고객이 게시하는 서비스 내의 정보∙자료 등을 포함한 일체의 게시물(고객간 전달 포함)이 다음 각 호의 어느 하나에 해당하는 경우, 이를 삭제하고 해당 고객에 대한 서비스 제공을 제한 또는 중단할 수 있으며, 이에 대해 회사는 어떠한 책임도 지지 않습니다.</p>
                        <ul className="pol_ord_ul">
                            <li>
                                1. 회사 또는 제3자를 비방, 모욕하거나 명예를 훼손시키는 내용인 경우<br />
                                2. 법령 또는 미풍양속에 위반되는 내용의 정보, 문장 등을 유포하거나 링크시키는 경우<br />
                                3. 회사 또는 제3자의 저작권 등을 포함한 일체의 권리를 침해하는 내용인 경우<br />
                                4. 영리를 목적으로 하는 광고 또는 스팸성 광고를 전송하는 경우<br />
                                5. 범죄와 결부된다고 객관적으로 인정되는 내용일 경우<br />
                                6. 회사가 서비스 성격에 부합하지 않는다고 판단하는 경우(예. 정치적 판단이나 종교적 견해 등의 내용)<br />
                                7. 기타 관련 법령에 위반된다고 판단되는 경우<br />
                            </li>
                        </ul>
                        <h2 className="pol_sub_tit">제 12 조 (권리의 귀속)</h2>
                        <ul className="pol_unord_ul">
                            <li><span>&middot;</span>서비스 및 회사가 서비스 내에 게시한 정보∙자료 등에 대한 저작권을 포함한 일체의 지식재산권 및 소유권 또는 사용권은 회사가 보유합니다.</li>
                            <li><span>&middot;</span>고객이 서비스 내에 게시한 정보∙자료 등에 대한 저작권을 포함한 일체의 지식재산권 및 소유권은 고객이 보유하되, 회사는 서비스 내에서 이를 임의의 방법으로 이용할 수 있습니다. 다만, 서비스와 무관하게 상업적 목적으로 게시물을 이용할 경우에는 사전에 회원의 동의를 얻고 진행을 합니다.</li>
                            <li><span>&middot;</span>서비스와 관련된 모든 상표, 서비스 기호, 로고, CI 등에 관한 일체의 지식재산권 및 소유권 또는 사용권은 회사가 보유합니다.</li>
                            <li><span>&middot;</span>고객은 서비스를 이용함으로써 얻은 정보∙자료 등을 회사의 사전 승낙 없이 복제, 공중송신, 출판, 배포 기타 방법에 의하여 영리 목적으로 이용하거나 제3자에게 제공하여서는 안됩니다.</li>
                        </ul>
                        <h2 className="pol_sub_tit">제 13 조 (제휴사 제품 및 관련 서비스)</h2>
                        <ul className="pol_unord_ul">
                            <li><span>&middot;</span>서비스와 관련하여 제조사∙제휴사가 제공하는 기기, 관련 서비스 또는 콘텐츠가 서비스 내용에 포함되는 경우가 있습니다. 이러한 스마트 기기, 관련 서비스 또는 콘텐츠에 대해서는 이를 제공하는 제조사∙제휴사가 정한 이용약관 등을 포함한 이용 조건이 적용됩니다.</li>
                            <li><span>&middot;</span>제1항에 따라 제조사∙제휴사가 제공하는 스마트 기기, 관련 서비스 또는 콘텐츠에 대한 제공 의무, 보증, A/S 등과 관련된 책임은 이를 제공하는 제조사∙제휴사에게 있습니다.</li>
                        </ul>
                        <h1 className="pol_tit">제 4 장 계약당사자의 의무</h1>
                        <h2 className="pol_sub_tit">제 14 조 (회사의 의무)</h2>
                        <ul className="pol_unord_ul">
                            <li><span>&middot;</span>회사는 이 약관 및 관련 법령에서 정한 바에 따라 안정적인 서비스를 제공하기 위해 노력하고 유지, 점검 또는 복구 등의 조치를 성실히 이행하여야 합니다.</li>
                            <li><span>&middot;</span>회사는 서비스와 관련한 고객으로부터 소정의 절차에 의해 제기되는 의견이나 불만이 정당하다고 인정되는 경우에는 적절한 절차를 거쳐 처리하며, 고객이 제기한 의견이나 불만사항에 대하여 공지사항을 활용하거나 전화, SMS, 기타 전자적 통신 수단 등을 통하여 고객에게 처리 과정 및 일정, 결과를 통지합니다.</li>
                            <li><span>&middot;</span>회사는 고객의 개인정보 보호와 관련하여 관련 법령에서 정한 절차와 방법을 준수하고, 개인정보처리방침을 공지하고 준수하며 고객의 개인정보를 처리함에 있어 안정성 확보 및 기술적 대책을 수립 운영합니다.</li>
                            <li><span>&middot;</span>회사는 서비스 제공과 관련하여 고객의 정보를 고객 본인의 동의 없이 수집, 이용하거나 제3자에게 제공하지 않습니다. 단, 고객으로부터 동의를 얻거나 관련 법령에서 정한 경우는 제외합니다.</li>
                            <li><span>&middot;</span>회사는 서비스 제공목적에 맞는 서비스 이용여부를 확인하기 위하여 상시적으로 모니터링을 실시할 수 있습니다.</li>
                        </ul>
                        <h2 className="pol_sub_tit">제 15 조 (고객의 의무)</h2>
                        <ul className="pol_unord_ul">
                            <li><span>&middot;</span>고객은 이 약관 및 관련 법령에서 규정한 사항을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 해서는 안됩니다.</li>
                            <li><span>&middot;</span>고객은 회사의 서비스 제공목적 외의 용도로 서비스를 이용하거나 임의로 제3자에게 해당서비스를 임대하여서는 안 됩니다.</li>
                            <li><span>&middot;</span>고객은 고객 개인정보 관리의 책임을 부담하며, 고객의 귀책사유로 인한 정보 유출로 인한 손해에 대하여 회사는 책임을 지지 않습니다.</li>
                            <li><span>&middot;</span>고객은 서비스 이용에 필요한 개인신상정보 등을 회사에 허위로 제공하여서는 안되며, 정보변경 시 지체 없이 회사에 통보하여 갱신하여야 합니다.</li>
                            <li><span>&middot;</span>고객은 서비스 제공 또는 이 약관에 따른 회사의 업무 수행에 사용되는 보안 기술 또는 소프트웨어 등을 회피하거나, 변경 시도 또는 부정 사용하거나, 제3자가 그러한 행위를 하도록 교사∙방조하는 행위 등을 하여서는 안 됩니다. 고객이 이와 같은 행위를 한 경우 그와 관련된 모든 책임은 고객 본인에게 있습니다.</li>
                            <li><span>&middot;</span>고객은 서비스를 이용할 때 다음 각 호의 어느 하나에 해당하는 행위를 하여서는 안 됩니다.
                                <ul className="pol_ord_ul">
                                    <li>
                                        1. 이용 신청 또는 변경 시 허위 사실을 기재하거나, 다른 고객의 ID 및 비밀번호를 도용, 부정하게 사용하는 행위<br />
                                        2. 다른 고객 또는 제3자의 개인정보 또는 결제정보 등을 도용하는 행위<br />
                                        3. 서비스를 복제, 모방하거나 기타 변형하는 행위<br />
                                        4. 회사가 서비스에 게시한 정보를 복제, 모방하거나 기타 변형하는 행위<br />
                                        5. 서비스를 이용함으로써 얻은 정보 또는 자료 등을 회사의 사전 승낙 없이 복제, 공중송신, 출판, 배포 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하는 행위<br />
                                        6. 회사 또는 제3자를 비방, 모욕하거나 명예를 훼손시키는 행위<br />
                                        7. 회사 또는 제3자의 저작권 등을 포함한 일체의 권리를 침해하는 행위<br />
                                        8. 법령 또는 미풍양속에 위반되는 정보 등을 서비스에 게시 또는 유포하는 행위<br />
                                        9. 자동 접속프로그램을 이용하는 등 정상적인 이용과 다른 방법으로 서비스를 이용하여 회사의 정상적인 서비스 제공을 방해하는 일체의 행위<br />
                                        10. 서비스 장애, 정보 등의 파괴 및 혼란을 유발시키는 악성 프로그램∙바이러스 및 그러한 내용이 포함된 자료 등을 게시 또는 유포하는 행위<br />
                                        11. 서비스의 안정적 운영을 방해할 수 있는 정보 및 광고성 정보를 전송하는 행위 또는 불법 스팸을 전송하는 행위<br />
                                        12. 다른 고객의 개인정보(계정 포함)를 수집, 저장, 공개하거나, 다른 고객이 서비스에 게시한 정보를 임의로 변형하는 행위<br />
                                        13. 본인 아닌 제3자에게 ID를 양도∙대여하거나 이용하게 하는 행위 및 기타 제3자에게 서비스 접속권한을 부여, 배포하거나 서비스를 이용하게 하는 행위<br />
                                        14. 기타 관련 법령에서 금지하거나 미풍양속 또는 기타 사회통념상 허용되지 않는 행위<br />
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <h1 className="pol_tit">제 5 장 계약사항 해지 및 이용제한</h1>
                        <h2 className="pol_sub_tit">제 16 조 (계약 변경 및 해지)</h2>
                        <ul className="pol_unord_ul">
                            <li><span>&middot;</span>고객은 다음 각 호의 하나에 해당하는 이용계약 사항을 변경하고자 할 경우에는 회사가 정한 방법으로 신청할 수 있습니다.
                                <ul className="pol_ord_ul">
                                    <li>
                                        1. 서비스 종류를 변경하고자 하는 경우<br />
                                        2. 기타 변경이 필요한 경우<br />
                                    </li>
                                </ul>
                            </li>
                            <li><span>&middot;</span>회사는 고객이 제15조 등 이 약관에 규정된 고객의 의무에 위반되는 행위를 하였을 경우, 일방적으로 서비스 이용계약을 해지할 수 있고, 이로 인하여 서비스 운영에 손해가 발생한 경우 이에 대한 민,형사상 책임도 물을 수 있습니다.</li>
                            <li><span>&middot;</span>회사가 제3항에 따라 이용계약을 해지하고자 하는 때에는 해당 고객에게 해지일로부터 5일전까지 그 사유 등을 통보하며, 해지사유 통보 시 고객에게 이의신청 기회를 줍니다. 단, 회사의 책임 없는 사유로 통지할 수 없는 경우에는 홈페이지 게시를 통해 통지한 것으로 간주합니다.</li>
                            <li><span>&middot;</span>유료서비스 이용계약의 해지는 고객의 서비스 해지신청 및 회사의 승낙에 의해 성립합니다. 회사가 승낙한 시점에 해지의 효과가 발생하며 환불할 금액이 있는 경우 환불도 이루어지게 됩니다.</li>
                        </ul>
                        <h2 className="pol_sub_tit">제 17 조 (서비스 이용중단)</h2>
                        <ul className="pol_unord_ul">
                            <li>
                                <span>&middot;</span>회사는 고객이 다음 각 호의 하나에 해당하는 경우에는 즉시 서비스의 전부 또는 일부를 제한하거나 중단할 수 있습니다.
                                <ul className="pol_ord_ul">
                                    <li>
                                        1. 제15조 등 이 약관에 규정된 고객의 의무를 위반하는 경우<br />
                                        2. 타 관련 법령 등을 위반하는 행위를 한 경우<br />
                                    </li>
                                </ul>
                            </li>
                            <li><span>&middot;</span>회사가 제1항에 따라 서비스 이용제한 또는 중단을 하고자 하는 때에는 그 사유, 일시 및 기간을 정하여 본 서비스를 이용하여 해당 고객에게 통지합니다. 단, 회사가 긴급하게 이용을 제한 또는 중단해야 할 필요가 있다고 인정되는 경우에는, 서비스 이용을 제한 또는 중단한 후 통지할 수 있으며, 회사의 책임 없는 사유로 통지할 수 없는 경우에는 홈페이지 게시로 통지한 것으로 간주합니다.</li>
                            <li><span>&middot;</span>제2항에 따라 서비스 이용제한 또는 중단 통지를 받은 고객은, 그에 대하여 이의가 있을 시에는 회사가 정한 절차에 따라 이의를 제기할 수 있습니다.</li>
                            <li><span>&middot;</span>회사는 서비스 이용제한 또는 중단 기간 중에 그 사유가 해소된 것이 확인된 경우나 고객의 이의 신청에 이유가 있는 경우 등에 한하여, 즉시 서비스의 이용을 가능하게 합니다.</li>
                            <li><span>&middot;</span>고객이 서비스 이용요금을 미납한 경우, 회원자격은 유지되나 유료 서비스는 이용할 수 없고 요금 납부 즉시 서비스는 다시 이용 가능합니다.</li>
                        </ul>
                        <h1 className="pol_tit">제 6 장 서비스 이용료 등</h1>
                        <h2 className="pol_sub_tit">제 18 조 (서비스 이용 방법 등)</h2>
                        <p className="title_content">고객은 서비스를 이용하기 위하여 시설 내에 무선 인터넷 서비스(제공 사업자와 무관함) 및 WiFi 관련 설비가 필요하며, 회사는 고객의 무선 인터넷 서비스 환경에 대해서는 아무런 책임을 부담하지 않습니다.</p>
                        <h2 className="pol_sub_tit">제 19 조 (서비스 이용료 등)</h2>
                        <ul className="pol_unord_ul">
                            <li><span>&middot;</span>회사가 제공하는 유료서비스에 대한 이용요금의 결제 방법은 무통장 입금, 계좌이체, 핸드폰결제, 신용카드결제 등이 있으며 각 유료서비스마다 결제 방법의 차이가 있을 수 있습니다. 다만, 회사 내부 정책에 따라 일정 기간 무료 서비스를 제공한 후 유료 서비스로 전환될 수 있으며, 프로모션∙이벤트 등을 통하여 일정기간 동안 기존의 서비스 이용료와 다르게 적용될 수 있습니다.</li>
                            <li><span>&middot;</span>서비스 이용료는 서비스를 이용하기 위하여 회사에 납부하는 비용으로서 스마트기기(스마트체어 및 스마트방석) 비용과는 별도로 부과됩니다.</li>
                            <li><span>&middot;</span>다음 각 호와 같은 사유에 의한 약정 기간 내 고객의 서비스 해지의 경우에는 할인 반환금이 면제됩니다.
                                <ul className="pol_ord_ul">
                                    <li>
                                        1. 고객의 사망, 이민<br />
                                        2. 서비스 가입일로부터 7일 이내의 서비스 해지<br />
                                        3. 기타 회사의 귀책 사유에 의한 서비스 이용 불가능으로 인한 해지(단, 고객 가정의 무선 인터넷 환경 불비 등 이 약관상 회사의 의무와 직접 관련 없는 사유는 제외됨)<br />
                                    </li>
                                </ul>
                            </li>
                            <li><span>&middot;</span>회사는 결제의 이행을 위하여 반드시 필요한 회원의 개인정보를 추가적으로 요구할 수 있으며, 고객은 회사가 요구하는 개인정보를 정확하게 제공하여야 합니다. 회사는 고객이 허위로 또는 부정확하게 제공한 개인정보로 인하여 고객에게 발생한 손해에 대하여 회사의 고의.과실이 없는 한 고객의 손해를 배상할 책임을 부담하지 않습니다.</li>
                        </ul>
                        <h2 className="pol_sub_tit">제 20 조 (서비스 이용료 환불 등)</h2>
                        <ul className="pol_unord_ul">
                            <li><span>&middot;</span>회사는 서비스 이용료 등의 과납 또는 오납이 있을 때에는 그 과납 또는 오납된 요금을 반환하고, 회사의 귀책 사유로 발생한 경우에는 법정 이율을 부가하여 반환합니다. 다만, 고객이 동의하거나 회사의 반환 통지에 대하여 응하지 아니하는 경우에는 새로이 발생하는 서비스 이용료 등에서 해당 금액과 납부 마감일까지의 법정 이율을 차감하여 청구할 수 있습니다.</li>
                            <li><span>&middot;</span>회사가 제공하는 유료서비스가 결제 후 1회의 이용만으로 서비스의 이용이나 구매가 완료되는 서비스의 경우 해당 서비스를 이용한 후에는 환불이 불가합니다.</li>
                            <li>
                                <span>&middot;</span>고객이 서비스 이용료를 미리 납부하는 경우(1개월 사용)에 있어서, 서비스 이용료의 환불과 관련된 사항은 다음 각 호와 같습니다.
                                <ul className="pol_ord_ul">
                                    <li>
                                        1. 결제한 날로부터 7일 이내 서비스 해지시, 납부 금액에서 이용 일수에 해당하는 금액만 공제하고 환불합니다.<br />
                                        2. 결제한 날로부터 7일 경과 후 서비스 해지시, 납부 금액에서 이용 일수에 해당하는 금액과 잔여 기간 이용료의 10%에 해당하는 금액을 합한 금액을 공제한 나머지 금액을 환불합니다.<br />
                                        3. 무료 이용권(쿠폰 등)으로 결제한 고객에 대해서는 별도 환불 금액이 없습니다.<br />
                                    </li>
                                </ul>
                            </li>
                            <li><span>&middot;</span>고객의 회사가 정한 방법에 따라 서비스 이용료 환불 신청을 할 수 있습니다.</li>
                            <li><span>&middot;</span>고객은 납부하거나 청구된 서비스 이용료에 대하여 이의가 있을 경우 요금을 납부하거나 청구를 받은 날로부터 3월 이내에 회사에 이의 신청을 할 수 있습니다. 다만, 회사의 귀책사유로 과오납이 발생한 경우, 고객은 본문에 관계없이 납부하거나 청구된 요금 등에 대하여 이의 신청을 할 수 있습니다.</li>
                            <li><span>&middot;</span>회사는 제4항의 이의 신청에 대하여 신청일로부터 10일 이내에 처리 결과를 회신합니다. 다만, 고객과의 협의에 따라 일자는 변경될 수 있습니다.</li>
                        </ul>
                        <h1 className="pol_tit">제 7 장 손해배상</h1>
                        <h2 className="pol_sub_tit">제 21 조 (손해배상의 범위 및 청구)</h2>
                        <ul className="pol_unord_ul">
                            <li><span>&middot;</span>회사와 고객은 서비스 이용과 관련하여 고의 또는 과실로 상대방에게 손해를 끼친 경우에는 이를 배상하여야 합니다.</li>
                            <li><span>&middot;</span>고객이 제1항에 따라 회사에 대해 손해배상을 청구할 경우 그 청구 사유를 서면으로 기재하여 소명 자료와 함께 회사에 제출해야 합니다.</li>
                        </ul>
                        <h2 className="pol_sub_tit">제 22 조 (면책조항)</h2>
                        <ul className="pol_unord_ul">
                            <li><span>&middot;</span>회사는 천재지변, 국가 비상사태, 정부 또는 규제기관의 명령 등을 포함하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우, 고객에 대해 책임을 부담하지 않습니다.</li>
                            <li><span>&middot;</span>회사는 제10조의 사유에 의한 서비스의 제한 또는 중단에 대해 귀책사유가 없는 한 책임을 부담하지 않습니다.</li>
                            <li><span>&middot;</span>고객의 귀책사유 또는 이 계약상 고객의 의무를 위반한 결과, 회사가 서비스를 제공할 수 없게 되거나 그로 인해 고객에게 손해가 발생한 경우, 회사는 고객에 대해 책임을 부담하지 않습니다.</li>
                            <li><span>&middot;</span>어플리케이션이 탑재된 고객의 스마트폰 및 태블릿 등의 장애∙오류 및 고객이 서비스를 이용하는 통신수단(유∙무선 통신, Wi-Fi 등의 장애∙오류 등으로 인하여 서비스를 제공할 수 없게 되거나 그로 인해 고객에게 손해가 발생한 경우, 회사는 고객에 대해 귀책사유가 없는 한 책임을 부담하지 않습니다.</li>
                            <li><span>&middot;</span>회사는 서비스 제공과 관련된 제조사(판매∙유통사 포함) 또는 제휴사의 귀책 사유로 인하여 서비스를 제공할 수 없게 되거나 그로 인하여 고객에게 손해가 발생한 경우, 고객에 대해 책임을 부담하지 않습니다.</li>
                            <li><span>&middot;</span>회사는 고객이 서비스를 이용하여 기대하는 수익을 얻지 못하거나, 상실한 것에 대하여 책임을 지지 않으며, 서비스를 이용하면서 취득한 정보 또는 자료 등을 신뢰하거나 이용함으로 인해 고객에게 손해가 발생한 경우, 고객에 대해 책임을 부담하지 않습니다.</li>
                            <li><span>&middot;</span>회사는 고객이 서비스에 게시한 각종 정보∙자료 등 일체의 게시물과 관련하여, 사실의 신뢰도, 정확성 등 내용에 대하여 책임을 지지 않으며, 고객간 또는 고객과 제3자 간 서비스와 관련하여 발생한 분쟁에 대하여 개입할 의무가 없고, 이로 인한 손해를 배상할 책임도 없습니다. 이와 관련하여 회사가 제3자에게 손해를 배상하거나 기타 비용을 지출한 경우, 회사는 해당 고객에게 구상권을 행사할 수 있습니다.</li>
                            <li><span>&middot;</span>회사는 고객이 서비스에 정보∙자료 등 게시물을 게시하기 전에, 해당 게시물의 내용을 확인 또는 검토하여야 할 의무가 없으며, 그 결과에 대해 책임을 부담하지 않습니다.</li>
                            <li><span>&middot;</span>회사는 서비스를 통해 광고가 이루어진 제품 또는 용역의 내용∙품질 등에 대하여 귀책사유가 없는 한 책임을 부담하지 않습니다.</li>
                            <li><span>&middot;</span>회사는 서비스를 매개로 이루어진 고객간 또는 고객과 제3자간의 거래에 개입하거나 이를 모니터링할 의무가 없고, 그에 대한 책임을 부담하지 않습니다.</li>
                            <li><span>&middot;</span>고객이 이용하는 통신 수단(유∙무선 통신, Wi-Fi 등 포함) 및 통신 구간에서의 통신 내용 또는 고객 개인정보 유출 등 침해 사고가 발생하는 경우, 회사는 귀책사유가 없는 한 책임을 부담하지 않습니다.</li>
                            <li><span>&middot;</span>서비스는 대한민국 영내를 기반으로 제공되는 것으로, 고객이 해외에서 서비스를 이용할 수 없게 되거나 이로 인해 고객에게 손해가 발생하는 경우, 회사는 고객에 대해 아무런 책임을 부담하지 않습니다.</li>
                            <li><span>&middot;</span>회사는 고객이 무료로 서비스를 이용하는 것과 관련하여 발생한 손해에 대해서는 귀책사유가 없는 한 손해배상 책임을 부담하지 않습니다.</li>
                            <li><span>&middot;</span>고객의 이 약관 위반 행위 또는 불법 행위 등 고객의 귀책 사유로 인하여, 회사가 제3자 또는 규제기관 등으로부터 손해배상 청구 또는 소송을 비롯한 각종 이의 제기를 받는 경우, 해당 고객은 자신의 책임과 비용으로 회사를 면책시켜야 하며, 회사가 면책되지 못한 경우 해당 고객은 그로 인하여 회사에 발생한 모든 손해를 배상하여야 합니다.</li>
                        </ul>
                        <h1 className="pol_tit">제 8 장 기타</h1>
                        <h2 className="pol_sub_tit">제 23 조 (고객에 대한 통지 및 정보제공)</h2>
                        <ul className="pol_unord_ul">
                            <li><span>&middot;</span>회사의 고객에 대한 안내 등 공지는 어플리케이션 또는 홈페이지를 통해 이루어 집니다.</li>
                            <li><span>&middot;</span>회사는 고객의 서비스 이용에 필요한 각종 정보 또는 자료 등을 어플리케이션 또는 홈페이지를 통해 고객에게 제공할 수 있습니다.</li>
                        </ul>
                        <h2 className="pol_sub_tit">제 24 조 (일부 무효)</h2>
                        <p className="title_content">관련 법령 등에 의하여 이 약관의 일부 규정이 무효가 될 경우, 나머지 규정만으로 그 목적 달성이 불가능하거나 고객에게 부당하게 불리하지 않다면, 이 약관은 그 범위내에서 효력을 유지합니다.</p>
                        <h2 className="pol_sub_tit">제 25 조 (분쟁의 조정 등)</h2>
                        <ul className="pol_unord_ul">
                            <li><span>&middot;</span>이 약관에 명시되지 않는 사항은 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 대한민국의 관련 법령과 상관습에 따릅니다.</li>
                            <li><span>&middot;</span>회사와 고객간의 분쟁이 있을 경우 상호 협의를 통하여 해결하는 것을 원칙으로 하되, 당사자간 협의가 이루어지지 아니하거나 협의를 할 수 없는 경우에는 민사소송법의 주소지를 관할하는 법원을 합의관할로 합니다.</li>
                            <li><span>&middot;</span>이 약관의 준거법률은 대한민국 법률에 따릅니다.</li>
                        </ul>
                        <h1 className="pol_tit">부칙</h1>
                        <h2 className="pol_sub_tit">제 1 조 (시행일)</h2>
                        <p className="title_content">이 약관은 2020년 04월 01일부터 시행합니다.</p>
                    </div>
                )
        }
    }
    else if (lang === 'en') {
        switch (p_version) {
            case 0:
            // case 2:
            //     return ()
            case 1:
                return (
                    <div data-p-version={1}>
                        <h1 className="pol_tit"></h1>
                        <p className="title_content">
                            Welcome! Thank you for your interest in our services. 
                        </p>
                        <p className="title_content">
                            Please read these Terms of Use carefully before using any algorigo service("algo Health"). By using any algorigo service you signify your agreement to these Terms of Use. If you do not agree to these Terms of Use, you may not use the algorigo service.
                        </p>
                        <p className="title_content">
                            algorigo may modify these Terms of Use at any time by updating this posting. You are bound by any such modification and should therefore visit this page periodically to review these Terms of Use.
                        </p>
                        <h2 className="pol_sub_tit">Use of Content and Service</h2>
                        <p className="title_content">
                            The content contained on algorigo Health, such as text, graphics, images, audio, video and other material, as well as the domain names, tagline, organization and user look and feel (collectively, the "Content"), is protected by copyright, trademark and other such laws in Korea and foreign countries, and is owned or controlled by algorigo
                        </p>
                        <p className="title_content">
                            Unauthorized use of the Content may violate copyright, trademark, and other laws. Where algorigo site is configured to enable the download of particular Content, you may download one copy of such Content to a single computer for your personal, noncommercial home use only, provided that (a) you retain all copyright and other proprietary notices contained in the original Content, (b) you may not sell or modify the Content or reproduce, display, publicly perform, distribute, or otherwise use the Content in any way for any public or commercial purpose without the prior written permission of algorigo, and (c) you may not use the Content in a manner that suggests an association with any of our products, services or brands.
                        </p>
                        <p className="title_content">
                            You may not, without the prior written permission of algorigo, "mirror" on any other server any material contained on any algo Health. The use of the Content on any other website or in a networked computer environment for any purpose is prohibited, without the express written permission of algorigo. The trademarks, logos and service marks (the "Marks") displayed on the algo Health are owned by algorigo or third parties. You are prohibited from use of those Marks without the express, written permission of algorigo or such third party. If you would like information about obtaining algorigo's permission to use the Content on your website, email <a href="mailto:admin@algorigo.com">admin@algorigo.com</a>
                        </p>
                        <p className="title_content">
                            In the event that we offer downloads of software on a algorigo site and you download such software, the software, including without limitation any files, images incorporated in or generated by the software, and data accompanying the software (collectively, the "Software") is licensed to you by us or third-party licensors for your personal, noncommercial home use only. We do not transfer title to the Software to you. You may not distribute or otherwise exploit the Software or decompile, reverse engineer, disassemble or otherwise reduce the Software to a human-readable form. UNLESS OTHERWISE SPECIFICALLY AND EXPRESSLY STATED ELSEWHERE, algorigo HEREBY DISCLAIMS ALL WARRANTIES WITH REGARD TO THE SOFTWARE, INCLUDING WITHOUT LIMITATION ALL WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NONINFRINGEMENT, WHETHER SUCH WARRANTIES ARE EXPRESS, IMPLIED OR STATUTORY.
                        </p>
                        <p className="title_content">
                            TO THE FULLEST EXTENT PERMITTED BY LAW, algorigo SHALL NOT BE LIABLE FOR (1) ANY INACCURACY, ERROR IN OR FAILURE OF THE SOFTWARE; (2) ANY LOSS OR DAMAGE (INCLUDING WITHOUT LIMITATION ANY CONSEQUENTIAL, INDIRECT, INCIDENTAL, SPECIAL OR EXEMPLARY DAMAGES) ARISING FROM ANY DOWNLOAD OR USE MADE OF THE SOFTWARE, OR OCCASIONED BY ANY SUCH INACCURACY, ERROR OR FAILURE OF THE SOFTWARE.
                        </p>
                        <h2 className="pol_sub_tit">Account</h2>
                        <ul className="pol_unord_ul">
                            <li>
                                You may need to create an account to use of our service("algo Health"). By creating a algorigo account, you agree that: 
                                <ul className="pol_ord_ul">
                                    <li>
                                        1. You'll provide accurate and complete information when you create your account.<br />
                                        2. You'll update your account if any of your information changes.<br />
                                        3. You'll safeguard your password and won't share it with anyone. <br />
                                        4. You'll contact us immediately if you suspect anyone else is using your account. <br />
                                    </li>
                                </ul>
                                <p className="title_content">
                                    You can delete your algorigo account at any time on our app("algo Health")
                                </p>
                            </li>
                        </ul>
                        <h2 className="pol_sub_tit">Change / Termination</h2>
                        <ul className="pol_unord_ul">
                            <li>
                                We may, at any time: 
                                <ul className="pol_ord_ul">
                                    <li>
                                        1. Change, add, suspend or remove features from our service.<br />
                                        2. Suspend or terminate your right to use our Service including access to your account or data. <br />
                                        3. Pre-screen, review, flag, filter, modify, refuse, reject, block access to or remove any or all content from our service.<br />
                                    </li>
                                </ul>
                                <p className="title_content">
                                    You can terminate these Terms at any time by deleting your algorigo account and stopping your use of our service 
                                </p>
                                <p className="title_content">
                                    We may suspend or delete your algorigo account or stop providing you with all or part of our service at any time, if:
                                </p>
                                <ul className="pol_ord_ul">
                                    <li>
                                        1. We reasonably suspect that you have violated these Terms or the instructions we provide in our service.<br />
                                        2. You have clearly demonstrated (either directly or through your actions, statements, or otherwise) that you don’t intend to comply with these Terms.<br />
                                        3. We decide to end all or part of our services (either worldwide or in the country where you are a resident or from where you are using our service).<br />
                                        4. We are required by Applicable Law to end all or part of our services (for example if due to changes in Applicable Law or due to court rulings or judgements which make the Services or parts of them to become or be considered unlawful).<br />
                                    </li>
                                </ul>
        
                            </li>
                        </ul>
                        <h2 className="pol_sub_tit">Communication</h2>
                        <p className="title_content">
                            Some services on the algo Health permit or require you to create an account to participate or to secure additional benefits. You agree to provide, maintain and update true, accurate, current, and complete information about yourself as prompted by our registration processes (the "Registration Data"). You agree not to impersonate any person or entity or misrepresent your identity or affiliation with any person or entity, including using another person's username, password or other account information, or another person's name, likeness, voice, image or photograph. You also agree to notify us promptly at <a href="mailto:admin@algorigo.com">admin@algorigo.com</a> of any unauthorized use of your username, password, other account information, or any other breach of security that you become aware of involving or relating to any algorigo service.
                        </p>
                        <p className="title_content">
                            We may suspend or terminate your account and your ability to use any algorigo service or portion thereof for failure to comply with these Terms of Use or any special terms related to a particular service.
                        </p>
                        <h2 className="pol_sub_tit">Algorigo's Liability</h2>
                        <p className="title_content">
                            algorigo makes no representations or warranties about the accuracy, reliability, completeness, or r timeliness of the Content or about the results to be obtained from using the algorigo service and the Content. Any use of the algorigo service and the Content is at your own risk. Changes are periodically made to algorigo service and may be made at any time. algorigo further reserves the right to modify, suspend, or discontinue any service on or feature of the algorigo service(including any changes to or removal of Content) at any time with or without notice to you, and algorigo shall not be liable to you or to any third party should it exercise such rights. Some Content on the algorigo service may be provided by third parties, and algorigo will not be held responsible for any such Content provided by third parties.
                        </p>
                        <p className="title_content">
                            ALGORIGO DOES NOT WARRANT THAT THE ALGORIGO SITES WILL OPERATE ERROR-FREE OR THAT THE ALGORIGO SITES OR THEIR SERVERS ARE FREE OF COMPUTER VIRUSES OR OTHER HARMFUL GOODS. IF YOUR USE OF THE ALGORIGO SITES OR THE CONTENT RESULTS IN THE NEED FOR SERVICING OR REPLACING EQUIPMENT OR LOSS OF PROFITS OR DATA, ALGORIGO WILL NOT BE RESPONSIBLE FOR THOSE COSTS.
                        </p>
                        <p className="title_content">
                            THE ALGORIGO SITES AND CONTENT ARE PROVIDED ON AN 'AS IS' BASIS WITHOUT ANY WARRANTIES OF ANY KIND. ALGORIGO, TO THE FULLEST EXTENT PERMITTED BY LAW, DISCLAIMS ALL WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE WARRANTY OF MERCHANTABILITY, NONINFRINGEMENT OF PROPRIETARY OR THIRD-PARTY RIGHTS, AND THE WARRANTY OF FITNESS FOR PARTICULAR PURPOSE.
                        </p>
                        <p className="title_content">
                            ALGORIGO SHALL USE REASONABLE EFFORTS TO PROTECT INFORMATION OR DATA SUBMITTED BY YOU TO ALGORIGO SITE IN CONNECTION WITH ANY SERVICE ON OR FEATURE OF THE ALGORIGO SITE, BUT YOU AGREE THAT YOUR SUBMISSION OF SUCH INFORMATION OR DATA IS AT YOUR SOLE RISK, AND YOU HEREBY RELEASE ALGORIGO FROM ANY AND ALL LIABILITY TO YOU FOR ANY LOSS OR LIABILITY RELATING TO SUCH INFORMATION IN ANY WAY.
                        </p>
                        <p className="title_content">
                            ALGORIGO IS NOT RESPONSIBLE FOR ANY DATA CHARGES YOU MAY INCUR IN CONNECTION WITH YOUR USE OF ANY SERVICE ON OR FEATURE OF THE ALGORIGO SITES.
                        </p>
                        <h2 className="pol_sub_tit">Disclaimer of Certain Damages</h2>
                        <p className="title_content">
                            Your use of the algorigo Serivice is at your own risk. If you are dissatisfied with any of the Content, other contents, any service on, or feature of the algo health or with these Terms and Conditions, your sole remedy is to discontinue use of the algorigo service. IN NO EVENT WILL algorigo OR ANY THIRD PARTIES MENTIONED AT THE ALGORIGO SITES BE LIABLE FOR ANY DAMAGES WHATSOEVER (INCLUDING, BUT NOT LIMITED TO, INCIDENTAL, EXEMPLARY, AND CONSEQUENTIAL DAMAGES, LOST PROFITS, OR DAMAGES RESULTING FROM LOST DATA OR BUSINESS INTERRUPTION) RESULTING FROM THE USE OR INABILITY TO USE THE ALGORIGO SITES AND THE CONTENT, WHETHER BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT algorigo IS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME COUNTRIES, STATES, OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR LOSS OR DAMAGE CAUSED BY GROSS NEGLIGENCE, PERSONAL INJURY OR DEATH, OR OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU, IN WHICH CASE algorigo’s LIABILITY SHALL BE LIMITED TO THE FULLEST EXTENT SUCH EXCLUSION OR LIMITATION IS ALLOWABLE UNDER THE APPLICABLE LAW. In no event shall the total liability of algorigo to you for all losses and damages, including for any implied warranties, exceed the greater of fifty U.S. dollars <u>(US $25.00)</u> or the total amount you paid algorigo to use any service on or feature of the algorigo Sites in the twelve months prior to the date of an initial claim made against algorigo.
                        </p>
                        <h2 className="pol_sub_tit">Indemnity</h2>
                        <p className="title_content">
                            By using a algorigo service, you agree, to the extent permitted by law, to defend, indemnify, and hold harmless algorigo, its officers, directors, employees and agents, from and against any claims, actions or demands, including without limitation reasonable legal and accounting fees, alleging or resulting from your breach of these Terms of Use or your violation of any law or rights of a third party.
                        </p>
                        <h2 className="pol_sub_tit">Export Control</h2>
                        <p className="title_content">
                            The United States and certain other countries control the export of products and information. You agree to comply with such restrictions and not to export or re-export the Content to countries or persons prohibited under export control laws. By downloading the Content, you are agreeing that you are not in a country where such export is prohibited. You are responsible for compliance with the laws of your local jurisdiction regarding the import, export, or re-export of the Content.
                        </p>
                        <h2 className="pol_sub_tit">Copyright Policy</h2>
                        <p className="title_content">
                            algorigo respects the intellectual property rights of others. It is algorigo's policy, at its discretion and when appropriate, to terminate the accounts of users who may infringe or repeatedly infringe the copyrights of third parties.
                        </p>
                        <ul className="pol_unord_ul">
                            <li>
                                To submit a copyright infringement notification to algorigo relating to a algorigo site, including but not limited to User Submissions or Content, please submit a notification pursuant to the Digital Millennium Copyright Act ("DMCA") or, as applicable, other laws, by providing the below-specified Copyright Agent with the following information in writing:
                                <ul className="pol_ord_ul">
                                    <li>
                                        <span>&middot;</span> A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.<br />
                                        <span>&middot;</span> Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works at a single online site are covered by a single notification, a representative list of such works at that site.<br />
                                        <span>&middot;</span> Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit the service provider to locate the material.<br />
                                        <span>&middot;</span> Information reasonably sufficient to permit the service provider to contact the complaining party, such as an address, telephone number, and, if available, an electronic mail address at which the complaining party may be contacted.<br />
                                        <span>&middot;</span> A statement that the complaining party has a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law.<br />
                                        <span>&middot;</span> A statement that the information in the notification is accurate, and under penalty of perjury, that the complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.<br />
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <p className="title_content">
                            You acknowledge that if you fail to comply with all of the requirements of the preceding paragraph, your notification may not be valid. Please see 17 U.S.C. § 512 for more information regarding the DMCA and DMCA notifications.
                        </p>
                        <p className="title_content">
                            algorigo to Receive Notification of Claimed Copyright Infringement can be reached as follows:<br />
                            By email: <a href="mailto:admin@algorigo.com">admin@algorigo.com</a>
                        </p>
                        <h2 className="pol_sub_tit">General</h2>
                        <p className="title_content">
                            Except to the extent expressly provided in the following paragraph, all legal issues arising from or related to your use of any algorigo service and these Terms of Use are prescribed and implemented by the laws of the Republic of Korea. If there is a dispute between the company and you regarding the use of the service, we will sincerely discuss it to resolve it. Nevertheless, if not resolved, the settlement of the dispute follows the procedure presccribed by the Civil Procedure Act of the Republic Korea.
                        </p>
                        <p className="title_content">
                            If you are a resident of any European Union country, the United Kingdom (U.K.), Switzerland, Norway, Iceland or Australia, the governing law and forum shall be the laws and courts of your usual place of residence.
                        </p>
                        <p className="title_content">
                            Specifically excluded from application to these Terms of Use is the United Nations Convention on Contracts for the International Sale of Goods. 
                        </p>
                        <p className="title_content">
                            In the event of a complaint, or to request further information, the provider may be contacted by email at <a href="mailto:admin@algorigo.com">admin@algorigo.com</a>
                        </p>
                        <p className="title_content">
                            If you are under the age of majority in your country of residence, you should review these Terms of Use with a parent or guardian to ensure that you and parent or legal guardian understand these Terms of Use.
                        </p>
                        <i>Last updated : May 24, 2021</i>
                    </div>
                )
        }
    }
    return (
        <div>
            <h1 className="pol_tit">Page not found.</h1>
            <h2 className="title_content">language code : {lang}<br/>document Version : {p_version}</h2>
        </div>
    )
}