// DO NOT EDIT manually
// This file has edited by setConfig.sh file
export default {
    RUNMODE: 'service',
    // cognito: {
    //     REGION: 'ap-northeast-2',
    //     USER_POOL_ID: 'ap-northeast-2_LJGerS6Ga',
    //     APP_CLIENT_ID: '6v8daijua2m0th10ce6jl4f9g',
    // },
    baseURL: 'https://www.algorigo.com',
};
