import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next'
import { TitleComponent } from '../TitleComponent'
import PictureWebpComponent from '../PictureWebpComponent'

// images
import main_w_3x_png from '../../images/illustration-mian-w@3x.png';
import main_w_2x_png from '../../images/illustration-mian-w@2x.png';
import main_w_1x_png from '../../images/illustration-mian-w.png';

import algohealth_w_3x_png from '../../images/illustration-algohealth-w@3x.png';
import algohealth_w_2x_png from '../../images/illustration-algohealth-w@2x.png';
import algohealth_w_1x_png from '../../images/illustration-algohealth-w.png';

import algostudy_w_3x_png from '../../images/illustration-algostudy-n-w@3x.png';
import algostudy_w_2x_png from '../../images/illustration-algostudy-n-w@2x.png';
import algostudy_w_1x_png from '../../images/illustration-algostudy-n-w.png';

import algowork_w_3x_png from '../../images/illustration-algowork-w@3x.png';
import algowork_w_2x_png from '../../images/illustration-algowork-w@2x.png';
import algowork_w_1x_png from '../../images/illustration-algowork-w.png';

import algocare_w_3x_png from '../../images/illustration-algocare-w@3x.png';
import algocare_w_2x_png from '../../images/illustration-algocare-w@2x.png';
import algocare_w_1x_png from '../../images/illustration-algocare-w.png';


import ichealth from '../../images/icon-algohealth-n@3x.png';
import icstudy from '../../images/icon-algostudy-n@3x.png';
import icwork from '../../images/icon-algowork-n@3x.png';
import iccare from '../../images/icon-algocare-n@3x.png';

// import icarrowright from '../../images/icon-arrow-right@3x.png';

import mbanner2_w_3x_png from '../../images/image-banner-w@3x.png';
import mbanner2_w_2x_png from '../../images/image-banner-w@2x.png';
import mbanner2_w_1x_png from '../../images/image-banner-w.png';

// partners logo
import zer_3x_png from '../../images/partners-logo-zer-01-ne-w@3x.png';
import zer_2x_png from '../../images/partners-logo-zer-01-ne-w@2x.png';
import zer_1x_png from '../../images/partners-logo-zer-01-ne-w.png';
import transys_3x_png from '../../images/partners-logo-transys-w@3x.png';
import transys_2x_png from '../../images/partners-logo-transys-w@2x.png';
import transys_1x_png from '../../images/partners-logo-transys-w.png';
import duoback_3x_png from '../../images/partners-logo-duoback-w@3x.png';
import duoback_2x_png from '../../images/partners-logo-duoback-w@2x.png';
import duoback_1x_png from '../../images/partners-logo-duoback-w.png';
import chungnam_3x_png from '../../images/partners-logo-chungnam-w@3x.png';
import chungnam_2x_png from '../../images/partners-logo-chungnam-w@2x.png';
import chungnam_1x_png from '../../images/partners-logo-chungnam-w.png';
import hanyang_3x_png from '../../images/partners-logo-hanyang-w@3x.png';
import hanyang_2x_png from '../../images/partners-logo-hanyang-w@2x.png';
import hanyang_1x_png from '../../images/partners-logo-hanyang-w.png';
import techstorm_3x_png from '../../images/partners-logo-techstorm-w@3x.png';
import techstorm_2x_png from '../../images/partners-logo-techstorm-w@2x.png';
import techstorm_1x_png from '../../images/partners-logo-techstorm-w.png';
import ewha_3x_png from '../../images/partners-logo-ewha-w@3x.png';
import ewha_2x_png from '../../images/partners-logo-ewha-w@2x.png';
import ewha_1x_png from '../../images/partners-logo-ewha-w.png';
import sogang_3x_png from '../../images/partners-logo-sogang-w@3x.png';
import sogang_2x_png from '../../images/partners-logo-sogang-w@2x.png';
import sogang_1x_png from '../../images/partners-logo-sogang-w.png';


import './Main.css'

import { NewsType } from '../../types/NewsType'
import ContactForm from '../Contact/ContactForm';
import { useScrollFadeIn } from '../../utils/useScrollEvent'
import { openNewTabUrl } from '../../utils/commonUtils'



function Main(props: { newsList: NewsType[] }) {
    const { newsList } = props;

    const { t } = useTranslation();

    const [isActiveServiceSec, setIsActiveServiceSec] = useState<number>(1); //Service중 "알고헬스", "알고스터디", "알고워크", "알고케어" Tab

    const animatedFadeInTitle1 = useScrollFadeIn('up', 0.5, 0);
    const animatedFadeInTitleContent1 = useScrollFadeIn('up', 0.8, 0.3);
    // const animatedTitleImg = useScrollClipPath('left', 2.5, 0.3, 0.2, 1.5);

    const animatedFadeIn_main_top_2_content = useScrollFadeIn('up', 0.8, 0.3);
    const animatedFadeIn_main_top_2_card1 = useScrollFadeIn('up', 0.5, 0.2);
    const animatedFadeIn_main_top_2_card2 = useScrollFadeIn('up', 0.5, window.matchMedia('(min-width: 960px)').matches ? 0.4 : 0.2);
    const animatedFadeIn_main_top_2_card3 = useScrollFadeIn('up', 0.5, window.matchMedia('(min-width: 960px)').matches ? 0.6 : 0.2);

    const animatedFadeIn_main_top_3_Title = useScrollFadeIn('up', 0.5, 0.2, 0.7);
    const animatedFadeIn_main_top_3_Tab1 = useScrollFadeIn('up', 1, 0.0, 0.3);
    const animatedFadeIn_main_top_3_Tab2 = useScrollFadeIn('up', 0.4, 0.0, 0.2);
    const animatedFadeIn_main_top_3_Tab3 = useScrollFadeIn('up', 0.4, 0.0, 0.2);
    const animatedFadeIn_main_top_3_Tab4 = useScrollFadeIn('up', 0.4, 0.0, 0.2);

    // const animatedPartner1 = useScrollClipPath('left', 0.5, 0.3, 0.5, 1.1);
    // const animatedPartner2 = useScrollClipPath('left', 0.5, 0.4, 0.5, 1.1);
    // const animatedPartner3 = useScrollClipPath('left', 0.5, 0.5, 0.5, 1.1);
    // const animatedPartner4 = useScrollClipPath('left', 0.5, 0.6, 0.5, 1.1);
    // const animatedPartner5 = useScrollClipPath('left', 0.5, 0.7, 0.5, 1.1);
    // const animatedPartner6 = useScrollClipPath('left', 0.5, 0.8, 0.5, 1.1);
    // const animatedPartner7 = useScrollClipPath('left', 0.5, 0.9, 0.5, 1.1);
    // const animatedPartner8 = useScrollClipPath('left', 0.5, 1.0, 0.5, 1.1);
    // const animatedPartner1 = useScrollFadeIn('', 0.5, 0.3, 0.5);
    // const animatedPartner2 = useScrollFadeIn('', 0.5, 0.4, 0.5);
    // const animatedPartner3 = useScrollFadeIn('', 0.5, 0.5, 0.5);
    // const animatedPartner4 = useScrollFadeIn('', 0.5, 0.6, 0.5);
    // const animatedPartner5 = useScrollFadeIn('', 0.5, 0.7, 0.5);
    // const animatedPartner6 = useScrollFadeIn('', 0.5, 0.8, 0.5);
    // const animatedPartner7 = useScrollFadeIn('', 0.5, 0.9, 0.5);
    // const animatedPartner8 = useScrollFadeIn('', 0.5, 1.0, 0.5);
    const animatedFadeIn_partner_Title = useScrollFadeIn('up', 0.5, 0.2, 0.5);
    const animatedPartner = useScrollFadeIn('up', 0.7, 0.3, 0.5);

    const animatedFadeIn_news_Title = useScrollFadeIn('up', 0.5, 0.2, 0.5);
    const animatedFadeIn_contact_Title = useScrollFadeIn('up', 0.5, 0.2, 0.5);

    const map_lang_url = document.documentElement.lang === 'ko' ? "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d791.2596296548736!2d127.05275862851812!3d37.50700966211634!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca4122ca5e0cf%3A0x9334918234da396e!2z7ISc7Jq47Yq567OE7IucIOqwleuCqOq1rCDthYztl6TrnoDroZw3N-q4uCAxMS0xMg!5e0!3m2!1sko!2skr!4v1710997969963!5m2!1sko!2skr" : "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3165.0385636512483!2d127.05340369999999!3d37.5070086!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca4122ca5e0cf%3A0x9334918234da396e!2s11-12%20Teheran-ro%2077-gil%2C%20Gangnam-gu%2C%20Seoul!5e0!3m2!1sen!2skr!4v1711001351844!5m2!1sen!2skr";

    return (
        <section className="container main_ctr">
            <TitleComponent />
            <section className="content main_top_1">
                <div className="inner">
                    <div className='main_top_1_1'>
                        <div {...animatedFadeInTitle1}>
                            <div className="bg--yellow7"></div>
                            <h1 className="title lang_en_font">
                                <Trans i18nKey="main.company_slogan.title" components={[<br />]} />
                            </h1>
                        </div>
                        <p className="title_content" {...animatedFadeInTitleContent1}>
                            <Trans i18nKey="main.company_slogan.title_content" components={[<em className="main_line"></em>]} />
                        </p>
                    </div>
                    <div className="main_top_1_2">
                        <div className="content_img">
                            <PictureWebpComponent fb_img_1x={main_w_1x_png} fb_img_2x={main_w_2x_png} fb_img_3x={main_w_3x_png} fb_alt="we are.." alt_img_name="illustration-mian"></PictureWebpComponent>
                        </div>
                    </div>
                </div>
            </section>
            <section className="content_wide bg_wrap">
                <div className="bg--yellow2"></div>
                <div className="main_top_2">
                    <div className="main_top_2_1 inner">
                        <h1 className="title lang_en_font">algori<span className="black">thms to</span> go</h1>
                        <p className="title_content" {...animatedFadeIn_main_top_2_content}><Trans i18nKey="main.company_intro.title_content" components={[<br />]} /></p>
                    </div>
                    <div className="main_top_2_2">
                        <ul>
                            <li className="main_top_2_2_2">
                                <div className="inner" {...animatedFadeIn_main_top_2_card1}>
                                    <h5 className="title lang_en_font">Study</h5>
                                    <div className="title_content">{t('main.company_intro.card.study')}</div>
                                </div>
                            </li>
                            <li className="main_top_2_2_1">
                                <div className="inner" {...animatedFadeIn_main_top_2_card2}>
                                    <h5 className="title lang_en_font">Health</h5>
                                    <div className="title_content">{t('main.company_intro.card.health')}</div>
                                </div>
                            </li>
                            <li className="main_top_2_2_3">
                                <div className="inner" {...animatedFadeIn_main_top_2_card3}>
                                    <h5 className="title lang_en_font">Mobility</h5>
                                    <div className="title_content">{t('main.company_intro.card.mobility')}</div>
                                </div>
                            </li>
                        </ul>
                        <div className="show_more max_width">
                            <Link to="/about">{t('main.company_intro.more')}<span className="arrow-r"></span></Link>
                        </div>
                    </div>
                </div>
            </section>
            <section className="content main_top_3">
                <div className="inner">
                    <div className="" {...animatedFadeIn_main_top_3_Title}>
                        <h1 className="title">
                            <span className="up lang_en_font">{t('main.service_intro.title.up')}</span>
                            <Trans i18nKey="main.service_intro.title" components={[<br className="u_mobile" />]} />
                        </h1>
                    </div>
                    <ul className="tab tab_under">
                        <li className={isActiveServiceSec === 1 ? "active" : ""} onClick={() => setIsActiveServiceSec(1)}>{t('main.service_intro.category.algostudy')}</li>
                        <li className={isActiveServiceSec === 2 ? "active" : ""} onClick={() => setIsActiveServiceSec(2)}>{t('main.service_intro.category.algowork')}</li>
                        <li className={isActiveServiceSec === 3 ? "active" : ""} onClick={() => setIsActiveServiceSec(3)}>{t('main.service_intro.category.algohealth')}</li>
                        <li className={isActiveServiceSec === 4 ? "active" : ""} onClick={() => setIsActiveServiceSec(4)}>{t('main.service_intro.category.algocare')}</li>
                    </ul>
                    {/* tab1 */}
                    <div className={"service_sec " + (isActiveServiceSec === 1 ? "" : " no_display")}>
                        <div className="service_img">
                            <PictureWebpComponent fb_img_1x={algostudy_w_1x_png} fb_img_2x={algostudy_w_2x_png} fb_img_3x={algostudy_w_3x_png} fb_alt="algo study" alt_img_name="illustration-algostudy-n"></PictureWebpComponent>
                        </div>
                        <div className="service_content" {...animatedFadeIn_main_top_3_Tab1}>
                            <h2 className="title">
                                <img src={icstudy} alt="" />
                                <span>{t('main.service_intro.service2.title')}</span>
                                {/* <span className="comingsoon">coming soon!</span> */}
                            </h2>
                            <p className="title_content">{t('main.service_intro.service2.title_content')}</p>
                            <ul>
                                <li><span className="lang_en_font">01</span><span>{t('main.service_intro.service2.li1')}</span></li>
                                <li><span className="lang_en_font">02</span><span>{t('main.service_intro.service2.li2')}</span></li>
                                <li><span className="lang_en_font">03</span><span>{t('main.service_intro.service2.li3')}</span></li>
                            </ul>
                            <div className="show_more">
                                <Link to="/services/algostudy">{t('main.service_intro.service.more')}<span className="arrow-r"></span></Link>
                            </div>

                        </div>
                    </div>
                    {/* tab2 */}
                    <div className={"service_sec" + (isActiveServiceSec === 2 ? "" : " no_display")}>
                        <div className="service_img">
                            <PictureWebpComponent fb_img_1x={algowork_w_1x_png} fb_img_2x={algowork_w_2x_png} fb_img_3x={algowork_w_3x_png} fb_alt="algo work" alt_img_name="illustration-algowork"></PictureWebpComponent>
                        </div>
                        <div className="service_content" {...animatedFadeIn_main_top_3_Tab2}>
                            <h2 className="title">
                                <img src={icwork} alt="" />
                                <span>{t('main.service_intro.service3.title')}</span>
                                <span className="comingsoon">coming soon!</span>
                            </h2>
                            <p className="title_content">{t('main.service_intro.service3.title_content')}</p>
                            <ul>
                                <li><span className="lang_en_font">01</span><span><Trans i18nKey="main.service_intro.service3.li1" components={[<br />]} /></span></li>
                                <li><span className="lang_en_font">02</span><span>{t('main.service_intro.service3.li2')}</span></li>
                                <li><span className="lang_en_font">03</span><span>{t('main.service_intro.service3.li3')}</span></li>
                            </ul>
                            <div className="show_more">
                                <Link to="/services/algowork">{t('main.service_intro.service.more')}<span className="arrow-r"></span></Link>
                            </div>
                        </div>
                    </div>
                    {/* tab3 */}
                    <div className={"service_sec" + (isActiveServiceSec === 3 ? "" : " no_display")}>
                        <div className="service_img">
                            <PictureWebpComponent fb_img_1x={algohealth_w_1x_png} fb_img_2x={algohealth_w_2x_png} fb_img_3x={algohealth_w_3x_png} fb_alt="algo health" alt_img_name="illustration-algohealth"></PictureWebpComponent>
                            {/* <img src={algowork} alt="" /> */}
                        </div>
                        <div className="service_content" {...animatedFadeIn_main_top_3_Tab3}>

                            <h2 className="title">
                                <img src={ichealth} alt="" />
                                <span>{t('main.service_intro.service1.title')}</span>
                            </h2>
                            <p className="title_content">{t('main.service_intro.service1.title_content')}</p>
                            <ul>
                                <li><span className="lang_en_font">01</span><span>{t('main.service_intro.service1.li1')}</span></li>
                                <li><span className="lang_en_font">02</span><span>{t('main.service_intro.service1.li2')}</span></li>
                                <li><span className="lang_en_font">03</span><span>{t('main.service_intro.service1.li3')}</span></li>
                            </ul>
                            <div className="show_more">
                                <Link to="/services/algohealth">{t('main.service_intro.service.more')}<span className="arrow-r"></span></Link>
                            </div>
                        </div>
                    </div>
                    {/* tab4 */}
                    <div className={"service_sec" + (isActiveServiceSec === 4 ? "" : " no_display")}>
                        <div className="service_img">
                            <PictureWebpComponent fb_img_1x={algocare_w_1x_png} fb_img_2x={algocare_w_2x_png} fb_img_3x={algocare_w_3x_png} fb_alt="algo care" alt_img_name="illustration-algocare"></PictureWebpComponent>
                            {/* <img src={algocare} alt="" /> */}
                        </div>
                        <div className="service_content" {...animatedFadeIn_main_top_3_Tab4}>
                            <h2 className="title">
                                <img src={iccare} alt="" />
                                <span>{t('main.service_intro.service4.title')}</span>
                                <span className="comingsoon">coming soon!</span>
                            </h2>
                            <p className="title_content">{t('main.service_intro.service4.title_content')}</p>
                            <ul>
                                <li><span className="lang_en_font">01</span><span>{t('main.service_intro.service4.li1')}</span></li>
                                <li><span className="lang_en_font">02</span><span>{t('main.service_intro.service4.li2')}</span></li>
                            </ul>
                            <div className="show_more no_display">
                                <Link to="">{t('main.service_intro.service.more')}<span className="arrow-r"></span></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="content_wide main_top_4">
                <div className="bg_wide_rect">
                    <div className="main_top_4_content" onClick={() => {
                        if (!window.matchMedia('(min-width: 960px)').matches) {
                            openNewTabUrl("https://smartstore.naver.com/algorigo");
                        }
                    }}>
                        <div className="main_top_4_left">
                            <div className="main_top_4_flex">
                                <div className="title_content">{t('main.banner.title')}</div>
                                <div className="title">{t('main.banner.title_content')}</div>
                            </div>
                        </div>
                        <div className="main_top_4_right">
                            <PictureWebpComponent fb_img_1x={mbanner2_w_1x_png} fb_img_2x={mbanner2_w_2x_png} fb_img_3x={mbanner2_w_3x_png} alt_img_name="image-banner"></PictureWebpComponent>
                        </div>
                    </div>
                </div>
                <div className="show_more">
                    <a href="https://smartstore.naver.com/algorigo" target="_black">{t('main.banner.buy')}<span className="arrow-r"></span></a>
                </div>
            </section>
            <section className="content main_top_5">
                <div className="inner">
                    <div {...animatedFadeIn_partner_Title}>
                        <h1 className="title">{t('main.partner.title')}</h1>
                    </div>
                    <ul {...animatedPartner}>
                        <li>
                            <PictureWebpComponent fb_img_1x={zer_1x_png} fb_img_2x={zer_2x_png} fb_img_3x={zer_3x_png} fb_alt="zer01ne" alt_img_name="partners-logo-zer-01-ne"></PictureWebpComponent>
                        </li>
                        <li>
                            <PictureWebpComponent fb_img_1x={transys_1x_png} fb_img_2x={transys_2x_png} fb_img_3x={transys_3x_png} fb_alt="hyundai transys" alt_img_name="partners-logo-transys"></PictureWebpComponent>
                        </li>
                        <li>
                            <PictureWebpComponent fb_img_1x={duoback_1x_png} fb_img_2x={duoback_2x_png} fb_img_3x={duoback_3x_png} fb_alt="duoback" alt_img_name="partners-logo-duoback"></PictureWebpComponent>
                        </li>
                        <li>
                            <PictureWebpComponent fb_img_1x={chungnam_1x_png} fb_img_2x={chungnam_2x_png} fb_img_3x={chungnam_3x_png} fb_alt="chungnam university" alt_img_name="partners-logo-chungnam"></PictureWebpComponent>
                        </li>
                        <li>
                            <PictureWebpComponent fb_img_1x={hanyang_1x_png} fb_img_2x={hanyang_2x_png} fb_img_3x={hanyang_3x_png} fb_alt="hanyang university" alt_img_name="partners-logo-hanyang"></PictureWebpComponent>
                        </li>
                        <li>
                            <PictureWebpComponent fb_img_1x={techstorm_1x_png} fb_img_2x={techstorm_2x_png} fb_img_3x={techstorm_3x_png} fb_alt="techstorm" alt_img_name="partners-logo-techstorm"></PictureWebpComponent>
                        </li>
                        <li>
                            <PictureWebpComponent fb_img_1x={ewha_1x_png} fb_img_2x={ewha_2x_png} fb_img_3x={ewha_3x_png} fb_alt="ewha university" alt_img_name="partners-logo-ewha"></PictureWebpComponent>
                        </li>
                        <li>
                            <PictureWebpComponent fb_img_1x={sogang_1x_png} fb_img_2x={sogang_2x_png} fb_img_3x={sogang_3x_png} fb_alt="sogang university" alt_img_name="partners-logo-sogang"></PictureWebpComponent>
                        </li>
                    </ul>
                </div>
            </section>
            <section className="content_wide main_top_6">
                <div {...animatedFadeIn_news_Title}>
                    <h1 className="title">{t('main.news.title')}</h1>
                </div>
                <ul className="news-group scroll-none">
                    {
                        newsList.map((n: NewsType) => {
                            return (
                                <li key={n.news_id} onClick={() => openNewTabUrl(n.ref_url || '')} className="lang_ko_font">
                                    <div className="news_img">
                                        <img src={n.image_src || ''} alt="" />
                                    </div>
                                    <div className="news_txt_cnt">
                                        <div className="news_type lang_en_font">
                                            {n.type}
                                        </div>
                                        <div className="news_title">
                                            {n.title}
                                        </div>
                                        <div className="news_title_content">
                                            {n.contents}
                                        </div>
                                        <div className="news_date">
                                            <span className="lang_en_font">{n.publish_date}</span> {n.publish_name}
                                        </div>
                                    </div>
                                </li>
                            )
                        })
                    }
                </ul>
                <div className="inner show_more">
                    <Link to="/news">{t('main.news.more')}<span className="arrow-r"></span></Link>
                </div>
            </section>
            <section className="content_wide max_width bg_wrap">
                <div className="bg--blue5"></div>
                <div className="inner">
                    <div className="main_top_7_1" {...animatedFadeIn_contact_Title}>
                        <h1 className="title">
                            <span className="up lang_en_font">{t('contact.title.up')}</span>
                            {t('contact.title')}
                        </h1>
                        <p className="title_content">{t('contact.title_content')}</p>
                    </div>
                    <section className="ctt_form">
                        {/* form */}
                        <ContactForm></ContactForm>
                        {/* Map */}
                        <div className="ctt_map">
                            <iframe title="googlemap" width="100%" height="420" src={map_lang_url}></iframe>
                            <p>
                                <Trans i18nKey="footer.info.address" components={[<span className="lang_en_font"></span>]} />
                            </p>
                            <p><a href="mailto:admin@algorigo.com">admin@algorigo.com</a></p>
                        </div>
                    </section>
                </div>
            </section>
        </section>
    )
}

export default Main
