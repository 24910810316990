import React, { useEffect } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { TitleComponent } from '../TitleComponent'
import ScrollContainer from 'react-indiana-drag-scroll'
import PictureWebpComponent from '../PictureWebpComponent'
import './about.css';

import { useScrollFadeIn, useScrollClipPath } from '../../utils/useScrollEvent'
import { useInterval } from '../../utils/useInterval'

import $ from "jquery";

// images
// import algorigo from '../../images/1-3-x@3x.png';
import chairsensor_w_3x_png from '../../images/image-chairsensor-w@3x.png';
import chairsensor_w_2x_png from '../../images/image-chairsensor-w@2x.png';
import chairsensor_w_1x_png from '../../images/image-chairsensor-w.png';
// import mission_m_3x_png from '../../images/image-about-mission-m@3x.png';
// import mission_m_2x_png from '../../images/image-about-mission-m@2x.png';
// import mission_m_1x_png from '../../images/image-about-mission-m.png';

import mission_w_3x_png from '../../images/image-about-mission-w@3x.png';
import mission_w_2x_png from '../../images/image-about-mission-w@2x.png';
import mission_w_1x_png from '../../images/image-about-mission-w.png';

import algori_svg from '../../images/logo-algori.svg'
import go_svg from '../../images/logo-go.svg'
import CvCard from './CvCard';

function About() {
    const { t } = useTranslation();

    const animatedFadeInTitle1 = useScrollFadeIn('up', 0.5, 0);
    const animatedFadeInTitle2 = useScrollFadeIn('up', 0.5, 0);
    const animatedFadeInTitle3 = useScrollFadeIn('up', 0.5, 0);
    const animatedFadeInTitle4 = useScrollFadeIn('up', 0.5, 0.5);

    const animatedFadeInAbText = useScrollFadeIn('up', 0.8, 0.5);
    const animatedFadeInMission = useScrollFadeIn('up', 0.5, 0.0, 0.1);
    const animatedFadeInCvContent = useScrollFadeIn('up', 1, 0.5);

    const animatedBackGrnYellow = useScrollClipPath('', 0.6, 0.2, 0.1, 0.1, true);

    const history_du = 0.8
    const history_delay = 0.5
    const animatedFadeInLeft0 = useScrollFadeIn('up', history_du, history_delay);
    const animatedFadeInLeft1 = useScrollFadeIn('up', history_du, history_delay);
    const animatedFadeInLeft2 = useScrollFadeIn('up', history_du, history_delay);
    const animatedFadeInLeft3 = useScrollFadeIn('up', history_du, history_delay);
    const animatedFadeInLeft4 = useScrollFadeIn('up', history_du, history_delay);
    const animatedFadeInLeft5 = useScrollFadeIn('up', history_du, history_delay);
    const animatedFadeInLeft6 = useScrollFadeIn('up', history_du, history_delay);
    const animatedFadeInLeft7 = useScrollFadeIn('up', history_du, history_delay);
    const animatedFadeInLeft8 = useScrollFadeIn('up', history_du, history_delay);
    const animatedFadeInLeft9 = useScrollFadeIn('up', history_du, history_delay);
    const animatedFadeInLeft10 = useScrollFadeIn('up', history_du, history_delay);
    const animatedFadeInLeft11 = useScrollFadeIn('up', history_du, history_delay);
    const animatedFadeInLeft12 = useScrollFadeIn('up', history_du, history_delay);
    const animatedFadeInLeft13 = useScrollFadeIn('up', history_du, history_delay);
    const animatedFadeInLeft14 = useScrollFadeIn('up', history_du, history_delay);
    const animatedFadeInLeft15 = useScrollFadeIn('up', history_du, history_delay);
    const animatedFadeInLeft16 = useScrollFadeIn('up', history_du, history_delay);
    const animatedFadeInLeft17 = useScrollFadeIn('up', history_du, history_delay);
    const animatedFadeInLeft18 = useScrollFadeIn('up', history_du, history_delay);
    const animatedFadeInLeft19 = useScrollFadeIn('up', history_du, history_delay);
    const animatedFadeInLeft20 = useScrollFadeIn('up', history_du, history_delay);
    const animatedFadeInLeft22_1 = useScrollFadeIn('up', history_du, history_delay);
    const animatedFadeInLeft22_2 = useScrollFadeIn('up', history_du, history_delay);
    const animatedFadeInLeft22_3 = useScrollFadeIn('up', history_du, history_delay);
    const animatedFadeInLeft23_1 = useScrollFadeIn('up', history_du, history_delay);
    const animatedFadeInLeft23_2 = useScrollFadeIn('up', history_du, history_delay);


    let cv_n = 1;
    const doScrollLeft = () => {
        const q_ele = $('.indiana-scroll-container');
        if (q_ele) {
            const ul_width = q_ele.width()
            const li_width = q_ele.children().first().outerWidth(true)
            if (ul_width && li_width) {
                if (ul_width < li_width * (q_ele.children().length - 1)) {
                    const orig_scroll_width = q_ele.scrollLeft() || 12
                    const scroll_width = (li_width || 0) < 250 ? 172 : 306;
                    q_ele.animate({
                        scrollLeft: orig_scroll_width + scroll_width
                    }, 400, function () {
                        if (cv_n < q_ele.children().length - 1) {
                            cv_n++;
                        } else {
                            cv_n = 1;
                            // q_ele.scrollLeft(0);
                            q_ele.animate({
                                scrollLeft: 0
                            }, 1300)
                        }
                    });
                } else {
                    cv_n = 1;
                    q_ele.animate({
                        scrollLeft: 0
                    }, 1300)
                    console.log('do NOT auto scroll cause UL width')
                }
            }
        }
    }

    useEffect(() => {
        let logoBox: any = document.getElementById('ani_logo');
        logoBox.classList.add('ani_box');

        // const scroll_timer = setInterval(() => {
        //     doScrollLeft();
        // }, 4000);
        // return () => {
        //     clearInterval(scroll_timer);
        // }
    }, []);

    useInterval(() => {
        doScrollLeft();
    }, 4000);

    return (
        <section className="container about_ctr">
            <TitleComponent title={t('header.nav.company_intro')} />
            <section className="content_wide ab_content_w_1">
                <div id="ani_logo">
                    <svg id="logo-left" >
                        <image href={algori_svg} type="image/svg+xml" x="0" y="0"></image>
                    </svg>
                    <svg id="intro" className="intro go" viewBox="0 0 189 90">
                        <text
                            textAnchor="start"
                            x="0"
                            y="58"
                            className="text text-stroke"
                            clipPath="url(#text1)"
                        >thms to</text>
                        <text
                            textAnchor="start"
                            x="0"
                            y="58"
                            className="text text-stroke text-stroke-2"
                            clipPath="url(#text1)"
                        >thms to</text>
                        <text
                            textAnchor="start"
                            x="0"
                            y="58"
                            className="text text-stroke text-stroke-3"
                            clipPath="url(#text1)"
                        >thms to</text>
                        <defs>
                            <clipPath id="text1">
                                <text textAnchor="start" x="0" y="58" className="text">thms to</text>
                            </clipPath>
                        </defs>
                    </svg>
                    <svg id="logo-right">
                        <image href={go_svg} type="image/svg+xml" x="0" y="0"></image>
                    </svg>
                </div>
            </section>
            <section className="content ab_content_2">
                <div className="inner">
                    <div className="bg_wrap" {...animatedFadeInTitle1}>
                        <div className="bg--blue7"></div>
                        <h1 className="title">
                            <span className="up">about us</span>
                            {/* <span>{t('company.about.title')}</span> */}
                            <span><Trans i18nKey="company.about.title" components={[<br className="u_mobile" />]} /></span>
                        </h1>
                    </div>
                    <div className="ab_title_content">
                        <PictureWebpComponent fb_img_1x={chairsensor_w_1x_png} fb_img_2x={chairsensor_w_2x_png} fb_img_3x={chairsensor_w_3x_png} alt_img_name="image-chairsensor"></PictureWebpComponent>
                        <div className="ab_text" {...animatedFadeInAbText}>
                            <p><Trans i18nKey="company.about.title_content" components={[<br />]} /></p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="content_wide ab_content_w_3">
                <div className="inner">
                    <h1 className="title" {...animatedFadeInTitle2}>
                        <span className="up">mission</span>
                        <span><Trans i18nKey="company.mission.title" components={[<br className="u_mobile" />]} /></span>
                    </h1>
                    <p {...animatedFadeInMission}>
                        <PictureWebpComponent fb_img_1x={mission_w_1x_png} fb_img_2x={mission_w_2x_png} fb_img_3x={mission_w_3x_png} alt_img_name="image-about-mission"></PictureWebpComponent>
                    </p>
                </div>
            </section>
            <section className="content_wide ab_content_w_4 bg_wrap">
                <div className="bg--yellow2" {...animatedBackGrnYellow}></div>
                <div className="inner">
                    <h1 className="title" {...animatedFadeInTitle3}>
                        <span className="up">core value</span>
                        {t('company.cv.title')}
                    </h1>
                    <p className="title_content" {...animatedFadeInCvContent}><Trans i18nKey="company.cv.title_title" components={[<br className="u_mobile" />]} /></p>
                </div>
                <div>
                    <ScrollContainer vertical={false} component="ul" className="cv_scroll scroll-none">
                        <CvCard c_n={1} c_t="Positive Shift" c_c_code="company.cv.card1"></CvCard>
                        <CvCard c_n={2} c_t="Quality" c_c_code="company.cv.card2"></CvCard>
                        <CvCard c_n={3} c_t="Integrity" c_c_code="company.cv.card3"></CvCard>
                        <CvCard c_n={4} c_t="Performance" c_c_code="company.cv.card4"></CvCard>
                        <CvCard c_n={5} c_t="Leadership" c_c_code="company.cv.card5"></CvCard>
                        <CvCard c_n={6} c_t="Innovation" c_c_code="company.cv.card6"></CvCard>
                    </ScrollContainer>
                </div>
            </section>
            <section className="content_wide max_width">
                <div className="ab_content_5">
                    <div className=" bg--yellow5"></div>
                    <div className="ab_circle">
                        <div className=" bg--blue7"></div>
                        <div className="ab_inner">
                            <h1 className="title" {...animatedFadeInTitle4}>
                                <span className="up">history</span>
                                {t('company.history.title')}
                            </h1>
                            <div className="ab_history">
                            <div>
                                    <div className="ab_year lang_en_font">2023</div>
                                    <ul>
                                        <div {...animatedFadeInLeft23_2}>
                                            <span className="lang_en_font">06</span>
                                            <li>
                                                {t('company.history.2023.06.a')}
                                            </li>
                                        </div>
                                        <div {...animatedFadeInLeft23_1}>
                                            <span className="lang_en_font">05</span>
                                            <li>
                                                {t('company.history.2023.05.a')}
                                            </li>
                                        </div>
                                    </ul>
                                </div>
                                <div>
                                    <div className="ab_year lang_en_font">2022</div>
                                    <ul>
                                        <div {...animatedFadeInLeft22_3}>
                                            <span className="lang_en_font">09</span>
                                            <li>
                                                {t('company.history.2022.09.a')}
                                            </li>
                                        </div>
                                        <div {...animatedFadeInLeft22_2}>
                                            <span className="lang_en_font">05</span>
                                            <li>
                                                {t('company.history.2022.05.a')}
                                            </li>
                                        </div>
                                        <div {...animatedFadeInLeft22_1}>
                                            <span className="lang_en_font">04</span>
                                            <li>
                                                {t('company.history.2022.04.a')}
                                            </li>
                                        </div>
                                    </ul>
                                </div>
                                <div>
                                    <div className="ab_year lang_en_font">2021</div>
                                    <ul>
                                        <div {...animatedFadeInLeft20}>
                                            <span className="lang_en_font">08</span>
                                            <li>
                                                {t('company.history.2021.08.a')}
                                            </li>
                                        </div>
                                        <div {...animatedFadeInLeft19}>
                                            <span className="lang_en_font">06</span>
                                            <li>
                                                {t('company.history.2021.06.a')}
                                            </li>
                                        </div>
                                    </ul>
                                </div>
                                <div>
                                    <div className="ab_year lang_en_font">2020</div>
                                    <ul>
                                        <div {...animatedFadeInLeft18}>
                                            <span className="lang_en_font">11</span>
                                            <li>
                                                {t('company.history.2020.11.a')}
                                            </li>
                                        </div>
                                        <div {...animatedFadeInLeft17}>
                                            <span className="lang_en_font">06</span>
                                            <li>
                                                {t('company.history.2020.06.a')}
                                            </li>
                                        </div>
                                        <div {...animatedFadeInLeft16}>
                                            <span className="lang_en_font">06</span>
                                            <li>
                                                {t('company.history.2020.06.b')}
                                            </li>
                                        </div>
                                        <div {...animatedFadeInLeft15}>
                                            <span className="lang_en_font">06</span>
                                            <li>
                                                {t('company.history.2020.06.c')}
                                            </li>
                                        </div>
                                        <div {...animatedFadeInLeft14}>
                                            <span className="lang_en_font">04</span>
                                            <li>
                                                {t('company.history.2020.04.a')}
                                            </li>
                                        </div>
                                    </ul>
                                </div>
                                <div>
                                    <div className="ab_year lang_en_font">2019</div>
                                    <ul>
                                        <div {...animatedFadeInLeft13}>
                                            <span className="lang_en_font">12</span>
                                            <li>
                                                {t('company.history.2019.12.a')}
                                            </li>
                                        </div>
                                        <div {...animatedFadeInLeft12}>
                                            <span className="lang_en_font">12</span>
                                            <li>
                                                {t('company.history.2019.12.b')}
                                            </li>
                                        </div>
                                        <div {...animatedFadeInLeft11}>
                                            <span className="lang_en_font">04</span>
                                            <li>
                                                {t('company.history.2019.04.a')}
                                            </li>
                                        </div>
                                    </ul>
                                </div>
                                <div>
                                    <div className="ab_year lang_en_font">2018</div>
                                    <ul>
                                        <div {...animatedFadeInLeft10}>
                                            <span className="lang_en_font">02</span>
                                            <li>
                                                {t('company.history.2018.02.a')}
                                            </li>
                                        </div>
                                        <div {...animatedFadeInLeft9}>
                                            <span className="lang_en_font">01</span>
                                            <li>
                                                {t('company.history.2018.01.a')}
                                            </li>
                                        </div>
                                    </ul>
                                </div>
                                <div>
                                    <div className="ab_year lang_en_font">2017</div>
                                    <ul>
                                        <div {...animatedFadeInLeft8}>
                                            <span className="lang_en_font">10</span>
                                            <li>
                                                {t('company.history.2017.10.a')}
                                            </li>
                                        </div>
                                        <div {...animatedFadeInLeft7}>
                                            <span className="lang_en_font">09</span>
                                            <li>
                                                {t('company.history.2017.09.a')}
                                            </li>
                                        </div>
                                        <div {...animatedFadeInLeft6}>
                                            <span className="lang_en_font">04</span>
                                            <li>
                                                {t('company.history.2017.04.a')}
                                            </li>
                                        </div>
                                        <div {...animatedFadeInLeft5}>
                                            <span className="lang_en_font">01</span>
                                            <li>
                                                {t('company.history.2017.01.a')}
                                            </li>
                                        </div>
                                    </ul>
                                </div>
                                <div>
                                    <div className="ab_year lang_en_font">2016</div>
                                    <ul>
                                        <div {...animatedFadeInLeft4}>
                                            <span className="lang_en_font">12</span>
                                            <li>
                                                {t('company.history.2016.12.a')}
                                            </li>
                                        </div>
                                        <div {...animatedFadeInLeft3}>
                                            <span className="lang_en_font">11</span>
                                            <li>
                                                {t('company.history.2016.11.a')}
                                            </li>
                                        </div>
                                        <div {...animatedFadeInLeft2}>
                                            <span className="lang_en_font">05</span>
                                            <li>
                                                {t('company.history.2016.05.a')}
                                            </li>
                                        </div>
                                    </ul>
                                </div>
                                <div>
                                    <div className="ab_year lang_en_font">2015</div>
                                    <ul>
                                        <div {...animatedFadeInLeft1}>
                                            <span className="lang_en_font">10</span>
                                            <li>
                                                {t('company.history.2015.10.a')}
                                            </li>
                                        </div>
                                        <div {...animatedFadeInLeft0}>
                                            <span className="lang_en_font">08</span>
                                            <li>
                                                {t('company.history.2015.08.a')}
                                            </li>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    )
}

export default About
