import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next'
import ScrollContainer from 'react-indiana-drag-scroll'

import { TitleComponent } from '../TitleComponent'
import PictureWebpComponent from '../PictureWebpComponent'
import { useScrollFadeIn } from '../../utils/useScrollEvent';
import { openNewTabUrl } from '../../utils/commonUtils'
import { useInterval } from '../../utils/useInterval'
import './service.css';

import $ from "jquery";

// images
import hlogo from '../../images/logo-algohealth@3x.png';

import algohealth_w_3x_png from '../../images/illustration-algohealth-w@3x.png';
import algohealth_w_2x_png from '../../images/illustration-algohealth-w@2x.png';
import algohealth_w_1x_png from '../../images/illustration-algohealth-w.png';

import health1_w_3x_png from '../../images/illustration-health-1-w@3x.png';
import health1_w_2x_png from '../../images/illustration-health-1-w@2x.png';
import health1_w_1x_png from '../../images/illustration-health-1-w.png';

import health2_w_3x_png from '../../images/illustration-health-2-w@3x.png';
import health2_w_2x_png from '../../images/illustration-health-2-w@2x.png';
import health2_w_1x_png from '../../images/illustration-health-2-w.png';

import health3_w_3x_png from '../../images/illustration-health-3-w@3x.png';
import health3_w_2x_png from '../../images/illustration-health-3-w@2x.png';
import health3_w_1x_png from '../../images/illustration-health-3-w.png';

import health_ph_home_w_3x_png from '../../images/image-ph-health-home-w@3x.png';
import health_ph_home_w_2x_png from '../../images/image-ph-health-home-w@2x.png';
import health_ph_home_w_1x_png from '../../images/image-ph-health-home-w.png';
import health_ph_alarm_w_3x_png from '../../images/image-ph-health-alarm-w@3x.png';
import health_ph_alarm_w_2x_png from '../../images/image-ph-health-alarm-w@2x.png';
import health_ph_alarm_w_1x_png from '../../images/image-ph-health-alarm-w.png';
import health_ph_statistics_w_3x_png from '../../images/image-ph-health-statistics-w@3x.png';
import health_ph_statistics_w_2x_png from '../../images/image-ph-health-statistics-w@2x.png';
import health_ph_statistics_w_1x_png from '../../images/image-ph-health-statistics-w.png';
import health_ph_briefing_w_3x_png from '../../images/image-ph-health-briefing-w@3x.png';
import health_ph_briefing_w_2x_png from '../../images/image-ph-health-briefing-w@2x.png';
import health_ph_briefing_w_1x_png from '../../images/image-ph-health-briefing-w.png';





function AlgoHealth() {
    const [isActiveTab1, setIsActiveTab1] = useState(true);
    const [isActiveTab2, setIsActiveTab2] = useState(true);
    const [isActiveBtn, setIsActiveBtn] = useState(false);

    const { t } = useTranslation();

    // sec 1
    const animatedFadeInUpTitle1 = useScrollFadeIn('', 0.5, 0, 0.2, 0, 20);
    const animatedFadeInUpTitle_content_1 = useScrollFadeIn('', 0.5, 0.2, 0.2, 0, 20);
    const animatedFadeInUpTitleBtn1 = useScrollFadeIn('', 0.5, 0.4, 0.2, 0, 20);
    const animatedFadeInUpTitleBtn2 = useScrollFadeIn('', 0.5, 0.4, 0.2, 0, 20);
    // sec 2
    const animatedFadeInUpTitle2 = useScrollFadeIn('up', 0.5, 0);
    const animatedFadeInUpTitle_content_2 = useScrollFadeIn('up', 0.5, 0.2);
    const animatedFadeInUpCard1 = useScrollFadeIn('', 1, 0, 0.2, 0, 40);
    const animatedFadeInUpCard2 = useScrollFadeIn('', 1, window.matchMedia('(min-width: 960px)').matches ? 0.2 : 0, 0.2, 0, 40);
    const animatedFadeInUpCard3 = useScrollFadeIn('', 1, window.matchMedia('(min-width: 960px)').matches ? 0.4 : 0, 0.2, 0, 40);
    // sec 3
    const animatedFadeInUpTitle3 = useScrollFadeIn('up', 0.5, 0, 0.7);
    const animatedFadeInUpTab1 = useScrollFadeIn('up', 0.5, 0.2, 1);
    const animatedFadeInUpTab1_content1 = useScrollFadeIn('up', 0.5, 0);
    const animatedFadeInUpTab1_content2 = useScrollFadeIn('up', 0.5, 0);
    // sec 4
    const animatedFadeInUpTitle4 = useScrollFadeIn('up', 0.5, 0, 0.7);
    const animatedFadeInUpTab2 = useScrollFadeIn('up', 0.5, 0.2, 1);
    const animatedFadeInUpTab2_content1 = useScrollFadeIn('up', 0.5, 0);
    const animatedFadeInUpTab2_content2 = useScrollFadeIn('up', 0.5, 0);

    const onClickUnlimitScroll = () => {
        // console.log('UL auto scrolling start.....')
        const q_ele = $('.indiana-scroll-container');
        // 6개 li 총 width + a (마진과 보더로 인한 약간의 차이 포함)
        const init_left = window.matchMedia('(min-width: 960px)').matches ? 1830 : 1395;
        if (q_ele) {
            // console.log({q_ele})
            // console.log(q_ele.width(), q_ele.innerWidth())
            const ul_width = q_ele.width()
            const li_width = q_ele.children().first().outerWidth(true)
            if (ul_width && li_width) {
                // console.log('li_width : ' ,li_width, q_ele.children().length, li_width * (q_ele.children().length-1))
                if (ul_width < li_width * (q_ele.children().length - 1)) {
                    const scroll_width = (li_width || 0) < 250 ? 232 : 306;
                    // console.log({scroll_width})
                    q_ele.animate({
                        scrollLeft: init_left + scroll_width
                    }, 300, function () {
                        q_ele.scrollLeft(init_left);
                        q_ele.append(q_ele.children().first());
                    });
                } else {
                    console.log('do NOT auto scroll cause UL width')
                }
            }

            // fade out하여 사라지기
            // console.log('child : ', q_ele.children());
            // q_ele.children().first().fadeOut(1000, function(){
            //     $(this).css({display:'inline-block'})
            //     q_ele.append($(this));
            // })
        }
    }

    useEffect(() => {
        const q_ele = $('.indiana-scroll-container');
        const init_left = window.matchMedia('(min-width: 960px)').matches ? 1830 : 1395;
        // 기존 갯수보다 2배 더 많이 li를 만들고 중간으로 위치이동
        const rv_html = q_ele.html()
        q_ele.append(rv_html);
        q_ele.append(rv_html);
        q_ele.scrollLeft(init_left);

        // const scroll_timer = setInterval(() => {
        //     // console.log("setTimeout")
        //     onClickUnlimitScroll();
        // }, 3000);
        // return () => {
        //     // console.log("clearTimeout")
        //     clearInterval(scroll_timer);
        // }
    }, [])

    useInterval(() => {
        onClickUnlimitScroll();
    }, 3000);

    return (
        <section className="container service s_health">
            <TitleComponent title={t('header.nav.service.algohealth')} />
            <section className="content s_content_1">
                <div className="inner">
                    <div className="h_inner_1">
                        <h1 className="title"  {...animatedFadeInUpTitle1}>
                            <img src={hlogo} alt="logo" />
                            <Trans i18nKey="service.algohealth.intro.title" components={[<br />, <em className="health_line health_color"></em>, <em className="health_line"></em>]} />
                        </h1>
                        <p className="title_content" {...animatedFadeInUpTitle_content_1}>
                            <Trans i18nKey="service.algohealth.intro.title_content" components={[]} />
                        </p>
                        <div className="s_main_img">
                            <PictureWebpComponent fb_img_1x={algohealth_w_1x_png} fb_img_2x={algohealth_w_2x_png} fb_img_3x={algohealth_w_3x_png} fb_alt="algohealth" alt_img_name="illustration-algohealth"></PictureWebpComponent>
                        </div>
                        <div className="down-btn u_mobile" {...animatedFadeInUpTitleBtn1}>
                            <a onClick={() => { openNewTabUrl("https://short.algorigo.com/S63akVZ") }}>{t('service.algohealth.intro.btn')}<span className="arrow-r"></span></a>
                        </div>
                        <div className="down-btn u_desk" {...animatedFadeInUpTitleBtn2}>
                            <a onClick={() => { setIsActiveBtn(!isActiveBtn) }}>{t('service.algohealth.intro.btn')}<span className={`${isActiveBtn ? 'arrow-t' : 'arrow-b'}`}></span>
                                <ul className={`${isActiveBtn ? 'd_open' : 'd_close'}`}>
                                    <li className="lang_en_font" onClick={() => { openNewTabUrl("https://play.google.com/store/apps/details?id=com.algorigo.newsmartchair") }}>Google Play</li>
                                    <li className="lang_en_font" onClick={() => { openNewTabUrl("https://apps.apple.com/kr/app/%EC%95%8C%EA%B3%A0-%ED%97%AC%EC%8A%A4-algo-health/id1539540230") }}>App Store</li>
                                </ul>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <section className="content_wide s_banner_wrap">
                <div className="s_banner">
                    <div className="s_banner_text">
                        <p className="s_banner_sub">{t('service.algohealth.banner1.title')}</p>
                        <p className="s_banner_main">{t('service.algohealth.banner1.title_content')}</p>
                    </div>
                    <div className="s_banner_btn">
                        <a href="https://smartstore.naver.com/algorigo" target="_black"><button>{t('service.algohealth.banner1.btn')}</button></a>
                    </div>
                </div>
            </section>
            <section className="content s_content_2">
                <div className="inner">
                    <h1 className="title" {...animatedFadeInUpTitle2}>{t('service.algohealth.feature.title')}</h1>
                    <p className="title_content" {...animatedFadeInUpTitle_content_2}> <Trans i18nKey="service.algohealth.feature.title_content" components={[<br className="u_desk" />]} /></p>
                    <div className="rect_box" {...animatedFadeInUpCard1}>
                        <PictureWebpComponent fb_img_1x={health1_w_1x_png} fb_img_2x={health1_w_2x_png} fb_img_3x={health1_w_3x_png} alt_img_name="illustration-health-1"></PictureWebpComponent>
                        {/* <img src={health1} alt="" /> */}
                        <p><Trans i18nKey="service.algohealth.feature.card1" components={[<br />]} /></p>
                    </div>
                    <div className="rect_box" {...animatedFadeInUpCard2}>
                        <PictureWebpComponent fb_img_1x={health2_w_1x_png} fb_img_2x={health2_w_2x_png} fb_img_3x={health2_w_3x_png} alt_img_name="illustration-health-2"></PictureWebpComponent>
                        {/* <img src={health2} alt="" /> */}
                        <p><Trans i18nKey="service.algohealth.feature.card2" components={[<br />]} /></p>
                    </div>
                    <div className="rect_box" {...animatedFadeInUpCard3}>
                        <PictureWebpComponent fb_img_1x={health3_w_1x_png} fb_img_2x={health3_w_2x_png} fb_img_3x={health3_w_3x_png} alt_img_name="illustration-health-3"></PictureWebpComponent>
                        {/* <img src={health3} alt="" /> */}
                        <p>
                            <Trans i18nKey="service.algohealth.feature.card3" components={[<br />, <span className="d_block"></span>, <span className="d_inline-b"></span>]} />
                            {/* 비교 통계로 <br className="u_mobile" />알아보는 건강 순위
                            <span>(지원예정)</span> */}
                        </p>
                    </div>
                </div>
            </section>
            <section className={`content s_content_3`}>
                <div className="inner">
                    <div className="h_inner_3">
                        {/* <h1 className="title"><Trans i18nKey="service.algohealth.detail1.title" components={[<br />, <em className="health_line"></em>]} /></h1> */}
                        <h1 className="title"{...animatedFadeInUpTitle3}><Trans i18nKey="service.algohealth.detail1.title" components={[<br />, <em className="health_line"></em>, <br className="u_mobile" />]} /></h1>
                        <ul className="tab tab_under" {...animatedFadeInUpTab1}>
                            <li className={isActiveTab1 ? "active" : ""} onClick={() => setIsActiveTab1(true)}>{t('service.algohealth.detail1.category1')}</li>
                            <li className={isActiveTab1 ? "" : "active"} onClick={() => setIsActiveTab1(false)}>{t('service.algohealth.detail1.category2')}</li>
                        </ul>
                        <div className={`${isActiveTab1 ? "h_img_left" : "no_display"}`}>
                            <PictureWebpComponent fb_img_1x={health_ph_home_w_1x_png} fb_img_2x={health_ph_home_w_2x_png} fb_img_3x={health_ph_home_w_3x_png} alt_img_name="image-ph-health-home"></PictureWebpComponent>
                        </div>
                        {/* tabs menu 1 */}
                        <div className={`h_tab_text ${isActiveTab1 ? '' : 'no_display'}`} {...animatedFadeInUpTab1_content1}>
                            <p className="title_content">{t('service.algohealth.detail1.category1.content1')}</p>
                            <p className="title_content">{t('service.algohealth.detail1.category1.content2')}</p>
                        </div>
                        <div className={`${!isActiveTab1 ? "h_img_left" : "no_display"}`}>
                            <PictureWebpComponent fb_img_1x={health_ph_alarm_w_1x_png} fb_img_2x={health_ph_alarm_w_2x_png} fb_img_3x={health_ph_alarm_w_3x_png} alt_img_name="image-ph-health-alarm"></PictureWebpComponent>
                        </div>
                        <div className={`h_tab_text ${!isActiveTab1 ? '' : 'no_display'}`} {...animatedFadeInUpTab1_content2}>
                            <p className="title_content">{t('service.algohealth.detail1.category2.content1')}</p>
                            <p className="title_content">{t('service.algohealth.detail1.category2.content2')}</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className={`content s_content_4`}>
                <div className="inner">
                    <div className="h_inner_4">
                        <h1 className="title"{...animatedFadeInUpTitle4}><Trans i18nKey="service.algohealth.detail2.title" components={[<br />, <em className="health_line"></em>, <br className="u_mobile" />]} /></h1>
                        <ul className="tab tab_under" {...animatedFadeInUpTab2}>
                            <li className={isActiveTab2 ? "active" : ""} onClick={() => setIsActiveTab2(true)}>{t('service.algohealth.detail2.category1')}</li>
                            <li className={isActiveTab2 ? "" : "active"} onClick={() => setIsActiveTab2(false)}>{t('service.algohealth.detail2.category2')}</li>
                        </ul>
                        {/* tab menu 2 */}
                        <div className={`${isActiveTab2 ? "h_img_right" : "no_display"}`}>
                            <PictureWebpComponent fb_img_1x={health_ph_statistics_w_1x_png} fb_img_2x={health_ph_statistics_w_2x_png} fb_img_3x={health_ph_statistics_w_3x_png} alt_img_name="image-ph-health-statistics"></PictureWebpComponent>
                        </div>
                        <div className={`h_tab_text ${isActiveTab2 ? '' : 'no_display'}`} {...animatedFadeInUpTab2_content1}>
                            <p className="title_content">{t('service.algohealth.detail2.category1.content1')}</p>
                            <p className="title_content">{t('service.algohealth.detail2.category1.content2')}</p>
                        </div>
                        <div className={`${!isActiveTab2 ? "h_img_right" : "no_display"}`}>
                            <PictureWebpComponent fb_img_1x={health_ph_briefing_w_1x_png} fb_img_2x={health_ph_briefing_w_2x_png} fb_img_3x={health_ph_briefing_w_3x_png} alt_img_name="image-ph-health-briefing"></PictureWebpComponent>
                        </div>
                        <div className={`h_tab_text ${!isActiveTab2 ? '' : 'no_display'}`} {...animatedFadeInUpTab2_content2}>
                            <p className="title_content">{t('service.algohealth.detail2.category2.content1')}</p>
                            <p className="title_content">{t('service.algohealth.detail2.category2.content2')}</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="content_wide h_review_wrap">
                <div className="inner bg_wrap">
                    <div className="bg--blue7 light"></div>
                    {/* <h1 className="title">{t('service.algohealth.review.title')}</h1> */}
                    <h1 className="title"><Trans i18nKey="service.algohealth.review.title" components={[<span></span>]} /></h1>
                    {/* <h1 className="title"><span>실</span><span>제</span>&nbsp;<span>사</span><span>용</span><span>후</span><span>기</span><span>를</span>&nbsp;<span>확</span><span>인</span><span>하</span><span>세</span><span>요</span><span>!</span></h1> */}
                    <p className="title_content">{t('service.algohealth.review.title_content')}</p>
                </div>
                <ScrollContainer vertical={false} component="ul" className="h_review scroll-none">
                    <li>
                        <span className="h_star-rating">
                            <span></span>
                        </span>
                        {/* <p><b>제 자세에 맞게 스마트어플로 알려줘서 신기</b>하고<br />너무 좋아요~ <br />부모님도 사드렸어요~</p> */}
                        <p><Trans i18nKey="service.algohealth.review.card1" components={[<b></b>]} /></p>
                        <span className="h_review_info lang_en_font">20.09.17 ddes****</span>
                    </li>
                    <li>
                        <span className="h_star-rating">
                            <span></span>
                        </span>
                        {/* <p>의자를 기대도 어느정도<br />눕혀질만큼 또 눕힐 수 있어서<br />좋았고 <b>어플을 통해서 자세<br />문제점이나 공부시간 등 체크<br />되는게 아주 큰 도움</b>이 되었<br />습니다. 너무너무 좋은듯</p> */}
                        <p><Trans i18nKey="service.algohealth.review.card2" components={[<b></b>]} /></p>
                        <span className="h_review_info lang_en_font">20.09.06 elil****
                            <span className="h_review_month">{t('service.algohealth.review.1month')}</span>
                        </span>
                    </li>
                    <li>
                        <span className="h_star-rating">
                            <span></span>
                        </span>
                        {/* <p>요즘 <b>코로나로 재택 근무중인데 평소 앉은 자세나 습관도 <br />알 수 있고 너무 좋은 것 같아요.</b> 특히 앉는 시간이 많은 학생들이나 직장인들에게 강추네요.</p> */}
                        <p><Trans i18nKey="service.algohealth.review.card3" components={[<b></b>]} /></p>
                        <span className="h_review_info lang_en_font">20.09.02 ospr****</span>
                    </li>
                    <li>
                        <span className="h_star-rating">
                            <span></span>
                        </span>
                        {/* <p>평소 의자에 오래 앉아있어서 자세 교정을 위해 구매하였습<br />니다. 그 전엔 <b>공부를 하면 <br />따로 타이머로 체크하는게 <br />번거로웠는데 자동으로 해주<br />니 너무 편리</b>해졌어요.</p> */}
                        <p><Trans i18nKey="service.algohealth.review.card4" components={[<b></b>]} /></p>
                        <span className="h_review_info lang_en_font">20.09.03 lhy****</span>
                    </li>
                    <li>
                        <span className="h_star-rating">
                            <span></span>
                        </span>
                        {/* <p>자세교정에 큰도움이 <br />되고있습니다</p> */}
                        <p><Trans i18nKey="service.algohealth.review.card5" components={[<b></b>]} /></p>
                        <span className="h_review_info lang_en_font">20.09.17 elil****
                            <span className="h_review_month">{t('service.algohealth.review.1month')}</span>
                        </span>
                    </li>
                    <li>
                        <span className="h_star-rating">
                            <span></span>
                        </span>
                        {/* <p>안좋은 자세습관을 고치고 싶<br />었는데 정확한 데어터가 없어 <br />고민이었습니다. <b>자세분석도 <br />정확하고 자세교정에 상당한 <br />도움</b>이됩니다. 허리나 목 척추<br />가 안좋으신 분들께 강추!</p> */}
                        <p><Trans i18nKey="service.algohealth.review.card6" components={[<b></b>]} /></p>
                        <span className="h_review_info lang_en_font">20.09.17 ropo****</span>
                    </li>
                </ScrollContainer>
                {/* <div className="h_star_content"> */}
                {/* </div> */}

            </section>
            <section className="content_wide s_qna">
                <div className="s_qna_content">
                    <p><Trans i18nKey="service.algohealth.banner2.title" components={[<b></b>]} /></p>
                    <Link to="/contact/inquire">
                        <button>{t('service.algohealth.banner2.btn')}</button>
                    </Link>
                </div>
            </section>
        </section>
    )
}

export default AlgoHealth
