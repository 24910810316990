import { createReducer } from 'typesafe-actions';
import { RecruitState, RecruitAction } from './types';
import { getRecruitsAsync, } from './actions';
import { asyncState, createAsyncReducer, transformToArray } from '../../lib/reducerUtils';

const initialState: RecruitState = {
    recruitList: asyncState.initial()
};

// const recruit = createReducer<RecruitState, RecruitAction>(initialState, {
//     [GET_RECRUITS]: state => ({
//         ...state,
//         recruitList: asyncState.load()
//     }),
//     [GET_RECRUITS_SUCCESS]: (state, action) => ({
//         ...state,
//         recruitList: asyncState.success(action.payload)
//     }),
//     [GET_RECRUITS_ERROR]: (state, action) => ({
//         ...state,
//         recruitList: asyncState.error(action.payload)
//     })
// });

const recruit = createReducer<RecruitState, RecruitAction>(initialState).handleAction(
    transformToArray(getRecruitsAsync),
    createAsyncReducer(getRecruitsAsync, 'recruitList')
);

export default recruit;