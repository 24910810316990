import axios from 'axios';
import config from '../config'
// import { NewsType } from '../types/NewsType';
// import { RecruitType } from '../types/RecruitType';

axios.defaults.baseURL =
    process.env.NODE_ENV === 'development' ? '/' : config.baseURL; //Production (stage or service)

const apiHeader = {
    headers: {
    }
}

export const getRecruits = async () => {
    const response = await axios.get(
        '/api/algorigo/recruits',
        apiHeader
    );
    return response.data;
}

export const getNewsList = async (data: any) => {
    // console.log(data)
    const response = await axios.get(
        // TODO : keyword 입력
        `/api/algorigo/news_list?page=${data.page}&item_per_page=${data.itemPerPage}&sort-by=[${data.newsId}]&sort-desc=[${data.desc}]&keyword=${data.keyword}`,
        apiHeader
    );
    return response.data;
}

export const postContact = async (body: FormData) => {
    const response = await axios.post(
        '/api/algorigo/contact',
        body,
        apiHeader,
    );
    return response.data;
};