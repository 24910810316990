import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next'
import { NewsType } from '../../types/NewsType';
import { openNewTabUrl } from '../../utils/commonUtils';
import { TitleComponent } from '../TitleComponent'
import './news.css';
import prevBtn from '../../images/icon-arrow-left@3x.png';
import nextBtn from '../../images/icon-arrow-right@3x.png';
import { useScrollFadeIn } from '../../utils/useScrollEvent';


function News(props: { newsData: NewsType, loading: boolean, setNowPageNum: any, nowPageNum: number, newsKeyword: string, setNewsKeyword: any }) {
    const { newsData, loading, setNowPageNum, nowPageNum, newsKeyword, setNewsKeyword } = props;
    const { t } = useTranslation();

    const animatedFadeInTitle1 = useScrollFadeIn('up', 0.5, 0);
    const animatedFadeInTitleContent1 = useScrollFadeIn('up', 0.8, 0.5);

    let newsList: any = [];
    let totalPageNum: number = 0;

    if (newsData) {
        newsList = newsData.list;
        totalPageNum = newsData.total_page_num;
    }

    const paginate = [...Array(totalPageNum)].map((list, idx) => {
        return (
            <li
                key={idx}
                className={nowPageNum === (idx + 1) ? 'active' : ''}
                onClick={() => { setNowPageNum(idx + 1) }}
            >
                {idx + 1}
            </li>
        )
    })

    return (
        <section className="container news_cnt">
            <TitleComponent title={t('header.nav.news')} />
            <div className="content">
                <div className="inner">
                    <div className="news_tit bg_wrap" {...animatedFadeInTitle1}>
                        <div className="bg--yellow7 light"></div>
                        <h1 className="title">{t('news.title')}</h1>
                        <p className="title_content" {...animatedFadeInTitleContent1}>{<Trans i18nKey="news.title_content" components={[<br className="u_desk" />]} />}</p>
                    </div>
                    <ul className="news_cate scroll-none">
                        <li className={newsKeyword === '' ? 'active' : ''} onClick={() => setNewsKeyword('')}>{t('news.category.all')}</li>
                        <li className={newsKeyword === 'news' ? 'active' : ''} onClick={() => setNewsKeyword('news')}>{t('news.category.press')}</li>
                        <li className={newsKeyword === 'blog' ? 'active' : ''} onClick={() => setNewsKeyword('blog')}>{t('news.category.blog')}</li>
                        <li className={newsKeyword === 'store' ? 'active' : ''} onClick={() => setNewsKeyword('store')}>{t('news.category.store')}</li>
                        <li className={newsKeyword === 'noti' ? 'active' : ''} onClick={() => setNewsKeyword('noti')}>{t('news.category.noti')}</li>
                    </ul>
                    <div className="news_ctt">
                        {loading ? (
                            <div className="news_loading">{t('news.loading.message')}</div>
                        ) : (
                            <ul className="news-group scroll-none">
                                <div className="news-group-sub">
                                    {newsList.map((n: NewsType) => {
                                        return (
                                            <li key={n.news_id} onClick={() => openNewTabUrl(n.ref_url || '')} className="lang_ko_font">
                                                <div className="news_img">
                                                    <img src={n.image_src || ''} alt="" />
                                                </div>
                                                <div className="news_txt_cnt">
                                                    <div className="news_type lang_en_font">
                                                        {n.type}
                                                    </div>
                                                    <div className="news_title">
                                                        {n.title}
                                                    </div>
                                                    <div className="news_title_content">
                                                        {n.contents}
                                                    </div>
                                                    <div className="news_date">
                                                        <span className="lang_en_font">{n.publish_date}</span> {n.publish_name}
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })}
                                </div>
                            </ul>
                        )}
                    </div>
                    <ul className={loading ? 'no_display' : `news_paginate lang_en_font`}>
                        <li className="prev_btn"><img src={prevBtn} onClick={() => setNowPageNum(1)} /></li>
                        {paginate}
                        <li className="next_btn"><img src={nextBtn} onClick={() => setNowPageNum(totalPageNum)} /></li>
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default News
