import React, { useState, Dispatch, SetStateAction } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

import './menu.css';

// images
import logo from '../../images/logo-algorigo@3x.png';

const MenuItem = ({ location, children, to, setIsOpened }: { location: string, children: HTMLElement, to: string, setIsOpened: React.Dispatch<React.SetStateAction<boolean>> }) => (
    <li className={`${location === to ? 'active' : ''}`}>
        <Link
            to={to}
            onClick={() => setIsOpened(false)}
        >
            {children}
        </Link>
    </li>
);

function Menu({ htmlLang, setHtmlLang }: { htmlLang: string, setHtmlLang: Dispatch<SetStateAction<string>> }) {
    const { t, i18n } = useTranslation();
    const changeLang = (lang: string) => {
        // ko | en
        if (lang === 'ko') {
            document.documentElement.lang = 'ko';
            setHtmlLang('ko');
        } else {
            document.documentElement.lang = 'en';
            setHtmlLang('en');
        }
        i18n.changeLanguage(lang);
    };

    const [isOpened, setIsOpened] = useState(false);
    const [serviceTab, setServiceTab] = useState(false);
    const [contactTab, setContactTab] = useState(false);

    const onToggleMenuBtn = () => {
        setIsOpened(!isOpened);
    }

    const onToggleServiceTab = () => {
        setServiceTab(!serviceTab);
    }

    const location = useLocation();

    return (
        <header className="header">
            <div className="inner">
                <h1 className="logo"><Link to="/"><img src={logo} alt="logo" /></Link></h1>
                <div className={`${isOpened ? 'dimmed' : 'dimmed--none'}`} onClick={onToggleMenuBtn}></div>
                <div className="menu">
                    <nav className={`gnb ${isOpened ? 'open' : 'close'}`}>
                        <ul className="m_list">
                            <MenuItem location={location.pathname} to="/about" setIsOpened={setIsOpened}>{t('header.nav.company_intro')}</MenuItem>
                            <li className="m_service" onClick={onToggleServiceTab}>
                                <span onClick={() => { setServiceTab(true) }}>{t('header.nav.service')}</span>
                                <span className={`${serviceTab ? 'arrow-t' : `arrow-b`}`}></span>
                                <ul className={`m_sub sub_service ${serviceTab ? 'm_open' : 'w_open'}`}>
                                    <MenuItem location={location.pathname} to="/services/algostudy" setIsOpened={setIsOpened}>{t('header.nav.service.algostudy')}</MenuItem>
                                    <MenuItem location={location.pathname} to="/services/algowork" setIsOpened={setIsOpened}>{t('header.nav.service.algowork')}</MenuItem>
                                    <MenuItem location={location.pathname} to="/services/algohealth" setIsOpened={setIsOpened}>{t('header.nav.service.algohealth')}</MenuItem>
                                </ul>
                            </li>
                            <MenuItem location={location.pathname} to="/product" setIsOpened={setIsOpened}>{t('header.nav.product')}</MenuItem>
                            {/* <MenuItem location={location.pathname} to="/contact" setIsOpened={setIsOpened}>{t('header.nav.contact')}</MenuItem>
                            <MenuItem location={location.pathname} to="/recruit" setIsOpened={setIsOpened}>{t('header.nav.recruit')}</MenuItem> */}
                            <MenuItem location={location.pathname} to="/news" setIsOpened={setIsOpened}>{t('header.nav.news')}</MenuItem>
                            {/* <MenuItem location={location.pathname} to="/contact" setIsOpened={setIsOpened}>{t('header.nav.contact')}</MenuItem> */}
                            <li className="m_service" onClick={() => { setContactTab(!contactTab) }}>
                                <span onClick={() => { setContactTab(true) }}>{t('header.nav.contact')}</span>
                                <span className={`${contactTab ? 'arrow-t' : `arrow-b`}`}></span>
                                <ul className={`m_sub sub_contact ${contactTab ? 'm_open' : 'w_open'}`}>
                                    <MenuItem location={location.pathname} to="/contact/recruit" setIsOpened={setIsOpened}>{t('header.nav.contact.recruit')}</MenuItem>
                                    <MenuItem location={location.pathname} to="/contact/inquire" setIsOpened={setIsOpened}>{t('header.nav.contact.inquire')}</MenuItem>

                                </ul>
                            </li>
                        </ul>
                        <ul className="m_lang lang_en_font">
                            <li className={`l_kr ${htmlLang === 'ko' ? 'active' : ''}`} onClick={() => { changeLang('ko'); setIsOpened(false); }}>KR</li>
                            <li className={`l_eng ${htmlLang === 'en' ? 'active' : ''}`} onClick={() => { changeLang('en'); setIsOpened(false) }}>ENG</li>
                        </ul>
                    </nav>
                    <span className={`menu-toggle-btn ${isOpened ? 'active-1' : ""}`} onClick={onToggleMenuBtn}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </span>
                </div>
            </div>
        </header>
    )
}

export default Menu;

