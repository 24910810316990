import React from 'react';
import Helmet from 'react-helmet';

export type initTitleType = Readonly<{
    title?: string
}>;
const initTitle: initTitleType = {
    title: 'Toward Data-Driven Lifestyle Management'
}

const TitleComponent = ({ title }: initTitleType = initTitle) => {
    const defaultTitle = 'Toward Data-Driven Lifestyle Management';
    return (
        <Helmet>
            <title>Algorigo Inc - {title ? title : defaultTitle}</title>
        </Helmet>
    );
};

export { TitleComponent };