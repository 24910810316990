import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next'

import { TitleComponent } from '../TitleComponent'
import PictureWebpComponent from '../PictureWebpComponent'
import { useScrollFadeIn } from '../../utils/useScrollEvent';
import { openNewTabUrl } from '../../utils/commonUtils'

import './service.css';

// images
import slogo from '../../images/logo-algostudy-n@3x.png';

import algostudy_w_3x_png from '../../images/illustration-algostudy-n-w@3x.png';
import algostudy_w_2x_png from '../../images/illustration-algostudy-n-w@2x.png';
import algostudy_w_1x_png from '../../images/illustration-algostudy-n-w.png';

import study1_w_3x_png from '../../images/illustration-study-1-n-w@3x.png';
import study1_w_2x_png from '../../images/illustration-study-1-n-w@2x.png';
import study1_w_1x_png from '../../images/illustration-study-1-n-w.png';

import study2_w_3x_png from '../../images/illustration-study-2-n-w@3x.png';
import study2_w_2x_png from '../../images/illustration-study-2-n-w@2x.png';
import study2_w_1x_png from '../../images/illustration-study-2-n-w.png';

import study3_w_3x_png from '../../images/illustration-study-3-n-w@3x.png';
import study3_w_2x_png from '../../images/illustration-study-3-n-w@2x.png';
import study3_w_1x_png from '../../images/illustration-study-3-n-w.png';

import study_ph_main_w_3x_png from '../../images/image-ph-study-main-1-n-w@3x.png';
import study_ph_main_w_2x_png from '../../images/image-ph-study-main-1-n-w@2x.png';
import study_ph_main_w_1x_png from '../../images/image-ph-study-main-1-n-w.png';
import study_ph_main_2_w_3x_png from '../../images/image-ph-study-main-2-n-w@3x.png';
import study_ph_main_2_w_2x_png from '../../images/image-ph-study-main-2-n-w@2x.png';
import study_ph_main_2_w_1x_png from '../../images/image-ph-study-main-2-n-w.png';
import study_ph_ranking_w_3x_png from '../../images/image-ph-study-ranking-n-w@3x.png';
import study_ph_ranking_w_2x_png from '../../images/image-ph-study-ranking-n-w@2x.png';
import study_ph_ranking_w_1x_png from '../../images/image-ph-study-ranking-n-w.png';
import study_ph_report_w_3x_png from '../../images/image-ph-study-report-n-w@3x.png';
import study_ph_report_w_2x_png from '../../images/image-ph-study-report-n-w@2x.png';
import study_ph_report_w_1x_png from '../../images/image-ph-study-report-n-w.png';
import study_ph_board_now_w_3x_png from '../../images/image-board-study-now-n-w@3x.png';
import study_ph_board_now_w_2x_png from '../../images/image-board-study-now-n-w@2x.png';
import study_ph_board_now_w_1x_png from '../../images/image-board-study-now-n-w.png';
import study_ph_board_ranking_w_3x_png from '../../images/image-board-study-ranking-n-w@3x.png';
import study_ph_board_ranking_w_2x_png from '../../images/image-board-study-ranking-n-w@2x.png';
import study_ph_board_ranking_w_1x_png from '../../images/image-board-study-ranking-n-w.png';
import study_ph_board_pyramid_w_3x_png from '../../images/image-board-study-pyramid-n-w@3x.png';
import study_ph_board_pyramid_w_2x_png from '../../images/image-board-study-pyramid-n-w@2x.png';
import study_ph_board_pyramid_w_1x_png from '../../images/image-board-study-pyramid-n-w.png';
import study_ph_board_pattern_w_3x_png from '../../images/image-board-study-pattern-n-w@3x.png';
import study_ph_board_pattern_w_2x_png from '../../images/image-board-study-pattern-n-w@2x.png';
import study_ph_board_pattern_w_1x_png from '../../images/image-board-study-pattern-n-w.png';


function AlgoStudy() {
    const [isActiveNavTabs, setIsActiveNavTabs] = useState<boolean>(true);
    const [isActiveTab1, setIsActiveTab1] = useState(true);
    const [isActiveBtn, setIsActiveBtn] = useState(false);

    const { t } = useTranslation();

    // sec 1
    const animatedFadeInUpTitle1 = useScrollFadeIn('', 0.5, 0, 0.2, 0, 20);
    const animatedFadeInUpTitle_content_1 = useScrollFadeIn('', 0.5, 0.2, 0.2, 0, 20);
    const animatedFadeInUpTitleBtn1 = useScrollFadeIn('', 0.5, 0.4, 0.2, 0, 20);
    const animatedFadeInUpTitleBtn2 = useScrollFadeIn('', 0.5, 0.4, 0.2, 0, 20);
    // sec 2
    const animatedFadeInUpTitle2 = useScrollFadeIn('up', 0.5, 0);
    const animatedFadeInUpTitle_content_2 = useScrollFadeIn('up', 0.5, 0.2);
    const animatedFadeInUpCard1 = useScrollFadeIn('', 1, 0, 0.2, 0, 40);
    const animatedFadeInUpCard2 = useScrollFadeIn('', 1, window.matchMedia('(min-width: 960px)').matches ? 0.2 : 0, 0.2, 0, 40);
    const animatedFadeInUpCard3 = useScrollFadeIn('', 1, window.matchMedia('(min-width: 960px)').matches ? 0.4 : 0, 0.2, 0, 40);
    // sec 3 - tab1
    const animatedFadeInUpTitle3 = useScrollFadeIn('up', 0.5, 0, 0.7);
    const animatedFadeInUpTab1 = useScrollFadeIn('up', 0.5, 0.2, 1);
    const animatedFadeInUpTab1_content1 = useScrollFadeIn('up', 0.5, 0, 0.2);
    const animatedFadeInUpTab1_content2 = useScrollFadeIn('up', 0.5, 0, 0.2);
    // sec 3 - tab2
    // const animatedFadeInUpTitle4 = useScrollFadeIn('up', 0.5, 0.3);
    // const animatedFadeInUpTab2 = useScrollFadeIn('up', 0.5, 0.5);
    // const animatedFadeInUpTab2_content1 = useScrollFadeIn('up', 0.5, window.matchMedia('(min-width: 960px)').matches ? 0.5 : 0);
    // const animatedFadeInUpTab2_content2 = useScrollFadeIn('up', 0.5, 0);
    // sec 5
    const animatedFadeInUpTitle5 = useScrollFadeIn('up', 0.5, 0, 0.7);
    const animatedFadeInUpTitle_content_5 = useScrollFadeIn('up', 0.5, 0, 0.7, 0, 20);
    // sec 6
    const animatedFadeInUpTitle6 = useScrollFadeIn('up', 0.5, 0, 0.7);
    const animatedFadeInUpTitle_content_6 = useScrollFadeIn('up', 0.5, 0, 0.7, 0, 20);
    // sec 7
    const animatedFadeInUpTitle7 = useScrollFadeIn('up', 0.5, 0, 0.7);
    const animatedFadeInUpTitle_content_7 = useScrollFadeIn('up', 0.5, 0, 0.7, 0, 20);

    const animatedFadeInUpTitle8 = useScrollFadeIn('up', 0.5, 0, 0.7);
    const animatedFadeInUpTitle_content_8 = useScrollFadeIn('up', 0.5, 0, 0.7, 0, 20);

    const animatedFadeInUpTitle9 = useScrollFadeIn('up', 0.5, 0, 0.7);
    const animatedFadeInUpTitle_content_9 = useScrollFadeIn('up', 0.5, 0, 0.7, 0, 20);

    const animatedFadeInUpTitle10 = useScrollFadeIn('up', 0.5, 0, 0.7);
    const animatedFadeInUpTitle_content_10 = useScrollFadeIn('up', 0.5, 0, 0.7, 0, 20);


    return (
        <section className="container service s_study">
            <TitleComponent title={t('header.nav.service.algostudy')} />
            <section className="content s_content_1">
                <div className="inner">
                    <div className="h_inner_1">
                        <h1 className="title" {...animatedFadeInUpTitle1}>
                            <img src={slogo} alt="logo" />
                            <Trans i18nKey="service.algostudy.intro.title" components={[<br />, <em className="study_line study_color"></em>, <em className="study_line"></em>]} />
                        </h1>
                        <p className="title_content" {...animatedFadeInUpTitle_content_1}><Trans i18nKey="service.algostudy.intro.title_content" components={[<br className="u_desk" />]} />
                            {/* <span className="s_comingsoon lang_en_font">coming soon!</span> */}
                        </p>
                        <div className="s_main_img">
                            <PictureWebpComponent fb_img_1x={algostudy_w_1x_png} fb_img_2x={algostudy_w_2x_png} fb_img_3x={algostudy_w_3x_png} fb_alt="algostudy" alt_img_name="illustration-algostudy-n"></PictureWebpComponent>
                        </div>
                        {/* <div className="down-btn" {...animatedFadeInUpTitleBtn1}>
                            <button><Trans i18nKey="service.algostudy.intro.btn1" components={[<span className="lang_en_font"></span>, <br />]} /></button>
                        </div> */}
                        <div className="down-btn u_mobile" {...animatedFadeInUpTitleBtn1}>
                            <a onClick={() => { openNewTabUrl("https://short.algorigo.com/AGXqhRXd1") }}>{t('service.algohealth.intro.btn')}<span className="arrow-r"></span></a>
                        </div>
                        <div className="down-btn u_desk" {...animatedFadeInUpTitleBtn2}>
                            <a onClick={() => { setIsActiveBtn(!isActiveBtn) }}>{t('service.algohealth.intro.btn')}<span className={`${isActiveBtn ? 'arrow-t' : 'arrow-b'}`}></span>
                                <ul className={`${isActiveBtn ? 'd_open' : 'd_close'}`}>
                                    <li className="lang_en_font" onClick={() => { openNewTabUrl("https://play.google.com/store/apps/details?id=com.algorigo.algo_study") }}>Google Play</li>
                                    <li className="lang_en_font" onClick={() => { openNewTabUrl("https://apps.apple.com/kr/app/%EC%95%8C%EA%B3%A0%EC%8A%A4%ED%84%B0%EB%94%94-algostudy/id1596889993") }}>App Store</li>
                                </ul>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <section className="content_wide s_banner_wrap">
                <div className="s_banner">
                    <div className="s_banner_text">
                        <p className="s_banner_sub">{t('service.algostudy.banner1.title')}</p>
                        <p className="s_banner_main"><Trans i18nKey="service.algostudy.banner1.title_content" components={[<br />]} /></p>
                    </div>
                    <div className="s_banner_btn">
                        <a href="https://smartstore.naver.com/algorigo/products/6092958840" target="_black" rel="noreferrer"><button>{t('service.algostudy.banner1.btn')}</button></a>
                    </div>
                </div>
            </section>
            <section className="content s_content_2">
                <div className="inner">
                    <h1 className="title" {...animatedFadeInUpTitle2}><Trans i18nKey="service.algostudy.feature.title" components={[<br />, <br className="u_mobile" />]} /></h1>
                    <p className="title_content" {...animatedFadeInUpTitle_content_2}><Trans i18nKey="service.algostudy.feature.title_content" components={[<br />]} /></p>
                    <div className="rect_box" {...animatedFadeInUpCard1}>
                        <PictureWebpComponent fb_img_1x={study1_w_1x_png} fb_img_2x={study1_w_2x_png} fb_img_3x={study1_w_3x_png} alt_img_name="illustration-study-1-n"></PictureWebpComponent>
                        <p><Trans i18nKey="service.algostudy.feature.card1" components={[<br />]} /></p>
                    </div>
                    <div className="rect_box" {...animatedFadeInUpCard2}>
                        <PictureWebpComponent fb_img_1x={study2_w_1x_png} fb_img_2x={study2_w_2x_png} fb_img_3x={study2_w_3x_png} alt_img_name="illustration-study-2-n"></PictureWebpComponent>
                        <p><Trans i18nKey="service.algostudy.feature.card2" components={[<br />]} /></p>
                    </div>
                    <div className="rect_box" {...animatedFadeInUpCard3}>
                        <PictureWebpComponent fb_img_1x={study3_w_1x_png} fb_img_2x={study3_w_2x_png} fb_img_3x={study3_w_3x_png} alt_img_name="illustration-study-3-n"></PictureWebpComponent>
                        <p><Trans i18nKey="service.algostudy.feature.card3" components={[<br />]} /></p>
                    </div>
                </div>
            </section>
            <section className="s_tab_wrap content">
                <div className="inner">
                    <ul className="s_tabmenu">
                        <li className={isActiveNavTabs ? 'active' : ''} onClick={() => setIsActiveNavTabs(true)}>{t('service.algostudy.type1')}</li>
                        <li className={isActiveNavTabs ? '' : 'active'} onClick={() => setIsActiveNavTabs(false)}>{t('service.algostudy.type2')}</li>
                    </ul>
                    <div className={`${isActiveNavTabs ? "s_personal" : "no_display"}`}>
                        <div className={`s_content_3`}>
                            <div className="h_inner_3">
                                <h1 className="title" {...animatedFadeInUpTitle3}><Trans i18nKey="service.algostudy.type1.detail1.title" components={[<br className="u_mobile" />, <em className="study_line"></em>]} /></h1>
                                <ul className="tab tab_under" {...animatedFadeInUpTab1}>
                                    <li className={isActiveTab1 ? 'active' : ''} onClick={() => setIsActiveTab1(true)}>{t('service.algostudy.type1.detail1.category1')}</li>
                                    <li className={isActiveTab1 ? '' : 'active'} onClick={() => setIsActiveTab1(false)}>{t('service.algostudy.type1.detail1.category2')}</li>
                                </ul>
                                <div className={`${isActiveTab1 ? "h_img_left" : "no_display"}`}>
                                    <PictureWebpComponent fb_img_1x={study_ph_main_w_1x_png} fb_img_2x={study_ph_main_w_2x_png} fb_img_3x={study_ph_main_w_3x_png} alt_img_name="image-ph-study-main-n"></PictureWebpComponent>
                                </div>
                                <div className={`h_tab_text ${isActiveTab1 ? '' : 'no_display'}`} {...animatedFadeInUpTab1_content1}>
                                    <p className="title_content">{t('service.algostudy.type1.detail1.category1.content1')}</p>
                                    <p className="title_content">{t('service.algostudy.type1.detail1.category1.content2')}</p>
                                </div>
                                <div className={`${!isActiveTab1 ? "h_img_left" : "no_display"}`}>
                                    <PictureWebpComponent fb_img_1x={study_ph_main_2_w_1x_png} fb_img_2x={study_ph_main_2_w_2x_png} fb_img_3x={study_ph_main_2_w_3x_png} alt_img_name="image-ph-study-main-2-n"></PictureWebpComponent>
                                </div>
                                <div className={`h_tab_text ${!isActiveTab1 ? '' : 'no_display'}`} {...animatedFadeInUpTab1_content2}>
                                    <p className="title_content">{t('service.algostudy.type1.detail1.category2.content1')}</p>
                                    {/* <p className="title_content">{t('service.algostudy.type1.detail1.category2.content2')}</p> */}
                                </div>
                            </div>
                        </div>
                        <div className="s_content_5">
                            <div className="s_inner_5">
                                <h1 className="title" {...animatedFadeInUpTitle5}><Trans i18nKey="service.algostudy.type1.detail2.title" components={[<br />, <em className="study_line"></em>, <br className="u_mobile" />]} /></h1>
                                <div className="h_img_right">
                                    <PictureWebpComponent fb_img_1x={study_ph_report_w_1x_png} fb_img_2x={study_ph_report_w_2x_png} fb_img_3x={study_ph_report_w_3x_png} alt_img_name="image-ph-study-report-n"></PictureWebpComponent>
                                </div>
                                <div className="h_tab_text" {...animatedFadeInUpTitle_content_5}>
                                    <p className="title_content"><Trans i18nKey="service.algostudy.type1.detail2.title_content1" components={[<br className="u_desk" />]} /></p>
                                    {/* <p className="title_content"><Trans i18nKey="service.algostudy.type1.detail2.title_content2" components={[<br className="u_desk" />]} /></p> */}
                                </div>
                            </div>
                        </div>
                        <div className="s_content_6">
                            <div className="s_inner_6">
                                <h1 className="title" {...animatedFadeInUpTitle6}><Trans i18nKey="service.algostudy.type1.detail3.title" components={[<br />, <em className="study_line"></em>, <br className="u_mobile" />]} /></h1>
                                <div className="h_img_left">
                                    <PictureWebpComponent fb_img_1x={study_ph_ranking_w_1x_png} fb_img_2x={study_ph_ranking_w_2x_png} fb_img_3x={study_ph_ranking_w_3x_png} alt_img_name="image-ph-study-ranking-n"></PictureWebpComponent>
                                </div>
                                <div className="h_tab_text" {...animatedFadeInUpTitle_content_6}>
                                    <p className="title_content">{t('service.algostudy.type1.detail3.title_content1')}</p>
                                    <p className="title_content">{t('service.algostudy.type1.detail3.title_content2')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`${!isActiveNavTabs ? "s_group" : "no_display"}`}>
                        {/* version1 */}
                        {/* <div className={`s_content_3`}>
                        <div className="h_inner_3">
                            <h1 className="title" {...animatedFadeInUpTitle4}>
                                <span>{t('service.algostudy.type2.detail1.title')}</span>
                                <Trans i18nKey="service.algostudy.type2.detail1.title_content" components={[<br />, <em className="study_line"></em>]} />
                            </h1>
                            <ul className="tab tab_under" {...animatedFadeInUpTab2}>
                                <li className={isActiveTab2 ? 'active' : ''} onClick={() => setIsActiveTab2(true)}>{t('service.algostudy.type2.detail1.category1')}</li>
                                <li className={isActiveTab2 ? '' : 'active'} onClick={() => setIsActiveTab2(false)}>{t('service.algostudy.type2.detail1.category2')}</li>
                            </ul>
                            <div >
                                <PictureWebpComponent fb_img_1x={study_ph_board_now_w_1x_png} fb_img_2x={study_ph_board_now_w_2x_png} fb_img_3x={study_ph_board_now_w_3x_png} alt_img_name="image-ph-study-board-now-n"></PictureWebpComponent>
                            </div>
                            <div className="h_tab_text" {...animatedFadeInUpTab2_content1}>
                                <p className="title_content">{t('service.algostudy.type2.detail1.category1.content')}</p>
                            </div>
                            <div>
                                <PictureWebpComponent fb_img_1x={study_ph_board_ranking_w_1x_png} fb_img_2x={study_ph_board_ranking_w_2x_png} fb_img_3x={study_ph_board_ranking_w_3x_png} alt_img_name="image-ph-study-board-ranking-n"></PictureWebpComponent>
                            </div>
                            <div className="h_tab_text" {...animatedFadeInUpTab2_content2}>
                                <p className="title_content">{t('service.algostudy.type2.detail1.category2.content')}</p>
                            </div>
                        </div>
                    </div> */}
                        <div className="s_content_7">
                            <h1 className="title" {...animatedFadeInUpTitle7}>
                                <span>{t('service.algostudy.type2.detail1.title')}</span>
                                <Trans i18nKey="service.algostudy.type2.detail1.title_content" components={[<br className="u_mobile" />, <em className="study_line"></em>]} />
                            </h1>
                            <div className="s_img_bottom">
                                <PictureWebpComponent fb_img_1x={study_ph_board_now_w_1x_png} fb_img_2x={study_ph_board_now_w_2x_png} fb_img_3x={study_ph_board_now_w_3x_png} alt_img_name="image-study-ph-board-now-n"></PictureWebpComponent>
                            </div>
                            <div className="s_group_text">
                                <p className="title_content" {...animatedFadeInUpTitle_content_7}>{t('service.algostudy.type2.detail1.content')}</p>
                            </div>
                        </div>
                        <div className="s_content_7">
                            <h1 className="title"  {...animatedFadeInUpTitle8}>
                                <span>{t('service.algostudy.type2.detail2.title')}</span>
                                <Trans i18nKey="service.algostudy.type2.detail2.title_content" components={[<br className="u_mobile" />, <em className="study_line"></em>]} />
                            </h1>
                            <div className="s_img_bottom">
                                <PictureWebpComponent fb_img_1x={study_ph_board_ranking_w_1x_png} fb_img_2x={study_ph_board_ranking_w_2x_png} fb_img_3x={study_ph_board_ranking_w_3x_png} alt_img_name="image-study-ph-board-ranking-n"></PictureWebpComponent>
                            </div>
                            <div className="s_group_text">
                                <p className="title_content" {...animatedFadeInUpTitle_content_8}>{t('service.algostudy.type2.detail2.content')}</p>
                            </div>
                        </div>
                        <div className="s_content_7">
                            <h1 className="title" {...animatedFadeInUpTitle9}>
                                <span>{t('service.algostudy.type2.detail3.title')}</span>
                                <Trans i18nKey="service.algostudy.type2.detail3.title_content" components={[<br className="u_mobile" />, <em className="study_line"></em>]} />
                            </h1>
                            <div className="s_img_bottom">
                                <PictureWebpComponent fb_img_1x={study_ph_board_pyramid_w_1x_png} fb_img_2x={study_ph_board_pyramid_w_2x_png} fb_img_3x={study_ph_board_pyramid_w_3x_png} alt_img_name="image-study-ph-board-pyramid-n"></PictureWebpComponent>
                            </div>
                            <div className="s_group_text">
                                <p className="title_content" {...animatedFadeInUpTitle_content_9}>{t('service.algostudy.type2.detail3.content')}</p>
                            </div>
                        </div>
                        <div className="s_content_7">
                            <h1 className="title" {...animatedFadeInUpTitle10}>
                                <span>{t('service.algostudy.type2.detail4.title')}</span>
                                <Trans i18nKey="service.algostudy.type2.detail4.title_content" components={[<br className="u_mobile" />, <em className="study_line"></em>]} />
                            </h1>
                            <div className="s_img_bottom">
                                <PictureWebpComponent fb_img_1x={study_ph_board_pattern_w_1x_png} fb_img_2x={study_ph_board_pattern_w_2x_png} fb_img_3x={study_ph_board_pattern_w_3x_png} alt_img_name="image-study-ph-board-pattern-n"></PictureWebpComponent>
                            </div>
                            <div className="s_group_text">
                                <p className="title_content" {...animatedFadeInUpTitle_content_10}>{t('service.algostudy.type2.detail4.content')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="content_wide s_qna">
                <div className="s_qna_content">
                    <p><Trans i18nKey="service.algostudy.banner2.title" components={[<b></b>]} /></p>
                    <Link to="/contact/inquire">
                        <button>{t('service.algostudy.banner2.btn')}</button>
                    </Link>
                </div>
            </section>
        </section >
    )
}

export default AlgoStudy;
