import { useCallback, useEffect, useRef } from 'react';

export const useScrollFadeIn = (
    direction = 'up',
    duration = 1,
    delay = 0,
    threshold = 0.2,
    translatePosX = 0,
    translatePosY = 0,
) => {
    const dom = useRef(null);

    const handleDirection = (name) => {
        switch (name) {
            case 'up':
                return 'translate3d(0, 50%, 0)';
            case 'down':
                return 'translate3d(0, -50%, 0)';
            case 'left':
                return 'translate3d(50%, 0, 0)';
            case 'right':
                return 'translate3d(-50%, 0, 0)';
            default:
                return `translate3d(${translatePosX}%, ${translatePosY}%, 0)`;
        }
    };

    const handleScroll = useCallback(
        ([entry]) => {
            const { current } = dom;
            if (current && entry.isIntersecting) {
                // if (!current.style) {
                //     current.style = {};
                // }
                current.style.transitionProperty = 'all';
                current.style.transitionDuration = `${duration}s`;
                current.style.transitionTimingFunction =
                    'cubic-bezier(0, 0, 0.2, 1)';
                current.style.transitionDelay = `${delay}s`;
                current.style.opacity = 1;
                current.style.transform = 'translate3d(0, 0, 0)';
            }
        },
        [delay, duration],
    );

    useEffect(() => {
        let observer;
        const { current } = dom;

        if (current) {
            observer = new IntersectionObserver(handleScroll, {
                threshold: threshold,
            });
            observer.observe(current);
        }

        return () => observer && observer.disconnect();
    }, [handleScroll]);

    return {
        ref: dom,
        style: {
            opacity: 0,
            transform: handleDirection(direction),
        },
    };
};

export const useScrollClipPath = (
    direction = 'left',
    duration = 1,
    delay = 0,
    threshold = 0.2,
    scale = 1.2,
    onOpacity = false,
    onTranslate = 1,
    insetPosX = 0,
    insetPosY = 0,
) => {
    // NOTE :
    // onTranslate : 1 -> 우측상단에서 부터 위치이동

    const element = useRef(null);

    const handleClipPath = (name) => {
        switch (name) {
            case 'up':
                return 'inset(100% 0 0 0)';
            case 'down':
                return 'inset(0 0 100% 0)';
            case 'left':
                return 'inset(0 100% 0 0)';
            case 'right':
                return 'inset(0 0 0 100%)';
            default:
                return;
        }
    };

    const onScroll = useCallback(
        ([entry]) => {
            const { current } = element;
            if (entry.isIntersecting) {
                current.style.transitionProperty =
                    'transform, clip-path, opacity';
                current.style.transitionDuration = `${
                    duration * 1.5
                }s, ${duration}s`;
                current.style.transitionTimingFunction =
                    'cubic-bezier(0, 0, 0.2, 1)';
                current.style.transitionDelay = `${delay}s`;
                current.style.transform = `scale(1) ${
                    onTranslate === 1 ? 'translate3d(0, 0, 0)' : ''
                }`;
                if (onOpacity) {
                    current.style.opacity = 1;
                }
                current.style.clipPath = 'inset(0 0 0 0)';
            }
        },
        [delay, duration],
    );

    useEffect(() => {
        let observer;

        if (element.current) {
            observer = new IntersectionObserver(onScroll, {
                threshold: threshold,
            });
            observer.observe(element.current.parentNode);
        }

        return () => observer && observer.disconnect();
    }, [onScroll]);

    return {
        ref: element,
        style: {
            transform: `scale(${scale}) ${
                onTranslate === 1 ? 'translate3d(100%, -100%, 0)' : ''
            }`,
            opacity: `${onOpacity ? 0 : 1}`,
            clipPath: handleClipPath(direction),
        },
    };
};

export const useScrollCount = (
    end,
    start = 0,
    duration = 3000,
    delay = 0,
    threshold = 0.7,
) => {
    const element = useRef(null);
    const observer = useRef(null);
    const stepTime = Math.abs(Math.floor(duration / (end - start)));

    const onScroll = useCallback(
        ([entry]) => {
            const { current } = element;
            if (entry.isIntersecting) {
                let currentNumber = start;
                const counter = setInterval(() => {
                    currentNumber += 1;
                    current.innerHTML = currentNumber;
                    if (currentNumber === end) {
                        clearInterval(counter);
                        observer.current.disconnect(element.current);
                    }
                }, stepTime);
            }
        },
        [end, start, stepTime, element],
    );

    useEffect(() => {
        if (element.current) {
            observer.current = new IntersectionObserver(onScroll, {
                threshold: threshold,
            });
            observer.current.observe(element.current);
        }

        return () => observer && observer.disconnect();
    }, [onScroll]);

    return {
        ref: element,
    };
};
