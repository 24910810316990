import React from 'react';
import version from '../version';

function Version() {
    return (
        <div>
            Build version : {version.version}
            <br />
            Build Time : {version.build_time}
            <br />
            Commit Hash : {version.build_commit_hash}
            {process.env.NODE_ENV === 'development'
                ? ' (development mode)'
                : ' (product mode)'}
        </div>
    );
}

export default Version;
