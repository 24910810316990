import React from 'react';

interface picWebInterface {
    fb_img_1x: string,
    fb_img_2x: string, 
    fb_img_3x: string, 
    fb_alt?: string, 
    alt_img_name: string,
    alt_img_type?: string|null,
    className?: string
}

export default function PictureWebpComponent({fb_img_1x, fb_img_2x, fb_img_3x, fb_alt = "", alt_img_name, alt_img_type = 'webp', className} : picWebInterface) {
// export default function PictureWebpComponent({img_name, fb_alt, fb_img_type = 'png'} : {img_name:string, fb_img_type?:string, fb_alt:string}) {
    // NOTE : <picture/>, <source/> tag를 이용하여 DPR에 따른 반응형이미지 처리altt
    // 기본 failback 이미지는 필수 (밀도별 @x1, @x2, @x3파일 필수. 제플린에서 생성되는 파일 규칙에 따름)
    // mediaQuery별 대응을 위한 webp 파일중 데스크탑파일은 필수 (밀도별 @x1, @x2, @x3파일 필수. 제플린에서 생성되는 파일 규칙에 따름)
    // mediaQuery별 대응을 위한 webp 파일중 모바일파일은 옵션 (파일이 없으면 데스크탑버전으로 대체)
    // File Name Rule : ####-{w|m}@{DPR}x.{확장자}

    // -> failback이미지마저 require로 생성하게 되면 compile 단계에서 파일에 대한 error를 중간에 찾을 수 없음.
    // -> 대체이미지에 대해서만 require로 생성.

    let w_3x_alt = null;
    let w_2x_alt = null;
    let w_1x_alt = null;
    try {
        w_3x_alt = require(`../images/${alt_img_name}-w@3x.${alt_img_type}`).default;
        w_2x_alt = require(`../images/${alt_img_name}-w@2x.${alt_img_type}`).default;
        w_1x_alt = require(`../images/${alt_img_name}-w.${alt_img_type}`).default;
    } catch (error) {
        w_3x_alt = fb_img_3x;
        w_2x_alt = fb_img_2x;
        w_1x_alt = fb_img_1x;
    }
    
    let m_3x_alt = null;
    let m_2x_alt = null;
    let m_1x_alt = null;
    try {
        m_3x_alt = require(`../images/${alt_img_name}-m@3x.${alt_img_type}`).default;
        m_2x_alt = require(`../images/${alt_img_name}-m@2x.${alt_img_type}`).default;
        m_1x_alt = require(`../images/${alt_img_name}-m.${alt_img_type}`).default;

    } catch (error) {
        m_3x_alt = w_3x_alt?w_3x_alt:fb_img_3x;
        m_2x_alt = w_2x_alt?w_2x_alt:fb_img_2x;
        m_1x_alt = w_1x_alt?w_1x_alt:fb_img_1x;
    }

    return (
        <picture>
            <source media="(min-width:960px)" srcSet={`${w_1x_alt} 1x, ${w_2x_alt} 2x, ${w_3x_alt} 3x`} type={`image/${alt_img_type}`} />
            <source srcSet={`${m_1x_alt} 1x, ${m_2x_alt} 2x, ${m_3x_alt} 3x`} type={`image/${alt_img_type}`} />
            <img srcSet={`${fb_img_1x} 1x, ${fb_img_2x} 2x, ${fb_img_3x} 3x`} src = {fb_img_3x} alt={fb_alt} className={className}/>
        </picture>
    )
}