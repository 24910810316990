import React, { useState } from 'react'
import { Route, Switch } from 'react-router-dom';
// import { i18n } from 'react-i18next';
import i18n from '../i18n'         // 다국어 지원 파일

import Footer from '../components/Footer/Footer';
import Menu from '../components/Menu/Menu';
import AboutData from '../containers/AboutData';
import ContactData from '../containers/ContactData';
import MainData from '../containers/MainData';
import ProductData from '../containers/ProductData';
import RecruitData from '../containers/RecruitData';
import ServiceData from '../containers/ServiceData';
import PolicyData from '../containers/PolicyData';
import Error from '../components/Error/Error';

import ScrollToTop from "../utils/ScrollToTop";
import NewsData from '../containers/NewsData';
import ChannelService from '../utils/ChannelService';

const getLanguage = () => {
    return i18n.language ||
        (typeof window !== 'undefined' && window.localStorage.i18nextLng) ||
        'en';
};
function Index() {
    const l = getLanguage();

    if (l.substring(0, 2) === 'ko') {
        document.documentElement.lang = 'ko';
    } else {
        document.documentElement.lang = 'en';
    }

    const [htmlLang, setHtmlLang] = useState(document.documentElement.lang);
    // console.log('document.documentElement.lang : ', document.documentElement.lang)
    // console.log({htmlLang})
    return (
        <div className={"wrap lang_" + htmlLang + "_font"}>
            <Menu htmlLang={htmlLang} setHtmlLang={setHtmlLang} />
            <ScrollToTop>
                <Switch>
                    <Route exact path="/" component={MainData} />
                    <Route path="/about" component={AboutData} />
                    <Route path="/services/:service_type" component={ServiceData} />
                    <Route path="/product" component={ProductData} />
                    <Route path="/news" component={NewsData} />
                    <Route path="/contact/:page" component={ContactData} />
                    {/* <Route path="/recruit" component={RecruitData} /> */}
                    <Route path="/policy" component={PolicyData} />
                    <Route path="*" component={Error} />
                </Switch>
                {ChannelService.boot({
                    "pluginKey": "9e4ba6e5-2cfd-4a64-ac0c-3803b19990af",
                })}
            </ScrollToTop>
            <Footer />
        </div>
    )
}

export default Index
