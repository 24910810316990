import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next'

import './footer.css';

// images
import logo from '../../images/logo-algorigo@3x.png';
import facebookic from '../../images/icon-facebook@3x.png';
import naveric from '../../images/icon-naver@3x.png';

function Accordion({ title, children }: { title: string, children: any }) {
    const [isOpened, setIsOpened] = useState(false);
    const onToggleServiceBtn = () => {
        setIsOpened(!isOpened);
    }
    return (
        <li
            className={`f_menu ${isOpened ? 'f_open' : 'f_close'}`} onClick={onToggleServiceBtn}>
            <strong>{title}</strong>
            <span className={`${isOpened ? 'f_arrow-t' : 'f_arrow-b'}`}></span>
            {children}
        </li>
    )
}

function Footer() {
    const { t } = useTranslation();

    return (
        <footer className="footer">
            <div className="f_inner">
                <div className="f_area">
                    <div className="f_menulist">
                        <ul>
                            <Accordion title="SERVICE">
                                <ul>
                                    <li><Link to="/services/algostudy">{t('footer.nav.service.algostudy')}</Link></li>
                                    <li><Link to="/services/algowork">{t('footer.nav.service.algowork')}</Link></li>
                                    <li><Link to="/services/algohealth">{t('footer.nav.service.algohealth')}</Link></li>
                                    {/* <li>{t('footer.nav.service.algocare')}</li> */}
                                </ul>
                            </Accordion>
                            <Accordion title="PRODUCT">
                                <ul>
                                    <li><Link to="/product?product_code=0">{t('footer.nav.product.smartchair')}</Link></li>
                                    <li><Link to="/product?product_code=1">{t('footer.nav.product.smartcushion')}</Link></li>
                                    {/* <li>{t('footer.nav.product.pressurego')}</li> */}
                                </ul>
                            </Accordion>
                            <Accordion title="COMPANY">
                                <ul>
                                    <li><Link to="/about">{t('footer.nav.company_intro')}</Link></li>
                                    <li><Link to="/news">{t('footer.nav.news')}</Link></li>
                                    <li><Link to="/contact/recruit">{t('footer.nav.recruit')}</Link></li>
                                    <li><Link to="/contact/inquire">{t('footer.nav.contact')}</Link></li>
                                </ul>
                            </Accordion>
                            <Accordion title="RESOURCE">
                                <ul>
                                    <li><Link to="/policy">{t('footer.nav.resource.policy')}</Link></li>
                                    {/* <li><Link to="">라이센스</Link></li>
                                    <li><Link to="">사이트</Link></li> */}
                                </ul>
                            </Accordion>
                        </ul>
                    </div>
                    <div className="f_help">
                        <div className="f_logo">
                            <Link to="/"><img src={logo} alt="" /></Link>
                            <p><Link to="/contact/inquire">
                                {/* Do you need any<br/> assistance? */}
                                {/* <Trans i18nKey="footer.any.help">
                                    Do you need any<br/> assistance?
                                </Trans> */}
                                {t('footer.any.help')}
                            </Link>
                            </p>
                        </div>
                        <div className="f_sns">
                            <a href="https://www.facebook.com/algorigo" target="_blank" rel="noreferrer"><img src={facebookic} alt="" /></a>
                            <a href="https://smartstore.naver.com/algorigo" target="_blank" rel="noreferrer"><img src={naveric} alt="" /></a>
                        </div>
                    </div>
                </div>
                <div className="f_info">
                    <ul>
                        <li>{t('footer.info.companyname')}</li>
                        <li>{t('footer.info.ceo')}</li>
                        <li>{t('footer.info.b_lic_num')}<span className="lang_en_font">386-81-00280</span></li>
                        <li>
                            {/* <Trans i18nKey="footer.info.address">
                                <span className="lang_en_font"></span>
                            </Trans> */}
                            <Trans i18nKey="footer.info.address" components={[<span className="lang_en_font"></span>]} />
                        </li>
                        <li>{t('footer.info.mail')} : <a href="mailto:admin@algorigo.com">admin@algorigo.com</a></li>
                    </ul>
                    <div className="f_copyright lang_en_font">Copyright &copy; 2021 Algorigo Inc. All Rights Reversed.</div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;

