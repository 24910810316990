import { useEffect } from 'react';
import Main from '../components/Main/Main';
// import { NewsType } from '../types/NewsType'
// import { getNewsList } from '../api/api'
import { useDispatch, useSelector } from 'react-redux';
import { getNewDataThunk } from '../modules/news';
import { RootState } from '../modules';


function MainData() {
    // TODO : Redux + PromiseThunk
    // const [ newsList, setNewsList ] = useState<NewsType[]>([]);
    const newList: any = useSelector((state: RootState) => state.news.newsList).data;
    const dispatch = useDispatch();

    const param: object = {
        'page': 1,
        'itemPerPage': 3,
        'newsId': '"news_id"',
        'desc': 'True',
        'keyword': ''
    }

    useEffect(() => {
        // getNews();
        dispatch(getNewDataThunk(param));
    }, []);
    // async function getNews(){
    //     const newsData = await getNewsList();
    //     // console.log({newsData});
    //     if(newsData.request_status === 'SUCCESS'){
    //         setNewsList(newsData.list);
    //     }
    // }

    let newsList: any[] = [];
    if (newList) {
        newsList = newList.list
    }
    return (
        <Main newsList={newsList} />
    )
}

export default MainData;
