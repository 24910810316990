import { createReducer } from 'typesafe-actions';
import { contactDataState, contactDataAction } from './types';
import { postContactDataAsync, } from './actions';
import { asyncState, createAsyncReducer, transformToArray } from '../../lib/reducerUtils';

const initialState: contactDataState = {
    postContact: asyncState.initial()
};

const contact = createReducer<contactDataState, contactDataAction>(initialState).handleAction(
    transformToArray(postContactDataAsync),
    createAsyncReducer(postContactDataAsync, 'postContact')
);

export default contact;