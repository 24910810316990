// import { createStandardAction } from 'typesafe-actions';
import { createAsyncAction } from 'typesafe-actions';
import { AxiosError, AxiosResponse } from 'axios';
// import { NewsType } from '../../types/NewsType'

export const POST_CONTACT_DATA = 'POST_CONTACT_DATA';
export const POST_CONTACT_DATA_SUCCESS = 'POST_CONTACT_DATA_SUCCESS';
export const POST_CONTACT_DATA_ERROR = 'POST_CONTACT_DATA_ERROR';

export const postContactDataAsync = createAsyncAction(
    POST_CONTACT_DATA,
    POST_CONTACT_DATA_SUCCESS,
    POST_CONTACT_DATA_ERROR
)<any, AxiosResponse, AxiosError>();
