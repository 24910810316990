import React, { useState, useEffect } from 'react'
// import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import { TitleComponent } from '../TitleComponent'
import LocationTerm from './locationTerm';

import './Policy.css'
import PrivacyPolicy from './privacy';
import TermsOfUse from './tou';

// images


function Policy(props: { p_version: number, p_type: number, chapter_num: number }) {
    const { t } = useTranslation();

    const { p_version, p_type, chapter_num } = props;
    const htmlLang = document.documentElement.lang;
    // console.log({htmlLang})

    const [numPolicyVersion, setNumPolicyVersion] = useState<number>(p_version);    // 이용약관의 버전
    const [isActivePolicySec, setIsActivePolicySec] = useState<number>(p_type);     //'서비스이용약관', '개인정보이용약관', '개인위치정보이용약관' Tab
    const [numChapter, setNumChapter] = useState<number>(chapter_num);


    useEffect(() => {
        if (p_version !== numPolicyVersion) {
            setNumPolicyVersion(p_version);
        }
        if (p_type !== isActivePolicySec) {
            setIsActivePolicySec(p_type);
        }
        if (chapter_num !== numChapter) {
            setNumChapter(chapter_num);
        }
    }, [props]);

    // console.log({p_version});

    return (
        <section className="container pol_ctr">
            <TitleComponent title={t('footer.nav.resource.policy')} />
            <section className="content pol_top_1">
                <div className="inner">
                    <div className="pol_tit bg_wrap">
                        <div className="bg--yellow7 light"></div>
                        <h1 className="title">
                            {
                                htmlLang === 'ko' ? ("개인정보 처리방침") : ("Privacy and Terms")
                            }
                        </h1>
                    </div>
                    <p className="title_content">
                        {
                            htmlLang === 'ko' ? (
                                "알고리고(이하, '회사'라 함)는 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보보호법, 통신비밀보호법, 전기통신사업법 등 정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보방침을 정하여 이용자 권익 보호에 최선을 다하고 있습니다."
                            ) : (" ")
                        }
                    </p>

                    <div className=" pol_content_1">
                        <ul className="tab pol_tab_menu">
                            <li className={isActivePolicySec === 0 ? 'active' : ''} onClick={() => setIsActivePolicySec(0)}>
                                {/* <Trans i18nKey="policy.tap.tou" components={[<br className="u_mobile" />]} /> */}
                                {
                                    htmlLang === 'ko' ? (<>서비스 <br className="u_mobile" />이용약관</>) : (<>Terms <br className="u_mobile" />of Use</>)
                                }
                            </li>
                            <li className={isActivePolicySec === 1 ? 'active' : ''} onClick={() => setIsActivePolicySec(1)}>
                                {/* <Trans i18nKey="policy.tap.privacy" components={[<br className="u_mobile" />]} /> */}
                                {
                                    htmlLang === 'ko' ? (<>개인정보 <br className="u_mobile" />이용약관</>) : (<>Privacy <br className="u_mobile" />Policy</>)
                                }
                            </li>
                            {
                                htmlLang === 'ko' ? (
                                    <li className={isActivePolicySec === 2 ? 'active' : ''} onClick={() => setIsActivePolicySec(2)}>
                                        {/* <Trans i18nKey="policy.tap.location" components={[<br className="u_mobile" />]} /> */}
                                        {
                                            htmlLang === 'ko' ? (<>위치정보 <br className="u_mobile" />이용약관</>) : (<>Location <br className="u_mobile" />Terms</>)
                                        }
                                    </li>
                                ) : ('')
                            }
                        </ul>
                        {isActivePolicySec === 0 ? (
                            <TermsOfUse lang={htmlLang} p_version={p_version} numChapter={numChapter}></TermsOfUse>
                        ) :
                            isActivePolicySec === 1 ? (
                                <PrivacyPolicy p_version={p_version} lang={htmlLang} numChapter={numChapter}></PrivacyPolicy>
                            ) : isActivePolicySec === 2 ? (
                                <LocationTerm p_version={p_version} lang={htmlLang} numChapter={numChapter}></LocationTerm>
                            ) : <div>
                                <h1 className="pol_tit">Page not found.</h1>
                                <h2 className="title_content">language code : {htmlLang}<br />document Version : {p_version}</h2>
                            </div>}

                    </div>
                </div>
            </section>
        </section>
    )
}

export default Policy
