import React, { useEffect } from 'react';
import Recruit from '../components/Recruit/Recruit';
import { RootState } from '../modules';
import { useDispatch, useSelector } from 'react-redux';
import { getRecruitsThunk } from '../modules/recruits';
// import { RecruitType } from '../types/RecruitType';

function RecruitData() {
    // TODO : Redux + PromiseThunk
    // const [ recrutList, setRecruitList ] = useState<RecruitType[]>([])
    const { data, loading, error }: any = useSelector((state: RootState) => state.recruit.recruitList);
    const dispatch = useDispatch();

    useEffect(() => {
        // getRecruitsList();
        dispatch(getRecruitsThunk());
    }, []);

    // async function getRecruitsList(){
    //     const recrutsData = await getRecruits();
    //     // console.log({recrutsData})
    //     if(recrutsData.request_status === 'SUCCESS'){
    //         setRecruitList(recrutsData.list);
    //         setIsLoaded(true);
    //         // recruitList = {...recrutsData.list}
    //         // console.log('b', {recruitList})
    //         // console.log('b')
    //     }
    // }

    let recruits = [];
    if (data) {
        recruits = data.list
    }

    return (
        <Recruit recruitList={recruits} isLoaded={loading} error={error} />
    )
}

export default RecruitData;
